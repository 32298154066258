import React from "react";
import { Divider, Tag, Row, Col } from "antd";
import { TextTime } from "../../../styles/components/style";
import ContactShop from "../ContactShop";
import { timeOnly, checkPostActiveByTime } from "../../../helpers/time";

export default function ShopDescription({
  shopName,
  openTime,
  closeTime,
  contact,
}) {
  const isPostActive = checkPostActiveByTime(openTime, closeTime);
  return (
    <div className="pd-2">
      <Row type="flex" justify="space-between">
        <Col span={8}>
          <span>
            <b>ร้าน:</b> {shopName}
          </span>
        </Col>
        <Col span={8} className="">
          <p className="mb-0">
            <b>เวลาเปิด - ปิดร้าน</b>
          </p>
          <TextTime>
            {timeOnly(openTime)} - {timeOnly(closeTime)} น.
          </TextTime>
        </Col>
        <Col span={8} className="text-right">
          <ContactShop contact={contact} size="small" className="mr-1" />
          {isPostActive ? (
            <Tag color="green" className="sm-ml-2 sm-mt-2">
              ร้านเปิด
            </Tag>
          ) : (
            <Tag color="red" className="sm-ml-2 sm-mt-2">
              ไม่อยู่ในช่วงเวลา
            </Tag>
          )}
        </Col>
      </Row>
      <Divider className="mt-2 mb-2" />
    </div>
  );
}
