import React, { useState } from "react";
import { Card, Tooltip, Avatar, Row, Col } from "antd";
import { Link } from "react-router-dom";
import { AppPath } from "../../router/path";
import ShopService from "../../services/ShopService";
import { TextEllip } from "../../styles/components/style";
import CheckOrderStatus from "./CheckOrderStatus";

const { Meta } = Card;
export default function OrderHistoryCard({
  shopName,
  sid,
  path_head_pic,
  head_pic,
  orderId,
  price,
  total,
  qty,
  orderStatus,
  onCancelOrder,
  contact,
}) {
  const pathLogo = ShopService.getLogoHasPath(path_head_pic, head_pic);
  const [path, setPath] = useState(pathLogo);
  return (
    <div>
      <Meta
        className="font-main bg-w pd-2 order-meta"
        avatar={
          <Link to={`${AppPath.shop}/${sid}`}>
            <Tooltip
              placement="top"
              title={<span className="font-sub">ดูรายละเอียดร้านค้า</span>}
            >
              <Avatar
                size={64}
                src={path}
                alt="shop logo"
                onError={() => setPath("/gebtam.png")}
              />
            </Tooltip>
          </Link>
        }
        title={
          <Link to={`${AppPath.shop}/${sid}`}>
            <Tooltip
              placement="top"
              title={<span className="font-sub">ดูรายละเอียดร้านค้า</span>}
            >
              <TextEllip lineNum={1} className="size-15">
                {shopName}
              </TextEllip>
            </Tooltip>
          </Link>
        }
        description={
          <span className="size-12 line-10">
            <p className="mb-2">รหัสสินค้า {orderId}</p>
            <Row className="mb-2" type="flex" justify="space-between">
              <p> ราคารวม {total} บาท </p>
              <Col className="text-right ml-auto">
                <CheckOrderStatus
                  orderId={orderId}
                  orderStatus={orderStatus}
                  contact={contact}
                  onCancelOrder={onCancelOrder}
                />
              </Col>
            </Row>
          </span>
        }
      />
      {/* <Divider /> */}
    </div>
  );
}
