import {
  getFeedsSuccess,
  getFeedsError,
  getAllFeedsSuccess,
  getFeedsPending,
  getFeedsNearSuccess,
  getFeedsNearError,
  getAllFeedsNearSuccess,
  getFeedsNearPending,
  setFeedsNear,
  setFeedsAll,
} from "./actionCreators";
import FeedService from "../../services/FeedService";
import AuthService from "../../services/AuthService";
import { logoutAndGotoLogin } from "./auth";

export const getAllFeeds = (history, data) => (dispatch) => {
  dispatch(getFeedsPending());
  const act = JSON.parse(AuthService.getToken());
  const newPostData = { ...data };
  newPostData.access_token = act.access_token;
  FeedService.getAllFeeds(newPostData)
    .then((resp) => {
      if (resp.success) {
        const newData = { ...data };
        newData.data = resp.result.reduce((a, b) => {
          a[b.feed_node] = b;
          return a;
        }, {});
        if (resp.result.length < data.limit) {
          dispatch(getAllFeedsSuccess(newData));
        } else {
          dispatch(getFeedsSuccess(newData));
        }
      } else {
        if (
          resp.result.ERROR &&
          (resp.result.ERROR.CODE === "SE" || resp.result.ERROR.CODE === "TE")
        ) {
          dispatch(logoutAndGotoLogin(history));
        }
        dispatch(getFeedsError(resp.result));
      }
    })
    .catch((error) => {
      console.log(error);
      dispatch(getFeedsError(error));
    });
};

export const getFeedsNear = (history, data) => (dispatch) => {
  dispatch(getFeedsNearPending());
  const act = JSON.parse(AuthService.getToken());
  const newPostData = { ...data };
  if (act) newPostData.access_token = act.access_token;
  FeedService.getFeedsNear(newPostData)
    .then((resp) => {
      if (resp.success) {
        const newData = { ...data };
        newData.data = resp.result.reduce((a, b) => {
          a[b.feed_node] = b;
          return a;
        }, {});
        if (resp.result.length < data.limit) {
          dispatch(getAllFeedsNearSuccess(newData));
        } else {
          dispatch(getFeedsNearSuccess(newData));
        }
      } else {
        if (resp.result.ERROR && resp.result.ERROR.CODE === "SE") {
          dispatch(logoutAndGotoLogin(history));
        }
        dispatch(getFeedsNearError(resp.result));
      }
    })
    .catch((error) => {
      console.log(error);
      dispatch(getFeedsNearError(error));
    });
};

export const setFeedsNears = (data) => (dispatch) => {
  dispatch(setFeedsNear(data));
};

export const setFeedsAlls = (data) => (dispatch) => {
  dispatch(setFeedsAll(data));
};
