import React from "react";
import ContactShop from "../ContactShop";
import { Tag } from "antd";

export default function ShopReceive({ contact, orderId }) {
  return (
    <>
      <ContactShop contact={contact} className="mr-2" />
      <Tag color="#69a832" className="font-sub">
        ร้านค้ารับออเดอร์
      </Tag>
    </>
  );
}
