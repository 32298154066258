import { message } from "antd";

import AuthService from "../../services/AuthService";
import { setCurrentUser, setOrganis } from "./actionCreators";
import { logoutAndGotoLogin } from "./auth";

//ดึงข้อมูลของ user
export const getProfile = (history) => (dispatch) => {
  return new Promise((resolve, reject) => {
    const act = JSON.parse(AuthService.getToken());
    if (act && act.access_token) {
      const data = {
        access_token: act.access_token,
      };
      AuthService.getProfile(data).then((resp) => {
        if (resp.success && resp.result) {
          dispatch(setOrganis(resp.organis));
          dispatch(setCurrentUser(resp.result));
          resolve();
        } else {
          if (resp.ERROR) {
            dispatch(logoutAndGotoLogin(history));
            resolve();
          } else {
            resolve();
          }
        }
      });
    } else {
      resolve();
    }
  });
};

export const saveProfile = (data, history) => (dispatch) => {
  return new Promise((resolve, reject) => {
    const act = JSON.parse(AuthService.getToken());
    if (act && act.access_token) {
      const newData = {
        ...data,
        access_token: act.access_token,
      };
      AuthService.saveProfile(newData)
        .then((resp) => {
          if (resp.success && resp.result) {
            const updateData = {
              ...data,
              m_pic: resp.result.m_pic,
            };
            dispatch(setCurrentUser(updateData));
            resolve();
          } else {
            if (resp.ERROR && resp.ERROR.CODE === "TE") {
              dispatch(logoutAndGotoLogin(history));
              reject(resp.ERROR);
            }
            reject(resp.result);
          }
        })
        .catch((error) => {
          console.log(error);
          message.error("เกิดข้อผิดพลาด");
          reject(error);
        });
    } else {
      resolve();
    }
  });
};
