import styled from "styled-components";

export const CardRatio = styled.div`
  background-image: url(${(props) => props.image});
  border: ${(props) => props.border};
  position: relative;
  min-height: 100%;
  width: 100%;
  padding-top: ${(props) => props.pdTop};
  background-color: ${(props) => props.bg};
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  background-position: center;
`;

export const CardItem = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  display: flex;
  /* align-items:center; */
`;
