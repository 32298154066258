import React, { Component } from "react";
import { connect } from "react-redux";
import {
  Form,
  Icon,
  Input,
  Button,
  Row,
  Col,
  Avatar,
  message,
  Tooltip,
  Spin,
  Tag,
  Radio,
} from "antd";
import { Link } from "react-router-dom";

import PoweredByGebtam from "../components/gebtam/PoweredByGebtam";
import ShopService from "../services/ShopService";
import { BASE_URL } from "../config";

function hasErrors(fieldsError) {
  return Object.keys(fieldsError).some((field) => fieldsError[field]);
}

class ManageSMS extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      isPath: false,
      m_tel_number: "", // โชว์ เบอร์
      sms_active: null,
      isOtp: false,
      sid: "",
      refOtp: [],
      isOtpDisabled: false,
      isOtpLoading: false,
      isTel: "", // ตรวจสอบ หน้าที่จะแสดงเมื่อมีเบอร์
      shopImg: "/gebtam.png",
      shopName: "",
    };
  }

  componentWillReceiveProps(nextProps) {
    const { location } = this.props;
    const { location: nextLocation } = nextProps;
    if (
      nextLocation.pathname !== location.pathname ||
      nextLocation.search !== location.search
    ) {
      window.location.reload();
    }
  }

  componentWillMount() {
    const param = new URLSearchParams(this.props.history.location.search);
    const sid = param.get("sid");
    const code = this.props.match.params.id;
    const tel = localStorage.getItem("m_tel_number");
    const temp_tel = localStorage.getItem("temp_tel");
    if (tel) {
      this.setState({ isTel: tel, m_tel_number: tel });
    }
    if (code) {
      this.getMemberSMS(code, null, null);
      this.setState({ isPath: true });
    } else {
      if (sid) {
        if (tel) {
          this.getMemberSMS(null, tel, sid);
        } else if (temp_tel) {
          this.checkShopDetail(sid);
          this.setState({
            m_tel_number: temp_tel,
          });
        } else {
          this.checkShopDetail(sid);
          this.setState({
            m_tel_number: "",
          });
        }
        this.setState({ sid: sid });
      } else {
        this.setState({ sid: "" });
        message.warning("ไม่พบข้อมูลร้าน");
      }
    }
  }

  checkShopDetail = async (sid) => {
    await ShopService.getCheckShopDetail({
      sid: sid,
    })
      .then((res) => {
        if (res.success) {
          this.setState({
            shopName: res.result.shop_name,
            shopImg: BASE_URL + res.result.path_shop_logo + res.result.head_pic,
            amoutCus: res.result.person_qty,
          });
        } else {
          this.setState({
            shopName: "ไม่พบร้านค้า",
            isFound: false,
            shopImg: "/gebtam.png",
          });
          message.error("ไม่พบร้านค้า");
        }
      })
      .catch((error) => {
        this.setState({ isLoading: false });
        console.log(error);
        message.error("เกิดข้อผิดพลาด");
      });
  };

  // Check Code by path
  getMemberSMS(code, tel, sid) {
    ShopService.getMemberSMS({
      code: code,
      m_tel_number: tel,
      sid: sid,
    })
      .then((res) => {
        if (res.success) {
          const tel_number = res.result.m_tel_number
            ? res.result.m_tel_number
            : "";
          if (
            res.result.shop_name &&
            res.result.path_shop_logo &&
            res.result.head_pic
          ) {
            this.setState({
              m_tel_number: tel_number,
              sms_active: res.result.sms_active,
              sid: res.result.sid,
              isLoading: false,
              shopImg:
                BASE_URL + res.result.path_shop_logo + res.result.head_pic,
              shopName: res.result.shop_name,
            });
          } else {
            this.setState({
              m_tel_number: tel_number,
              sms_active: res.result.sms_active,
              sid: res.result.sid,
              isLoading: false,
              shopImg: "/gebtam.png",
              shopName: "",
            });
          }

          localStorage.setItem("m_tel_number", tel_number);
        } else {
          this.setState({
            m_tel_number: "",
            sid: "",
            isLoading: false,
            shopImg: "/gebtam.png",
            shopName: "",
          });
          message.warning("ไม่พบข้อมูล");
        }
      })
      .catch((error) => {
        this.setState({
          isLoading: false,
        });
        console.log(error);
        message.error("เกิดข้อผิดพลาด");
      });
  }

  // Set Status active SMS
  setMemberSMS() {
    const { m_tel_number, sid, sms_active } = this.state;
    ShopService.setMemberSMS({
      m_tel_number: m_tel_number,
      sid: sid,
      sms_active: sms_active + "",
    })
      .then((res) => {
        if (res.success) {
          this.setState({ sms_active: res.sms_active });
          if (sms_active) {
            message.success(`รับการแจ้งเตือนข่าวสาร สำเร็จ`);
          } else {
            message.success(`ยกเลิกการแจ้งเตือนข่าวสาร สำเร็จ`);
          }
        } else {
          switch (res.result.ERROR_CODE) {
            case "WS":
              message.warning("ส่งข้อมูลซ้ำ");
              break;
            case "WF":
              message.warning("กรุณาตรวจสอบข้อมูลให้ครบ");
              break;
            case "WV":
              message.warning("กรุณากรอกข้อมูลให้ถูกต้อง");
              break;
            default:
              message.warning("เกิดข้อผิดพลาด");
              break;
          }
        }
      })
      .catch((error) => {
        this.setState({ isLoading: false });
        console.log(error);
        message.error("เกิดข้อผิดพลาด");
      });
  }

  //Check OTP
  checkOtp = async (m_tel_number, ref, code) => {
    const param = new URLSearchParams(this.props.history.location.search);
    const sid = param.get("sid");
    await ShopService.getCheckOtpShop({
      to: m_tel_number,
      ref: ref,
      code: code,
    })
      .then((res) => {
        if (res.success) {
          localStorage.setItem("m_tel_number", m_tel_number);
          this.setState({
            isLoading: false,
            isTel: localStorage.getItem("m_tel_number"),
            isOtp: true,
          });
          message.success("ยืนยัน OTP สำเร็จ");

          this.getMemberSMS(null, m_tel_number, sid);
          localStorage.setItem("temp_tel", m_tel_number);
        } else {
          switch (res.result.ERROR_CODE) {
            case "CE":
              message.warning("OTP หมดอายุ");
              break;
            case "WC":
              message.warning("OTP ไม่ถูกต้อง");
              break;
            case "WF":
              message.warning("กรุณากดส่ง OTP");
              break;
            default:
              message.warning("ไม่สามารถส่ง sms ได้");
              break;
          }
          this.setState({
            isLoading: false,
            isTel: localStorage.getItem("m_tel_number"),
            isOtp: true,
          });
        }
      })
      .catch((error) => {
        this.setState({ isLoading: false });
        console.log(error);
        message.error("เกิดข้อผิดพลาด");
      });
  };

  // ปุ่ม ส่ง otp
  handleSendOtp = () => {
    const m_tel_number = this.props.form.getFieldValue("m_tel_number");
    const telError = this.props.form.getFieldError("m_tel_number");
    this.setState({ m_tel_number: localStorage.getItem("m_tel_number") });
    if (!telError && !m_tel_number) {
      return message.error("กรุณาระบุหมายเลขโทรศัพท์");
    } else if (telError) {
      return message.error("กรุณาระบุหมายเลขโทรศัพท์ให้ถูกต้อง");
    }
    const postData = { to: m_tel_number };
    this.setState({ isOtpLoading: true });
    ShopService.sendVerifyOtpShop(postData)
      .then((resp) => {
        const data = resp;
        if (data.success) {
          message.success("ส่ง OTP สำเร็จ");
          this.setState({ refOtp: [...this.state.refOtp, data.ref] });
        } else {
          if (data.result.ERROR_CODE === 5) {
            message.warning("สามารถส่ง OTP ได้เพียง 5 ครั้ง ต่อวัน");
          } else {
            message.warning("ไม่สามารถส่ง sms ได้");
          }
        }
        this.setState({ isOtpLoading: false, isOtpDisabled: true });
      })
      .catch((error) => {
        this.setState({ isOtpLoading: false, isOtpDisabled: true });
        console.log(error);
        message.error("เกิดข้อผิดพลาด");
      });
    setTimeout(() => {
      this.setState({ isOtpDisabled: false });
    }, 20000);
  };

  // ปุ่ม ถัดไป < OTP >
  handleCheckUser = () => {
    this.setState({ isLoading: true });
    this.props.form.validateFields((err, values) => {
      this.checkOtp(values.m_tel_number, this.state.refOtp, values.code);
    });
  };

  // ปุ่ม ย้อนกลับ
  handleBackFormPhone() {
    this.setState({
      isOtp: false,
    });
  }

  // ปุ่ม ถัดไป < Phone >
  handleCheckPhone() {
    this.props.form.validateFields((err, values) => {
      if (values.m_tel_number) {
        this.setState({
          m_tel_number: values.m_tel_number,
          isOtp: true,
        });
      } else {
        message.warning("กรุณากรอกหมายเลขโทรศัพท์");
      }
    });
  }

  // ปุ่ม ยืนยัน การตั้งค่า SMS
  handleCheckStatus() {
    const { m_tel_number } = this.state;
    if (m_tel_number) {
      this.setMemberSMS();
    } else {
      message.error("เกิดข้อผิดพลาด");
    }
  }

  // ปุ่มแก้ไข Phone
  handleClearPhoneNumber = () => {
    this.setState({ isLoading: true });
    localStorage.removeItem("m_tel_number");
    this.setState({
      isOtp: false,
      isLoading: false,
      isPath: false,
      m_tel_number: localStorage.getItem("temp_tel"),
      isTel: "",
    });
  };

  renderLoddingForm() {
    return (
      <div
        className="text-center mb-3"
        style={{
          borderRadius: "2rem",
          border: "1px solid rgb(212, 212, 212)",
          boxShadow: "0px 0px 3px 0px rgb(212, 212, 212)",
          paddingTop: "0rem",
          color: "rgba(240, 57, 117, 1)",
        }}
      >
        <Tooltip onClick={() => window.location.reload()} placement="bottom">
          {" "}
          <Icon
            type="exclamation-circle"
            style={{
              paddingTop: 10,
              cursor: "pointer",
              type: "flex",
              fontSize: "50px",
              color: "rgba(240, 57, 117, 1)",
            }}
          />
          <p align="center">Not found</p>
        </Tooltip>
      </div>
    );
  }

  renderOtpForm() {
    const {
      getFieldDecorator,
      getFieldsError,
      isFieldTouched,
      getFieldError,
    } = this.props.form;
    const telError =
      isFieldTouched("m_tel_number") && getFieldError("m_tel_number");
    const otpError = isFieldTouched("code") && getFieldError("code");
    const { isOtpDisabled, refOtp } = this.state;
    return (
      <div>
        <Form.Item
          validateStatus={telError ? "error" : ""}
          help={telError || ""}
        >
          <Row span={24} justify="center">
            <Col span={16} align="center">
              {getFieldDecorator("m_tel_number", {
                initialValue: this.state.m_tel_number,
                rules: [
                  { required: true, message: "ระบุหมายเลขโทรศัพท์" },
                  { len: 10, message: "หมายเลขโทรศัพท์ไม่ถูกต้อง" },
                ],
              })(
                <Input
                  prefix={
                    <Icon type="phone" style={{ color: "rgba(0,0,0,.25)" }} />
                  }
                  shape="round"
                  placeholder="Phone"
                  disabled
                />
              )}
            </Col>
            <Col span={8} align="center">
              <Spin spinning={this.state.isOtpLoading}>
                <Button
                  type="primary"
                  shape="round"
                  onClick={this.handleSendOtp}
                  loading={isOtpDisabled}
                  disabled={isOtpDisabled}
                >
                  ส่ง otp
                </Button>
              </Spin>
            </Col>
          </Row>{" "}
          <Row span={24}>
            <div style={{ marginBottom: "-1rem" }}>รหัสอ้างอิง OTP :</div>
            {refOtp.map((item, i) => (
              <Tag key={i} style={{ color: "rgba(0,0,0,.25)" }}>
                {item}
              </Tag>
            ))}
          </Row>
        </Form.Item>
        <Form.Item
          validateStatus={otpError ? "error" : ""}
          help={otpError || ""}
        >
          {getFieldDecorator("code", {
            rules: [{ required: true, message: "กรอก OTP" }],
          })(
            <Input
              prefix={<Icon type="key" style={{ color: "rgba(0,0,0,.25)" }} />}
              placeholder="OTP"
            />
          )}
        </Form.Item>
        <Row
          gutter={[8, 24]}
          justify="center"
          style={{ marginBottom: "1.5rem" }}
        >
          <Col span={12}>
            <Button
              type="primary"
              shape="round"
              className="login-form-button font-main"
              onClick={() => this.handleBackFormPhone()}
            >
              ย้อนกลับ
            </Button>
          </Col>{" "}
          <Col span={12}>
            <Button
              type="primary"
              shape="round"
              className="login-form-button font-main"
              disabled={hasErrors(getFieldsError())}
              onClick={() => this.handleCheckUser()}
            >
              ถัดไป
            </Button>
          </Col>
        </Row>
      </div>
    );
  }

  renderFindPathForm() {
    const { sms_active, m_tel_number, isPath, sid, shopName } = this.state;
    return (
      <div className="text-center mb-3">
        {!sid && !shopName ? (
          <>{this.renderLoddingForm()}</>
        ) : (
          <>
            <Row gutter={[8, 24]} justify="center">
              <Col>
                {m_tel_number ? (
                  <div style={{ fontSize: "14px" }}>
                    {m_tel_number}
                    {"  "}
                    {!isPath ? (
                      <>
                        {" "}
                        <Button
                          type="link"
                          shape="circle"
                          onClick={this.handleClearPhoneNumber}
                        >
                          เปลี่ยน
                        </Button>
                      </>
                    ) : (
                      <></>
                    )}{" "}
                  </div>
                ) : (
                  <div></div>
                )}
              </Col>
              <Col>
                <Radio.Group
                  onChange={(e) =>
                    this.setState({
                      sms_active: e.target.value,
                    })
                  }
                  value={sms_active}
                >
                  <div align="left">
                    <Radio value={true}>รับการแจ้งเตือนข่าวสาร</Radio>
                  </div>
                  <div align="left">
                    <Radio value={false}>ยกเลิกการรับข่าวสาร</Radio>
                  </div>
                </Radio.Group>
              </Col>
              <Col span={16} offset={4}>
                <Button
                  type="primary"
                  shape="round"
                  className="login-form-button font-main"
                  onClick={() => this.handleCheckStatus()}
                >
                  ยืนยัน
                </Button>
              </Col>
            </Row>
          </>
        )}
      </div>
    );
  }

  renderNotFindPathForm() {
    const {
      getFieldDecorator,
      getFieldsError,
      isFieldTouched,
      getFieldError,
    } = this.props.form;
    const telError =
      isFieldTouched("m_tel_number") && getFieldError("m_tel_number");
    const { isOtp, sid, isTel, shopName } = this.state;
    return (
      <>
        {!sid || !shopName ? (
          <>{this.renderLoddingForm()}</>
        ) : isTel ? (
          <>{this.renderFindPathForm()}</>
        ) : isOtp ? (
          <>{this.renderOtpForm()}</>
        ) : (
          <>
            <Form.Item
              validateStatus={telError ? "error" : ""}
              help={telError || ""}
            >
              <Row>
                <Col>
                  {getFieldDecorator("m_tel_number", {
                    initialValue: this.state.m_tel_number,
                    rules: [
                      { required: true, message: "ระบุหมายเลขโทรศัพท์" },
                      { len: 10, message: "หมายเลขโทรศัพท์ไม่ถูกต้อง" },
                    ],
                  })(
                    <Input
                      prefix={
                        <Icon
                          type="phone"
                          style={{ color: "rgba(0,0,0,.25)" }}
                        />
                      }
                      shape="round"
                      placeholder="Phone"
                    />
                  )}
                </Col>
              </Row>
            </Form.Item>
            <div justify="center" style={{ marginBottom: "1.5rem" }}>
              <Button
                type="primary"
                shape="round"
                className="login-form-button font-main"
                disabled={hasErrors(getFieldsError())}
                onClick={() => this.handleCheckPhone()}
              >
                ถัดไป
              </Button>
            </div>
          </>
        )}
      </>
    );
  }

  render() {
    const { isPath, isFound, shopImg, shopName } = this.state;
    return (
      <div className="login-page font-sub">
        <Form onSubmit={this.handleSubmit} className="login-form">
          <Spin spinning={this.state.isLoading}>
            <div type="flex" justify="center" className="text-center mb-3">
              {shopImg ? (
                <>
                  <Link to="/">
                    <Tooltip
                      placement="bottom"
                      title={<span className="font-sub">หน้าแรก</span>}
                    >
                      <Avatar shape="square" size={64} src={shopImg} />
                    </Tooltip>
                  </Link>
                </>
              ) : (
                <>
                  <Tooltip placement="bottom">
                    <Avatar shape="square" size={64} src={shopImg} />
                  </Tooltip>
                </>
              )}
            </div>
            <div
              align="center"
              style={{
                type: "flex",
                fontSize: "16px",
              }}
            >
              ตั้งค่าการรับ SMS ข่าวสาร <br />{" "}
              {shopName ? <>จาก {shopName}</> : <></>}
            </div>
            {isFound ? (
              <></>
            ) : (
              <>
                {isPath ? (
                  <>{this.renderFindPathForm()}</>
                ) : (
                  <>{this.renderNotFindPathForm()}</>
                )}
              </>
            )}
          </Spin>
          <PoweredByGebtam />
        </Form>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  shops: state.shops,
});

const ManageSMSForm = Form.create({ name: "managesms" })(ManageSMS);

export default connect(mapStateToProps, {})(ManageSMSForm);
