import React, { useState } from "react";
import { Card, Avatar } from "antd";
import ShopService from "../../services/ShopService";
import { CardRatio, CardItem } from "../../styles/components/card";

const { Meta } = Card;
export default function PromotionCard({ promotion }) {
  const partPromotionImage = ShopService.getLogoHasPath(
    promotion.path_pro_pic,
    promotion.pro_pic
  );
  const [promotionImage, setPromotionImage] = useState(partPromotionImage);

  const onLoadImageError = () => {
    setPromotionImage("/mypro_1.png");
  };
  return (
    <Card
      hoverable
      cover={
        <CardRatio pdTop="100%">
          <CardItem>
            <Avatar
              shape="square"
              className="radius-0"
              style={{ height: "auto", width: "100%" }}
              onError={onLoadImageError}
              alt="promotion"
              src={promotionImage}
            />
          </CardItem>
        </CardRatio>
      }
    >
      <Meta
        className="promotion-meta"
        title={
          <span title={promotion.pro_text1 || "โปรโมชั่น"}>
            {promotion.pro_text1 || "โปรโมชั่น"}
          </span>
        }
        description={
          <span title={promotion.pro_condition || "ไม่มีเงื่อนไข"}>
            {promotion.pro_condition || "ไม่มีเงื่อนไข"}
          </span>
        }
      />
    </Card>
  );
}
