import React, { useEffect } from "react";
import { Route, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import { message } from "antd";
import NProgress from "nprogress";

const PrivateRoute = ({ component: Component, user, ...rest }) => {
  useEffect(() => {
    if (!user.isAuthenticated) message.warn("กรุณาเข้าสู่ระบบ");
  });
  useEffect(() => {
    setTimeout(() => {
      NProgress.done(true);
    }, 500);
  });

  return (
    <Route
      {...rest}
      render={(props) =>
        user.isAuthenticated === true ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={`/login?path=${props.location.pathname}${props.location.search}`}
          />
        )
      }
    />
  );
};

const mapStateToProps = (state) => ({
  user: state.user,
});

export default connect(mapStateToProps)(PrivateRoute);
