import React, { Component } from "react";
import Main from "./Main";
import { connect } from "react-redux";
import { getShopDetail, updateShopFollower } from "../store/actions/shop";
import { Skeleton, Row, Col, Tabs, Avatar, Icon } from "antd";

import ShopFeedList from "../components/shop/ShopFeedList";
import ShopDetail from "../components/shop/ShopDetail";
import "../styles/shopId.css";
import ShopPoint from "../components/shop/ShopPoint";
import ShopPromotionCouponHome from "../components/shop/ShopPromotionCouponHome";
import Follower from "../components/shop/Follower";

const { TabPane } = Tabs;

class ShopId extends Component {
  constructor(props) {
    super(props);
    this.state = {
      shop: {},
      isLoading: true,
      visible: false,
      followed: false,
    };
  }

  componentWillMount() {
    if (this.props.shops.length === 0) {
      this.getShopDetail();
    } else {
      this.myShop();
    }
  }

  myShop() {
    const { shops } = this.props;
    const sid = this.props.match.params.id;
    const shopDetail = shops.dataDetail[sid];
    if (!shopDetail) {
      this.getShopDetail();
    } else {
      console.log("else");
    }
  }

  getShopDetail() {
    const sid = this.props.match.params.id;
    const postData = { sid };
    this.props.getShopDetail(this.props.history, postData);
  }

  gotoMap(latLong) {
    window.open(`https://www.google.com/maps/search/?api=1&query=${latLong}`);
  }

  onTapChange(e) {
    const { history } = this.props;
    const { location } = history;
    history.push(`${location.pathname}?tabs=${e}`);
  }

  followerChange = (e) => {
    const { dataDetail } = this.props.shops;
    const sid = this.props.match.params.id;
    const shop = dataDetail[sid];
    shop.status = e ? 1 : 0;
    shop.followed = e;
    this.setState({ visible: false });
    this.props.updateShopFollower(this.props.history, shop);
  };

  followerChangeConfirm = (e) => {
    if (e) {
      this.followerChange(e);
    } else {
      this.setState({ visible: true, followed: e });
      // setFollowed(e);
    }
  };

  render() {
    const { dataDetail, pending } = this.props.shops;
    const { history } = this.props;
    const query = new URLSearchParams(history.location.search);
    const tabKey = query.get("tabs") || "home";
    const sid = this.props.match.params.id;
    const detail = dataDetail[sid];
    if (!pending && detail) {
      return (
        <Main title={detail.shop_name}>
          <Row type="flex" justify="center">
            <Col xs={24} sm={24} md={18} lg={16} className="mt-3 mb-3">
              <Row
                type="flex"
                justify="space-between"
                className="pd-2 b-sub c-w font-main text-center size-12"
              >
                <Col className="d-flex align-center">
                  <Icon
                    className="c-main size-15 bold"
                    type="left"
                    onClick={() => history.goBack()}
                  />
                </Col>
                <Col className="size-15">{detail.shop_name}</Col>
                <Col>
                  <Follower
                    followerChange={this.followerChange.bind(this)}
                    shop_name={detail.shop_name}
                    followed={detail.followed}
                  />
                </Col>
              </Row>
              <Tabs
                defaultActiveKey={tabKey}
                activeKey={tabKey}
                type="line"
                onChange={this.onTapChange.bind(this)}
                className="tab-bg"
              >
                <TabPane
                  tab={
                    <span className="font-main size-12 text-center">
                      <Avatar shape="square" size={40} src="/ic_1.png" />
                      <div className="line-12">หน้าแรก</div>
                    </span>
                  }
                  key="home"
                >
                  <Row type="flex" className="b-w pt-3  pb-3 font-sub">
                    <ShopFeedList sid={sid} history={history} />
                  </Row>
                </TabPane>
                <TabPane
                  tab={
                    <span className="font-main size-12">
                      <Avatar shape="square" size={40} src="/ic_2.png" />
                      <div className="line-12">แต้มของฉัน</div>
                    </span>
                  }
                  key="point"
                >
                  <Row type="flex" className="b-w   font-sub b-234">
                    <ShopPoint sid={sid} history={history} />
                  </Row>
                </TabPane>
                <TabPane
                  tab={
                    <span className="font-main size-12">
                      <Avatar shape="square" size={40} src="/ic_3.png" />
                      <div className="line-12">โปรโมชั่น / คูปอง</div>
                    </span>
                  }
                  key="promotion"
                >
                  <Row type="flex" className="b-w font-sub ">
                    <ShopPromotionCouponHome sid={sid} history={history} />
                  </Row>
                </TabPane>
                <TabPane
                  tab={
                    <span className="font-main size-12">
                      <Avatar shape="square" size={40} src="/ic_4.png" />
                      <div className="line-12">ข้อมูลร้าน</div>
                    </span>
                  }
                  key="detail"
                >
                  <ShopDetail shops={this.props.shops} />
                </TabPane>
              </Tabs>
            </Col>
          </Row>
        </Main>
      );
    } else {
      return (
        <Main>
          <Row type="flex" justify="center">
            <Col xs={22} sm={22} md={18} lg={16} className="mt-3 mb-3">
              <Skeleton loading active avatar />
            </Col>
          </Row>
        </Main>
      );
    }
  }
}

const mapStateToProps = (state) => ({
  shops: state.shops,
});

export default connect(mapStateToProps, { getShopDetail, updateShopFollower })(
  ShopId
);
