import { APP_ORG, APP_URL } from "../config";

import http from "./HttpService";
import { message } from "antd";

const AuthService = {
  async login(data) {
    const url = `${APP_URL}login`;
    return await http.requestPost(url, data);
  },
  async loginOTP(data) {
    const url = `${APP_URL}login_code`;
    return await http.requestPost(url, data);
  },
  async register(data = {}) {
    const url = `${APP_URL}signup`;
    return await http.requestPost(url, data);
  },
  async getProfile(data) {
    const url = `${APP_URL}myProfiles`;
    return await http.requestPost(url, data);
  },
  async saveProfile(data) {
    const url = `${APP_URL}memberProfileSave`;
    return await http.requestPost(url, data);
  },
  async sendVerifyOtp(data = {}) {
    const url = `${APP_URL}signUpSendCode`;
    return await http.requestPost(url, data);
  },
  async loginSendCode(data = {}) {
    const url = `${APP_URL}login_send_code`;
    return await http.requestPost(url, data);
  },
  async sendResetCode(data = {}) {
    const url = `${APP_URL}sendResetCode`;
    return await http.requestPost(url, data);
  },
  async resetPassword(data = {}) {
    const url = `${APP_URL}resetPwd`;
    return await http.requestPost(url, data);
  },
  logout: () => {
    localStorage.removeItem("atc");
  },
  getToken: () => {
    return localStorage.getItem("atc");
  },
  saveToken: (data) => {
    localStorage.setItem("atc", data);
  },
  goLogin(history, text) {
    if (history.location.pathname !== "/login")
      history.push(
        `/login?path=${history.location.pathname}${history.location.search}`
      );
    if (text) message.warning(text);
    // window.location.reload();
  },
  isLogin() {
    const atc = JSON.parse(this.getToken());
    return atc ? atc : false;
  },
  async checkUser(data) {
    const url = `${APP_URL}check_tel_dup`;
    return await http.requestPost(url, data);
  },
  async checkInfoUser(data) {
    const url = `${APP_ORG}check_tel_dup`;
    return await http.requestPost(url, data);
  },
  async getCategory(data) {
    const url = `${APP_ORG}get_categories`;
    return await http.requestPost(url, data);
  },

  // Update Profile
  async updateProfile(data = {}) {
    const url = `${APP_URL}member_profile_update`;
    return await http.requestPost(url, data);
  },
  async updateProfileEvent(data = {}) {
    const url = `${APP_URL}member_profile_event`;
    return await http.requestPost(url, data);
  },
};

export default AuthService;
