import { message } from "antd";

import ShopService from "../../services/ShopService";
import {
  getShopsSuccess,
  getShopsError,
  getAllShopsSuccess,
  updateShopFollow,
  setShopDetail,
  getShopsPending,
  getShopsFollowPending,
  getShopsFollowSuccess,
  getShopsFollowError,
  getAllShopsFollowSuccess,
  setShopPoint,
} from "./actionCreators";
import AuthService from "../../services/AuthService";
import { logoutAndGotoLogin } from "./auth";
import { checkTrue } from "../../helpers/util";

export const getAllShop = (history, data) => (dispatch) => {
  // dispatch(getShopsPending());
  const act = JSON.parse(AuthService.getToken());
  const newPostData = { ...data };
  newPostData.access_token = act.access_token;
  ShopService.getAllShop(newPostData)
    .then((resp) => {
      if (resp.success) {
        const newData = {};
        newData.recordsTotal = resp.recordsTotal;
        if (resp.result) {
          newData.data = resp.result.reduce((a, b) => {
            a[b.sid] = b;
            return a;
          }, {});
        } else {
          newData.data = {};
        }
        if (!resp.result || resp.result.length < newPostData.limit) {
          dispatch(getAllShopsSuccess(newData));
        } else {
          dispatch(getShopsSuccess(newData));
        }
      } else {
        if (
          resp.result.ERROR &&
          (resp.result.ERROR.CODE === "SE" || resp.result.ERROR.CODE === "TE")
        ) {
          dispatch(logoutAndGotoLogin(history));
        }
        dispatch(getShopsError(resp.result));
      }
    })
    .catch((error) => {
      console.log(error);
      dispatch(getShopsError(error));
    });
};

export const updateShopFollower = (history, data) => (dispatch) => {
  const act = JSON.parse(AuthService.getToken());
  const newPostData = { ...data };
  newPostData.access_token = act.access_token;
  ShopService.updateShopFollower(newPostData)
    .then((resp) => {
      if (resp.success) {
        if (checkTrue(newPostData.followed)) {
          message.info(`ติดตามร้าน ${newPostData.shop_name}`);
        } else {
          message.warning(`เลิกติดตามร้าน ${newPostData.shop_name}`);
        }
        dispatch(updateShopFollow(data));
      } else {
        if (
          resp.ERROR &&
          (resp.ERROR.CODE === "SE" || resp.ERROR.CODE === "TE")
        ) {
          dispatch(logoutAndGotoLogin(history));
        }
      }
    })
    .catch((error) => {
      console.log(error);
    });
};

export const getShopDetail = (history, data) => (dispatch) => {
  const act = JSON.parse(AuthService.getToken());
  const newPostData = { ...data };
  newPostData.access_token = act.access_token;
  dispatch(getShopsPending());
  ShopService.getShopDetail(newPostData)
    .then((resp) => {
      if (resp.success) {
        newPostData.sid = resp.result.sid;
        getShopBranchs(dispatch, resp.result, newPostData, history);
      } else {
        if (
          resp.ERROR &&
          (resp.ERROR.CODE === "SE" || resp.ERROR.CODE === "TE")
        ) {
          dispatch(logoutAndGotoLogin(history));
        }
        dispatch(getShopsError(resp));
      }
    })
    .catch((error) => {
      dispatch(getShopsError(error));
      console.log(error);
    });
};

// CheckIn
export const getCheckShopDetail = (data) => (dispatch) => {
  return ShopService.getCheckShopDetail(data);
};
export const getCheckInShop = (data) => (dispatch) => {
  return ShopService.getCheckInShop(data);
};
export const getCheckOutShop = (data) => (dispatch) => {
  return ShopService.getCheckOutShop(data);
};
export const getCheckOtpShop = (data) => (dispatch) => {
  return ShopService.getCheckOtpShop(data);
};
export const sendVerifyOtpShop = (data) => (dispatch) => {
  return ShopService.sendVerifyOtpShop(data);
};

// SMS
export const getMemberSMS = (data) => (dispatch) => {
  return ShopService.getMemberSMS(data);
};
export const setMemberSMS = (data) => (dispatch) => {
  return ShopService.setMemberSMS(data);
};

const getShopBranchs = (dispatch, data, postData, history) => {
  ShopService.getShopBranchs(postData)
    .then((resp) => {
      if (resp.success) {
        data.branchs = resp.result;
        dispatch(setShopDetail(data));
      } else {
        if (
          resp.ERROR &&
          (resp.ERROR.CODE === "SE" || resp.ERROR.CODE === "TE")
        ) {
          dispatch(logoutAndGotoLogin(history));
        }
        dispatch(getShopsError(resp));
      }
    })
    .catch((error) => {
      dispatch(getShopsError(error));
      console.log(error);
    });
};

export const getMyShops = (history, data) => (dispatch) => {
  const act = JSON.parse(AuthService.getToken());
  const newPostData = { ...data };
  newPostData.access_token = act.access_token;
  dispatch(getShopsFollowPending());
  ShopService.getMyshops(newPostData)
    .then((resp) => {
      if (resp.success) {
        const newData = {};
        newData.recordsTotal = resp.recordsTotal;
        if (resp.result) {
          newData.data = resp.result.reduce((a, b) => {
            a[b.sid] = b;
            return a;
          }, {});
        } else {
          newData.data = {};
        }

        if (!resp.result || resp.result.length < newPostData.limit) {
          dispatch(getAllShopsFollowSuccess(newData));
        } else {
          dispatch(getShopsFollowSuccess(newData));
        }
      } else {
        if (
          resp.ERROR &&
          (resp.ERROR.CODE === "SE" || resp.ERROR.CODE === "TE")
        ) {
          dispatch(logoutAndGotoLogin(history));
        }
        dispatch(getShopsFollowError(resp));
      }
    })
    .catch((error) => {
      dispatch(getShopsFollowError(error));
      console.log(error);
    });
};

export const setShopsPoints = (history, data) => (dispatch) => {
  const act = JSON.parse(AuthService.getToken());
  const newPostData = { ...data };
  newPostData.access_token = act.access_token;
  ShopService.getMyPointShop(newPostData)
    .then((resp) => {
      if (resp.success) {
        newPostData.member_stamp = resp.result[1].amount;
        dispatch(setShopPoint(newPostData));
      } else {
        dispatch(logoutAndGotoLogin(history));
      }
    })
    .catch((error) => {
      console.log(error);
    });
};
