import React, { useEffect } from "react";
import { Route } from "react-router-dom";
import NProgress from "nprogress";

export default function FancyRoute(props) {
  useEffect(() => {
    setTimeout(() => {
      NProgress.done(true);
    }, 500);
  });
  return <Route {...props} />;
}
