import React, { useState } from "react";
import { Modal, Icon, Avatar, Input, message } from "antd";
import {
  FacebookShareButton,
  TwitterShareButton,
  LineShareButton,
} from "react-share";
import { CopyToClipboard } from "react-copy-to-clipboard";

import { ReactComponent as line } from "../../assets/line-logo.svg";

export default function SharedModal({ visible, setVisible, feedId }) {
  const [link] = useState(`${window.location.origin}/#/shared/feed/${feedId}`);
  const onHideModal = () => {
    setVisible(false);
  };
  return (
    <Modal
      closable={true}
      visible={visible}
      onOk={onHideModal}
      onCancel={onHideModal}
      footer={false}
      centered
      width={400}
    >
      <div className="text-center">
        <FacebookShareButton url={link}>
          {" "}
          <Avatar
            style={{ backgroundColor: "#108ee9" }}
            className="  align-center  mt-2 mb-2 d-flex"
            size={50}
            icon={<Icon type="facebook" className="mx-auto" />}
          />
        </FacebookShareButton>
        <TwitterShareButton url={link}>
          <Avatar
            style={{ backgroundColor: "#2db7f5" }}
            className="  align-center mt-2 mb-2  ml-1 d-flex"
            size={50}
            icon={<Icon type="twitter" className="mx-auto" />}
          />
        </TwitterShareButton>
        <LineShareButton url={link}>
          <Avatar
            style={{ backgroundColor: "#38C810" }}
            className="  align-center mt-2 mb-2  ml-1 d-flex"
            // src="/icon-line.svg"
            size={50}
            icon={<Icon component={line} className="mx-auto" />}
          />
        </LineShareButton>
        <Input
          value={link}
          addonAfter={
            <CopyToClipboard
              text={link}
              onCopy={() =>
                message.success({
                  content: (
                    <>
                      <span className="copied-code">{link}</span> copied.
                    </>
                  ),
                })
              }
            >
              <Icon className="d-flex align-center mt-2 mb-2" type="copy" />
            </CopyToClipboard>
          }
        />
      </div>
    </Modal>
  );
}
