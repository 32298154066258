import { BASE_URL } from "../config";
import { objectToFormData } from "../helpers/util";

const http = {
  async requestPost(url = "", data = {}) {
    const newData = objectToFormData(data);
    // Default options are marked with *
    const response = await fetch(`${BASE_URL}${url}`, {
      method: "POST", // *GET, POST, PUT, DELETE, etc.
      mode: "cors", // no-cors, *cors, same-origin
      cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
      credentials: "same-origin", // include, *same-origin, omit
      redirect: "follow", // manual, *follow, error
      referrer: "no-referrer", // no-referrer, *client
      body: newData, // body data type must match "Content-Type" header
    });
    return response.json(); // parses JSON response into native JavaScript objects
  },
  async requestGet(url = "", data = {}) {
    const newData = objectToFormData(data);
    // Default options are marked with *
    const response = await fetch(`${BASE_URL}${url}`, {
      method: "GET", // *GET, POST, PUT, DELETE, etc.
      mode: "cors", // no-cors, *cors, same-origin
      cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
      credentials: "same-origin", // include, *same-origin, omit
      redirect: "follow", // manual, *follow, error
      referrer: "no-referrer", // no-referrer, *client
      // body: newData, // body data type must match "Content-Type" header
    });
    return response.json(); // parses JSON response into native JavaScript objects
  },
};

export default http;
