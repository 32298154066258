import React from "react";
import { Tooltip, Button, Icon } from "antd";

function ContactShop({ contact, className, size }) {
  const onOpenPhone = () => {
    window.open(`tel:${contact}`);
  };
  return (
    <Tooltip
      placement="top"
      title={<span className="font-sub">ติดต่อร้านค้า</span>}
    >
      <Button
        size={size}
        // shape="circle"
        onClick={onOpenPhone}
        className={`${className}`}
      >
        <Icon type="phone" style={{ verticalAlign: -4 }} />
      </Button>
    </Tooltip>
  );
}

ContactShop.defaultProps = {
  size: "small",
};

export default ContactShop;
