import {
  GET_ALL_FEEDS_SUCCESS,
  GET_FEEDS_PENDING,
  GET_FEEDS_SUCCESS,
  GET_FEEDS_ERROR,
  GET_ALL_FEEDS_NEAR_SUCCESS,
  GET_FEEDS_NEAR_PENDING,
  GET_FEEDS_NEAR_SUCCESS,
  GET_FEEDS_NEAR_ERROR,
  SET_FEEDS_NEAR,
  SET_FEEDS_ALL,
} from "../actions/types";

const feeds = (state = {}, action) => {
  const data = { ...state.data };
  const dataNear = { ...state.dataNear };
  switch (action.type) {
    case GET_FEEDS_PENDING:
      return {
        ...state,
        pending: true,
      };
    case GET_FEEDS_SUCCESS:
      return {
        ...state,
        pending: false,
        lat: action.payload.lat,
        long: action.payload.long,
        data: { ...data, ...action.payload.data },
      };
    case GET_ALL_FEEDS_SUCCESS:
      return {
        ...state,
        hasMore: false,
        pending: false,
        lat: action.payload.lat,
        long: action.payload.long,
        data: { ...data, ...action.payload.data },
      };
    case GET_FEEDS_ERROR:
      return {
        ...state,
        pending: false,
        error: action.error,
      };
    case GET_FEEDS_NEAR_PENDING:
      return {
        ...state,
        pending: true,
      };
    case GET_FEEDS_NEAR_SUCCESS:
      return {
        ...state,
        pending: false,
        lat: action.payload.lat,
        long: action.payload.long,
        dataNear: { ...dataNear, ...action.payload.data },
      };
    case GET_ALL_FEEDS_NEAR_SUCCESS:
      return {
        ...state,
        hasMoreNEAR: false,
        pending: false,
        lat: action.payload.lat,
        long: action.payload.long,
        dataNear: { ...dataNear, ...action.payload.data },
      };
    case GET_FEEDS_NEAR_ERROR:
      return {
        ...state,
        pending: false,
        error: action.error,
      };
    case SET_FEEDS_NEAR:
      return {
        ...state,
        dataNear: action.payload,
      };
    case SET_FEEDS_ALL:
      return {
        ...state,
        data: action.payload,
      };
    default:
      return state;
  }
};
export default feeds;
