export const SET_CURRENT_USER = "SET_CURRENT_USER";
export const GET_CURRENT_USER = "GET_CURRENT_USER";
export const LOGOUT = "LOGOUT";
export const CLEAR_DATA = "CLEAR_DATA";
export const REGISTER_SUCCESS = "REGISTER_SUCCESS";
export const GET_ERRORS = "GET_ERRORS";
export const GET_SHOPS = "GET_SHOPS";
export const SET_SHOPS_POINT = "SET_SHOPS_POINT";
export const SET_SHOPS = "SET_SHOPS";
export const GET_PRODUCTS = "GET_PRODUCTS";
export const GET_PRODUCTS_PENDING = "SET_PRODUCTS_PENDING";
export const GET_PRODUCTS_SUCCESS = "GET_PRODUCTS_SUCCESS";
export const GET_ALL_PRODUCTS_SUCCESS = "GET_ALL_PRODUCTS_SUCCESS";
export const GET_PRODUCTS_ERROR = "GET_PRODUCTS_ERROR";
export const GET_SHOPS_PENDING = "SET_SHOPS_PENDING";
export const GET_SHOPS_SUCCESS = "GET_SHOPS_SUCCESS";
export const UPDATE_SHOP_FOLLOW = "UPDATE_SHOP_FOLLOW";
export const SET_SHOP_DETAIL = "SET_SHOP_DETAIL";
export const GET_ALL_SHOPS_SUCCESS = "GET_ALL_SHOPS_SUCCESS";
export const GET_SHOPS_ERROR = "GET_SHOPS_ERROR";
export const GET_SHOPS_FOLLOW_PENDING = "SET_SHOPS_FOLLOW_PENDING";
export const GET_SHOPS_FOLLOW_SUCCESS = "GET_SHOPS_FOLLOW_SUCCESS";
export const GET_ALL_SHOPS_FOLLOW_SUCCESS = "GET_ALL_SHOPS_FOLLOW_SUCCESS";
export const GET_SHOPS_FOLLOW_ERROR = "GET_SHOPS_FOLLOW_ERROR";
export const SET_PRODUCTS = "SET_PRODUCTS";
export const SET_SHOP_PRODUCTS = "SET_SHOP_PRODUCTS";
export const SET_FEEDS_NEAR = "SET_FEEDS_NEAR";
export const SET_FEEDS_ALL = "SET_FEEDS_ALL";
export const GET_FEEDS_SUCCESS = "GET_FEEDS_SUCCESS";
export const GET_FEEDS_PENDING = "GET_FEEDS_PENDING";
export const GET_FEEDS_ERROR = "GET_FEED_ERROR";
export const GET_ALL_FEEDS_SUCCESS = "GET_ALL_FEEDS_SUCCESS";
export const GET_FEEDS_NEAR_SUCCESS = "GET_FEEDS_NEAR_SUCCESS";
export const GET_FEEDS_NEAR_PENDING = "GET_FEEDS_NEAR_PENDING";
export const GET_FEEDS_NEAR_ERROR = "GET_FEEDS_NEAR_ERROR";
export const GET_ALL_FEEDS_NEAR_SUCCESS = "GET_ALL_FEEDS_NEAR_SUCCESS";
export const SET_PAYMENT_VISIBLE = "SET_PAYMENT_VISIBLE";
export const SET_IS_PAYMENT = "SET_IS_PAYMENT";
export const SET_ORGANIS = "SET_ORGANIS";
