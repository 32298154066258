import http from "./HttpService";
import { APP_URL } from "../config";
import AuthService from "./AuthService";

// status  0 รับออร์เดอร์
//         1 ลูกค้าชำระเงินแล้ว
//         2 ร้านค้ารับออร์เดอร์
//         3 รายการสำเร็จแล้ว
const OrderService = {
  async insertOrder(data) {
    const url = `${APP_URL}insert_order`;
    return await http.requestPost(url, data);
  },
  async historyOrders(data) {
    // const url = `${APP_URL}history_order`;history_arr_status
    const url = `${APP_URL}history_arr_status`;
    return await http.requestPost(url, data);
  },
  async cancelOrder(orderId) {
    const act = JSON.parse(AuthService.getToken());
    const data = { access_token: act.access_token, order_id: orderId };
    const url = `${APP_URL}cancel_order`;
    return await http.requestPost(url, data);
  },
  async getOrderDetail(orderId) {
    const act = JSON.parse(AuthService.getToken());
    const data = { access_token: act.access_token, order_id: orderId };
    const url = `${APP_URL}get_detail_order`;
    return await http.requestPost(url, data);
  },
};
export default OrderService;
