const env = process.env.NODE_ENV || 'development'
//export const SUB_URL = "//www.gebtam.com/";
//export const BASE_URL = "http://www.dev.onnetthai.com/";
// export const SUB_URL = "https://www.gebtam.com/";
// export const BASE_URL = "https://www.gebtam.com/";
export const SUB_URL = {
  development: 'https://www.gebtam.com/',
  production: 'https://www.gebtam.com/',
}[env]
export const BASE_URL = {
  development: 'https://www.gebtam.com/',
  production: 'https://www.gebtam.com/',
}[env]

// export const SUB_URL = "http://localhost:4444/";
// export const BASE_URL = "http://localhost:4444/";
// export const SUB_URL = 'http://203.150.107.56/';
// export const BASE_URL = 'http://203.150.107.56/';
//export const DEV_URL = "http://203.150.107.56/";
export const APP_URL = 'appi/v2/members/'
export const APP_ORG = 'appi/v2/organis/'
export const APP_SHOP_V1 = 'api/v1/shops/'
export const APP_SMS = 'appi/v2/manage/'
export const APP_CHECKIN = 'appi/v2/checkin/'
// export const APP_URL = "appi/v1/members/";
export const APP_URL_V12 = 'appi/v1.2/members/'
export const ORDER_URL = 'order/v1/members/'
