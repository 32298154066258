import React, { Component } from "react";
import md5 from "react-native-md5";
import md5V2 from "md5";
import {
  Form,
  Row,
  Input,
  Col,
  Button,
  Avatar,
  Icon,
  message,
  Tooltip,
  Spin,
} from "antd";
import { Link } from "react-router-dom";
import AuthService from "../services/AuthService";

class Forgot extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      isOtpLoading: false,
    };
  }

  validateToNextPassword = (rule, value, callback) => {
    const { form } = this.props;
    if (value && this.state.confirmDirty) {
      form.validateFields(["confirm"], { force: true });
    }
    callback();
  };

  handleSendOtp = () => {
    const m_tel_number = this.props.form.getFieldValue("m_tel_number");
    const telError = this.props.form.getFieldError("m_tel_number");
    if (!telError && !m_tel_number) {
      return message.error("กรุณาระบุหมายเลขโทรศัพท์");
    } else if (telError) {
      return message.error("กรุณาระบุหมายเลขโทรศัพท์ให้ถูกต้อง");
    }
    const postData = { to: m_tel_number, user: "test" };
    this.setState({ isOtpLoading: true });
    AuthService.sendResetCode(postData)
      .then((resp) => {
        const data = resp;
        if (data.success) {
          message.success("ส่ง OTP สำเร็จ");
        } else {
          message.warning("ไม่สามารถส่ง sms ได้");
        }
        this.setState({ isOtpLoading: false });
      })
      .catch((error) => {
        this.setState({ isOtpLoading: false });
        console.log(error);
        message.error("เกิดข้อผิดพลาด");
      });
  };

  handleSubmit = (e) => {
    e.preventDefault();
    this.props.form.validateFieldsAndScroll((err, values) => {
      const param = new URLSearchParams(this.props.history.location.search);
      const path = param.get("path") ? param.get("path") : "/";
      if (!err) {
        this.setState({ isLoading: true });
        const newValues = { ...values };
        newValues.new_password = md5V2(
          // eslint-disable-next-line no-control-regex
          `${md5.str_md5(values.new_password).split(/\u0000/)[0]}AppDD2018`
        );
        newValues.fromWeb = true;
        AuthService.resetPassword(newValues)
          .then((resp) => {
            if (resp.success) {
              message.success("เปลี่ยนรหัสผ่านสำเร็จ");
              if (path !== "/") this.props.history.push(`/login?path=${path}`);
              else this.props.history.push("/login");
            } else {
              if (resp.result && resp.result.ERROR_CODE === "WC") {
                message.warning("เปลี่ยนรหัสผ่านไม่สำเร็จ Otp ไม่ถูกต้อง");
              } else {
                message.warning("เปลี่ยนรหัสผ่านไม่สำเร็จ");
              }
              this.setState({ isLoading: false });
            }
          })
          .catch((error) => {
            this.setState({ isLoading: false });
            console.log(error);
            message.error("เกิดข้อผิดพลาด");
          });
      }
    });
  };

  render() {
    const { getFieldDecorator } = this.props.form;
    const formItemLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 8 },
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 16 },
      },
    };

    const param = new URLSearchParams(this.props.history.location.search);
    const path = param.get("path") ? param.get("path") : "/";
    const login = path !== "/" ? `/login?path=${path}` : "/login";
    const register = path !== "/" ? `/register?path=${path}` : "/register";
    return (
      <div className="login-page">
        <Form
          {...formItemLayout}
          onSubmit={this.handleSubmit}
          className="login-form font-sub"
        >
          <div type="flex" justify="center" className="text-center mb-3">
            <Link to="/">
              <Tooltip
                placement="bottom"
                title={<span className="font-sub">หน้าแรก</span>}
              >
                <Avatar
                  shape="square"
                  size={64}
                  // icon="user"
                  src="/gebtam.png"
                />
              </Tooltip>
            </Link>
          </div>
          {/* <div className="text-center">
            <h1 className="font-main c-main ">ลืมรหัสผ่าน</h1>
          </div> */}

          <Form.Item label="โทรศัพท์">
            <Row gutter={8}>
              <Col span={16}>
                {getFieldDecorator("m_tel_number", {
                  rules: [
                    { required: true, message: "ระบุหมายเลขโทรศัพท์" },
                    { len: 10, message: "หมายเลขโทรศัพท์ไม่ถูกต้อง" },
                  ],
                })(
                  <Input
                    prefix={
                      <Icon type="phone" style={{ color: "rgba(0,0,0,.25)" }} />
                    }
                    placeholder="Phone"
                  />
                )}
              </Col>
              <Col span={8}>
                <Spin spinning={this.state.isOtpLoading}>
                  <Button
                    style={{ position: "relative" }}
                    type="primary"
                    shape="round"
                    onClick={this.handleSendOtp}
                  >
                    ส่ง otp
                  </Button>
                </Spin>
              </Col>
            </Row>
          </Form.Item>
          <Form.Item label="OTP">
            {getFieldDecorator("code", {
              rules: [
                {
                  required: true,
                  message: "กรอก OTP",
                },
              ],
            })(<Input />)}
          </Form.Item>
          <Form.Item label="รหัสผ่านใหม่" hasFeedback>
            {getFieldDecorator("new_password", {
              rules: [
                {
                  required: true,
                  message: "กรอกรหัสผ่าน",
                },
                {
                  validator: this.validateToNextPassword,
                },
                {
                  min: 8,
                  message: "รหัสผ่านอย่างน้อย 8 ตัวอักษร",
                },
              ],
            })(<Input.Password />)}
          </Form.Item>
          <Form.Item label="ยันยันรหัสผ่าน" hasFeedback>
            {getFieldDecorator("confirm", {
              rules: [
                {
                  required: true,
                  message: "ยืนยันรหัสผ่าน",
                },
                {
                  validator: this.compareToFirstPassword,
                },
                {
                  min: 8,
                  message: "รหัสผ่านอย่างน้อย 8 ตัวอักษร",
                },
              ],
            })(<Input.Password onBlur={this.handleConfirmBlur} />)}
          </Form.Item>
          <Row>
            <Col span={24}>
              <Button
                type="primary"
                htmlType="submit"
                block
                shape="round"
                className="font-main size-15"
                loading={this.state.isLoading}
              >
                เปลี่ยนรหัสผ่าน
              </Button>
            </Col>
          </Row>
          <Row className="mt-2" type="flex" justify="space-between">
            <Link to={login} className="font-main text-center size-12 ">
              เข้าสู่ระบบ
            </Link>
            <Link to={register} className="font-main text-center size-12 ">
              สมัครสมาชิก
            </Link>
          </Row>
        </Form>
      </div>
    );
  }
}

const FogotForm = Form.create({ name: "fogot" })(Forgot);
export default FogotForm;
