import React, { createContext, useState } from "react";

export const OrderHistoryContext = createContext(null);

const OrderHistoryProvider = ({ children }) => {
  const [ordersHistory, setOrdersHistory] = useState([]);

  const store = {
    ordersHistory: [ordersHistory, setOrdersHistory],
  };

  return (
    <OrderHistoryContext.Provider value={store}>
      {children}
    </OrderHistoryContext.Provider>
  );
};
export default OrderHistoryProvider;
