import React, { useState, useEffect, useRef } from "react";
import { connect } from "react-redux";
import { Row, Col } from "antd";
import InfiniteScroll from "react-infinite-scroller";

import { logoutAndGotoLogin } from "../../store/actions/auth";
import ShopService from "../../services/ShopService";
import AuthService from "../../services/AuthService";
import FeedSkeleton from "../feed/FeedSkeleton";
import CheckTypeFeed from "../feed/CheckTypeFeed";

const ShopFeedList = (props) => {
  const [feedState, setFeedState] = useState([]);
  const [isFeedLoad, setIsFeedLoad] = useState(false);
  const [hasMore, setHasMore] = useState(true);
  const latestProps = useRef(props);
  const limit = 15;
  useEffect(() => {
    latestProps.current = props;
  });
  useEffect(() => {
    const getShopFeed = () => {
      const { sid, logoutAndGotoLogin, history } = latestProps.current;
      const act = JSON.parse(AuthService.getToken());
      const postData = {
        access_token: act.access_token,
        sid,
        start: "0",
        limit: limit,
      };
      setIsFeedLoad(true);
      ShopService.getShopFeeds(postData)
        .then((resp) => {
          setIsFeedLoad(false);
          if (resp.success) {
            if (resp.result.length < limit) setHasMore(false);
            setFeedState(resp.result);
          } else {
            logoutAndGotoLogin(history);
          }
        })
        .catch((error) => {
          setIsFeedLoad(false);
          console.log(error);
        });
    };
    getShopFeed();
  }, []);

  const loadFunc = () => {
    if (feedState.length < limit) return;
    const { sid, logoutAndGotoLogin, history } = latestProps.current;
    const act = JSON.parse(AuthService.getToken());
    const postData = {
      access_token: act.access_token,
      sid,
      start: feedState.length,
      limit,
    };
    ShopService.getShopFeeds(postData)
      .then((resp) => {
        setIsFeedLoad(false);
        if (resp.success) {
          if (resp.result.length < limit) setHasMore(false);
          const newFeedState = [...feedState, ...resp.result];

          setFeedState(newFeedState);
        } else {
          logoutAndGotoLogin(history);
        }
      })
      .catch((error) => {
        setIsFeedLoad(false);
        console.log(error);
      });
  };

  return (
    <InfiniteScroll
      pageStart={0}
      loadMore={loadFunc}
      hasMore={hasMore}
      loader={
        <div className="loader" key={0}>
          <FeedSkeleton />
        </div>
      }
      useWindow={true}
      className="w-100"
    >
      <Row type="flex" gutter={[8, 8]} justify="center">
        {" "}
        {feedState.map((feed) => {
          return (
            <Col
              xs={12}
              sm={12}
              md={12}
              lg={12}
              key={feed.feed_node}
              className="d-flex"
            >
              <CheckTypeFeed feed={feed} />
            </Col>
          );
        })}
      </Row>
      {isFeedLoad ? <FeedSkeleton /> : null}
    </InfiniteScroll>
  );
};

export default connect(null, { logoutAndGotoLogin })(ShopFeedList);
