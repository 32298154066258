import React, { useState, useEffect } from "react";
import { useParams, useHistory } from "react-router-dom";

import "../../styles/feed.css";
import AuthService from "../../services/AuthService";
import { message, Spin } from "antd";
import FeedService from "../../services/FeedService";
import FeedCardCommentList from "./feed-card/FeedCardCommentList";
import FeedAddComment from "./feed-card/FeedAddComment";
import FeedCardView from "./feed-card/FeedCardView";

function FeedCard() {
  const [renew, setRenew] = useState(false);
  const [feed, setFeed] = useState({});
  const [comments, setComments] = useState([]);
  const [isLoading, setLoading] = useState(true);
  const params = useParams();
  const history = useHistory();
  const query = new URLSearchParams(history.location.search);
  const action = query.get("action");
  useEffect(() => {
    const fetchComments = async () => {
      const act = JSON.parse(AuthService.getToken());
      const postData = { top_node: params.id };
      if (act) {
        postData.access_token = act.access_token;
      }
      try {
        const resp = await FeedService.getFeed(postData);
        if (resp.success) {
          const newComments = resp.result.map((comment) => {
            let newComment = {};
            if (comment[0]) {
              newComment = comment[0];
              newComment.isUpNode = false;
            } else {
              newComment = comment[1];
              newComment.isUpNode = true;
            }
            return newComment;
          });
          setFeed(resp.top_result);
          setComments(newComments);
        }
        // console.log(resp);
      } catch (error) {
        console.log(error);
        message.error("เกิดข้อผิดพลาด");
      }
      setLoading(false);
    };
    fetchComments();
  }, [params.id, renew]);

  const onLikeClick = async () => {
    const act = JSON.parse(AuthService.getToken());
    const postData = { feed_node: params.id, sid: feed.sid };
    if (act) {
      postData.access_token = act.access_token;
    }
    try {
      const resp = await FeedService.feedClickLike(postData);
      if (resp.success) {
        const newFeed = { ...feed };
        if (Number(feed.liked)) {
          newFeed.liked = 0;
          newFeed.all_liked = Number(feed.all_liked) - 1;
        } else {
          newFeed.liked = 1;
          newFeed.all_liked = Number(feed.all_liked) + 1;
        }
        setFeed(newFeed);
      }
      console.log(resp);
    } catch (error) {
      console.log(error);
      message.error("เกิดข้อผิดพลาด");
    }
  };

  const saveComment = async (postData) => {
    try {
      const resp = await FeedService.feedComment(postData);
      if (resp.success) {
        setRenew((prev) => !prev);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const onComment = (text) => {
    const act = JSON.parse(AuthService.getToken());
    const postData = {
      top_node: feed.feed_node,
      up_node: feed.feed_node,
      feed_text: text,
      sid: feed.feed_shop_id,
    };
    if (act) {
      postData.access_token = act.access_token;
    }
    saveComment(postData);
  };

  const onReply = (text, comment) => {
    const act = JSON.parse(AuthService.getToken());
    const postData = {
      top_node: comment.top_node,
      up_node: comment.feed_node,
      feed_text: text,
      sid: comment.feed_shop_id,
    };
    if (act) {
      postData.access_token = act.access_token;
    }
    saveComment(postData);
  };

  const onCommentClick = () => {
    const textCommentRef = document.getElementById(
      `text-comment${feed.feed_node}`
    );
    textCommentRef.focus();
  };

  if (isLoading) {
    return (
      <Spin spinning={true} tip="loadign...">
        <div className="mt-6"></div>
      </Spin>
    );
  } else {
    return (
      <div className="bg-w mt-3 sm-mt-0 mb-3 sm-mb-0">
        <FeedCardView
          feed={feed}
          onLikeClick={onLikeClick}
          onCommentClick={onCommentClick}
        />
        <FeedCardCommentList comments={comments} onReply={onReply} />
        <FeedAddComment
          feed_node={feed.feed_node}
          onComment={onComment}
          autoFocus={action}
          isPadding
        />
      </div>
    );
  }
}

export default FeedCard;
