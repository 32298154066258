import React, { Component } from "react";
import { connect } from "react-redux";
import { Row, Col, message, Select } from "antd";
import {
  InfiniteLoader,
  List,
  WindowScroller,
  AutoSizer,
  CellMeasurerCache,
  CellMeasurer,
} from "react-virtualized";

import { getFeedsNear, setFeedsNears } from "../../store/actions/feed";
import CheckTypeFeed from "../feed/CheckTypeFeed";
import FeedSkeleton from "../feed/FeedSkeleton";
import {
  getRowsAmount,
  getMaxItemsAmountPerRow,
  generateIndexesForRow,
} from "../../helpers/util";
import { withRouter } from "react-router-dom";
import AuthService from "../../services/AuthService";
import ShopService from "../../services/ShopService";
import FeedService from "../../services/FeedService";

const { Option } = Select;
class FeedNearGrid extends Component {
  infiniteLoaderRef = React.createRef();
  constructor(props) {
    super(props);
    this.state = { provinces: "", province: [], isLocationError: false };
    this._cache_grid = new CellMeasurerCache({
      fixedWidth: true,
      minHeight: 100,
    });
  }

  componentWillMount() {
    this.getLocation();
  }

  getLocation() {
    // if (this.state.isLocationError) {
    if (navigator.geolocation) {
      this.getProvince();
      navigator.geolocation.getCurrentPosition(
        this.getFeedsNear.bind(this),
        this.getLocationError.bind(this)
      );
      console.log("available");
    } else {
      console.log("unavailable");
    }
    // } else {
    // this.revokePermission();
    // }
  }

  getProvince() {
    ShopService.getProvince()
      .then((res) => {
        if (res.success) {
          this.setState({
            province: res.result,
          });
        } else {
          this.setState({
            province: null,
          });
        }
      })
      .catch((error) => {
        this.setState({ isLoading: false });
        console.log(error);
        message.error("เกิดข้อผิดพลาด");
      });
  }

  getFeedsNear(position) {
    const { history, getFeedsNear, feeds, showFeedStyle } = this.props;
    const limit = showFeedStyle === 1 ? 50 : 20;
    const length = Object.keys(feeds.dataNear).length;
    if (length === 0) {
      const lat = position.coords.latitude;
      const long = position.coords.longitude;
      const postData = {
        lat,
        long,
        start: "0",
        limit,
        area: 100,
      };
      getFeedsNear(history, postData);
      this.setState({
        isLocationError: false,
      });
    }
  }

  onChangeProvince = (province) => {
    this.setState({
      provinces: province,
    });
    const location = this.state.province.find((p) => p.Id === province);
    console.log("location :>> ", location);
    const position = {
      coords: { latitude: location.lat, longitude: location.lng },
    };
    this.getFeedsNear(position);
  };

  getLocationError() {
    this.setState({
      isLocationError: true,
    });
  }

  loadFeed() {
    const { history, getFeedsNear, feeds, showFeedStyle } = this.props;
    const { dataNear, hasMoreNear, lat, long } = feeds;
    const limit = showFeedStyle === 1 ? 50 : 20;

    if (!hasMoreNear || !dataNear) return;
    const postData = {
      start: Object.keys(dataNear).length,
      limit,
      area: 100,
    };
    if (lat && long) {
      postData.lat = lat;
      postData.long = long;
      getFeedsNear(history, postData);
    }
  }

  onLikeClick = async (feed) => {
    const { dataNear } = this.props.feeds;
    const { setFeedsNears } = this.props;
    const act = JSON.parse(AuthService.getToken());
    const postData = { feed_node: feed.feed_node, sid: feed.sid };
    if (act) {
      postData.access_token = act.access_token;
    }
    try {
      const resp = await FeedService.feedClickLike(postData);
      if (resp.success) {
        const newFeed = { ...feed };
        if (Number(feed.liked)) {
          newFeed.liked = 0;
          newFeed.all_liked = Number(feed.all_liked) - 1;
        } else {
          newFeed.liked = 1;
          newFeed.all_liked = Number(feed.all_liked) + 1;
        }
        dataNear[feed.feed_node] = newFeed;
        setFeedsNears(dataNear);
      }
      console.log(resp);
    } catch (error) {
      console.log(error);
      message.error("เกิดข้อผิดพลาด");
    }
  };

  renderFeedStyleGrid = () => {
    const { itemWidth, onResize } = this.props;
    const { dataNear, hasMoreNear } = this.props.feeds;
    const dataArr = Object.values(dataNear).sort(
      (a, b) => b.feed_node - a.feed_node
    );
    const { isLocationError, province } = this.state;
    const provincez = province.map((d) => (
      <Option key={d.Id} values={(d.lat, d.lng)}>
        {d.name_th}
      </Option>
    ));
    return (
      <div>
        {dataArr.length === 0 ? (
          isLocationError ? (
            <Row gutter={[8, 16]}>
              <Col className="font-sub size-12 bold text-center">
                ไม่พบข่าวสารกรุณาให้สิทธิ์การเข้าถึงตำแหน่งของท่าน
              </Col>
              <Col className="text-center">
                <Select
                  style={{ width: "50%" }}
                  showSearch
                  placeholder="Select a persones"
                  onChange={this.onChangeProvince}
                  optionFilterProp="children"
                >
                  {provincez}
                </Select>
              </Col>
            </Row>
          ) : null
        ) : (
          <AutoSizer disableHeight onResize={onResize}>
            {({ width }) => {
              const rowCount = getRowsAmount(
                width,
                dataArr.length,
                hasMoreNear,
                itemWidth
              );
              return (
                <InfiniteLoader
                  ref={this.infiniteLoaderRef}
                  rowCount={rowCount}
                  isRowLoaded={({ index }) => {
                    const maxItemsPerRow = getMaxItemsAmountPerRow(
                      width,
                      itemWidth
                    );
                    const allItemsLoaded =
                      generateIndexesForRow(
                        index,
                        maxItemsPerRow,
                        dataArr.length
                      ).length > 0;
                    return !hasMoreNear || allItemsLoaded;
                  }}
                  loadMoreRows={this.loadFeed.bind(this)}
                >
                  {({ onRowsRendered, registerChild }) => (
                    <WindowScroller>
                      {({ height, scrollTop }) => {
                        const maxItemsPerRow = getMaxItemsAmountPerRow(
                          width,
                          itemWidth
                        );
                        return (
                          <List
                            autoHeight
                            ref={registerChild}
                            height={height + 300}
                            scrollTop={scrollTop}
                            width={width}
                            rowCount={rowCount}
                            rowHeight={this._cache_grid.rowHeight}
                            onRowsRendered={onRowsRendered}
                            rowRenderer={({ index, style, key, parent }) => {
                              const feeds = generateIndexesForRow(
                                index,
                                maxItemsPerRow,
                                dataArr.length
                              ).map((feedIndex) => dataArr[feedIndex]);
                              return (
                                <CellMeasurer
                                  cache={this._cache_grid}
                                  columnIndex={0}
                                  key={key}
                                  rowIndex={index}
                                  parent={parent}
                                >
                                  <Row
                                    style={style}
                                    key={key}
                                    type="flex"
                                    justify="center"
                                    className="mx-auto"
                                    gutter={[8, 8]}
                                  >
                                    {feeds.map((feed) => {
                                      return (
                                        <Col
                                          key={feed.feed_node}
                                          xs={12}
                                          sm={10}
                                          md={10}
                                          lg={10}
                                          xl={8}
                                          xxl={6}
                                        >
                                          <CheckTypeFeed feed={feed} />
                                        </Col>
                                      );
                                    })}
                                  </Row>
                                </CellMeasurer>
                              );
                            }}
                          />
                        );
                      }}
                    </WindowScroller>
                  )}
                </InfiniteLoader>
              );
            }}
          </AutoSizer>
        )}
        {hasMoreNear && !isLocationError ? <FeedSkeleton /> : null}
      </div>
    );
  };

  render() {
    return this.renderFeedStyleGrid();
  }
}
const mapStateToProps = (state) => ({
  feeds: state.feeds,
});

export default connect(mapStateToProps, { getFeedsNear, setFeedsNears })(
  withRouter(FeedNearGrid)
);
