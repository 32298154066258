import React from "react";
import { Row, Col, Skeleton } from "antd";

export default function FeedSkeleton() {
  return (
    <Row gutter={[16, 16]} type="flex" justify="center" className="pd-3">
      <Col xs={20} sm={20} md={18} lg={12} xl={8}>
        <Skeleton loading active avatar />
      </Col>
      <Col xs={20} sm={20} md={18} lg={12} xl={8}>
        <Skeleton loading active avatar />
      </Col>
      <Col xs={20} sm={20} md={18} lg={12} xl={8}>
        <Skeleton loading active avatar />
      </Col>
    </Row>
  );
}
