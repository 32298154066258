import React, { useEffect } from "react";
import { Tabs, Row, Icon } from "antd";
import { useLocation, useHistory } from "react-router-dom";
import NProgress from "nprogress";

import Main from "./Main";
import OrderHistoryList from "../components/order_v2/OrderHistoryList";
import { OrderHistoryContext } from "../context/OrderHistoryContext";

const { TabPane } = Tabs;
export default function OrderHistory() {
  const location = useLocation();
  const history = useHistory();
  const query = new URLSearchParams(location.search);
  const tabKey = query.get("tabs") || "order";
  const {
    ordersHistory: [, setOrdersHistory],
  } = React.useContext(OrderHistoryContext);
  useEffect(() => {
    setOrdersHistory([]);
    setTimeout(() => {
      NProgress.done(true);
    }, 500);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tabKey]);

  const onTabChange = (e) => {
    history.push({
      pathname: location.pathname,
      search: `tabs=${e}`,
    });
  };

  return (
    <Main title="ประวัติการสั่งซื้อสินค้า">
      <Tabs
        activeKey={tabKey}
        type="line"
        onTabClick={() => console.log("clikc")}
        onChange={onTabChange}
        className="tab-bg"
      >
        <TabPane
          tab={
            <span className="font-main size-12">
              {/* <Avatar shape="square" size={40} src="/ic_2.png" /> */}
              <Icon type="history" />

              <div className="line-12">ประวัติการสั่งซื้อ</div>
            </span>
          }
          key="order"
        >
          <Row
            type="flex"
            className="pl-2 pr-2 pt-3  pb-3 font-sub"
            justify="center"
          >
            {tabKey === "order" && <OrderHistoryList />}
          </Row>
        </TabPane>
        <TabPane
          tab={
            <span className="font-main size-12 text-center">
              {/* <Avatar shape="square" size={40} src="/ic_1.png" /> */}
              <Icon type="loading-3-quarters" />
              <div className="line-12">รอร้านค้ารับออเดอร์</div>
            </span>
          }
          key="pendingShopReceive"
        >
          <Row
            type="flex"
            className="pl-2 pr-2 pt-3  pb-3 font-sub"
            justify="center"
          >
            {tabKey === "pendingShopReceive" && (
              <OrderHistoryList orderStatus="1" />
            )}
          </Row>
        </TabPane>
        <TabPane
          tab={
            <span className="font-main size-12 text-center">
              {/* <Avatar shape="square" size={40} src="/ic_1.png" /> */}
              <Icon type="carry-out" />
              <div className="line-12">ร้านค้ารับออเดอร์</div>
            </span>
          }
          key="shopReceive"
        >
          <Row
            type="flex"
            className="pl-2 pr-2 pt-3  pb-3 font-sub"
            justify="center"
          >
            {tabKey === "shopReceive" && <OrderHistoryList orderStatus="2" />}
          </Row>
        </TabPane>
        <TabPane
          tab={
            <span className="font-main size-12 text-center">
              {/* <Avatar shape="square" size={40} src="/ic_1.png" /> */}
              <Icon type="check" />
              <div className="line-12">ประวัติการซื้อ</div>
            </span>
          }
          key="success"
        >
          <Row
            type="flex"
            className="pl-2 pr-2 pt-3  pb-3 font-sub"
            justify="center"
          ></Row>
        </TabPane>
      </Tabs>
    </Main>
  );
}
