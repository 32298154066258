import React from "react";
import { Modal, Icon, Button, Divider } from "antd";
import PriceItem from "./PriceItem";

export default function ConfirmOrder({
  visible,
  setVisible,
  onConfirm,
  total,
  price,
  count,
}) {
  return (
    <Modal
      width={400}
      centered
      visible={visible}
      closable={false}
      footer={null}
    >
      <span className="font-main size-15">
        <Icon type="question" className="c-sub mr-2 size-12" />
        ยันยันการสั่งซื้อสินค้า
      </span>
      <PriceItem
        total={total}
        price={price}
        count={count}
        layout={{ sm: 18, md: 16, span: 16 }}
      />
      <Divider />
      <div className="text-right ">
        <Button
          key="back"
          className="font-main size-12"
          onClick={() => {
            setVisible(false);
          }}
        >
          ยกเลิก
        </Button>
        ,
        <Button
          key="submit"
          className="font-main size-12"
          type="primary"
          onClick={() => {
            setVisible(false);
            onConfirm();
          }}
        >
          ยืนยัน
        </Button>
      </div>
    </Modal>
  );
}
