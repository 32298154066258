import React, { useEffect } from "react";
import { Layout, Breadcrumb, Row, Col } from "antd";

import LayoutFooter from "./LayoutFooter";
import LayoutSider from "./LayoutSider";
import LayoutHeader from "./LayoutHeader";
import "../../styles/menu.css";
const { Content } = Layout;

const BaseLayout = (props) => {
  const { children, title } = props;
  useEffect(() => {
    document.title = `gebtam ${title || "member"}`;
  }, [title]);
  return (
    <div>
      <Layout style={{ minHeight: "100vh" }}>
        <div className="leftMenu b-main">
          <LayoutSider />
        </div>
        <Layout>
          <LayoutHeader />
          <Content style={{ margin: "16px 16px" }} className="container">
            <Row type="flex" justify="center">
              <Col xs={24} sm={24} md={20} lg={18} xl={18} span={24}>
                <Breadcrumb></Breadcrumb>
              </Col>
              <Col xs={24} sm={24} md={20} lg={18} xl={18} span={24}>
                <div style={{ background: "rgb(234,234,234)", minHeight: 360 }}>
                  {children}
                </div>
              </Col>
            </Row>
          </Content>
          <LayoutFooter />
        </Layout>
      </Layout>
    </div>
  );
};

export default BaseLayout;
