import React, { Component } from "react";
import { connect } from "react-redux";
import {
  Spin,
  Card,
  Avatar,
  Row,
  Col,
  Upload,
  Icon,
  Form,
  Input,
  Button,
  message,
  Radio,
  DatePicker,
  Switch,
  Tooltip,
  Skeleton,
  Divider,
  Select,
} from "antd";
import moment from "moment";

import { getProfile, saveProfile } from "../../store/actions/user";
import uploadService from "../../services/UploadService";
import { BASE_URL } from "../../config";
import { setFormatDate } from "../../helpers/util";
import AuthService from "../../services/AuthService";

const { Option } = Select;
class Profile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      profile: {},
      imageUrl: "",
      loading: false,
      organis: [],
      facuries: [],
      persones: [],
      member: {
        rid: "",
        pid: "",
        fid: "",
      },
    };
  }

  componentWillMount() {
    this.props.getProfile(this.props.history).finally(() => {
      this.setState({
        isLoading: false,
        profile: this.props.user.profile,
        organis: this.props.organis,
        member: {
          rid: this.props.user.profile.rid,
          pid: this.props.user.profile.pid,
          fid: this.props.user.profile.fid,
        },
      });
    });
    this.getCategory(this.props.user.profile.rid || 1);
  }

  getCategory = async (rid) => {
    await AuthService.getCategory({ rid: rid }).then((user) => {
      this.setState({
        facuries: user.facuries,
        persones: user.persones,
      });
    });
  };

  onChangeRid = (rid) => {
    this.props.form.setFieldsValue({
      rid: rid,
      pid: "",
      fid: "",
    });
    this.setState({
      member: {
        rid: rid,
      },
    });
    this.getCategory(rid);
  };

  onChangePid = (pid) => {
    this.setState({
      member: {
        ...this.state.member,
        pid: pid,
      },
    });
  };

  onChangeFid = (fid) => {
    this.setState({
      member: {
        ...this.state.member,
        fid: fid,
      },
    });
  };

  handleChange = (info) => {
    const isLt1M = info.file.size / 1024 / 1024 < 1;
    if (!isLt1M) return;

    uploadService.getBase64(info.file, (imageUrl) => {
      this.setState({
        imageUrl,
        loading: false,
      });
    });
  };

  handleReset = () => {
    this.props.form.resetFields();
  };

  handleSubmit = (e) => {
    e.preventDefault();
    this.props.form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        this.setState({ loading: true });
        const newValues = { ...this.props.user.profile, ...values };
        if (this.state.imageUrl !== "") {
          newValues.m_image = this.state.imageUrl;
        }
        console.log("values :>> ", values);
        newValues.rid = values.rid;
        newValues.pid = values.pid;
        newValues.fid = values.fid;
        newValues.m_birth_date = setFormatDate(newValues.m_birth_date);
        newValues.notify_status = newValues.notify_status ? "1" : "0";
        console.log("this.state.member SAVE :>> ", this.state.member);
        //!วิธีที่ไม่ควรทำ เดี๋ยวค่อยแก้
        this.props
          .saveProfile(newValues, this.props.history)
          .then(() => {
            this.setState({ loading: false, profile: this.props.user.profile });
            message.success("บันทึกข้อมูลสำเร็จ");
          })
          .catch((error) => {
            console.log(error);
            this.props.form.resetFields();
            this.setState({ loading: false, imageUrl: "" });
            if (error.desc) {
              message.error(error.desc);
            } else {
              message.error("เปลี่ยนแปลงข้อมูลไม่สำเร็จ");
            }
          });
      }
    });
  };

  render() {
    const { organis, facuries, persones, member } = this.state;
    const organiz = organis.map((d) => (
      <Option key={d.rid} value={d.rid}>
        {d.name}
      </Option>
    ));
    const facuriez = facuries.map((d) => (
      <Option key={d.fid} value={d.fid}>
        {d.name}
      </Option>
    ));
    const personez = persones.map((d) => (
      <Option key={d.pid} value={d.pid}>
        {d.name}
      </Option>
    ));
    // const { user } = this.props;
    const { imageUrl, profile } = this.state;
    const { getFieldDecorator } = this.props.form;
    const notify_status = profile.notify_status === "0" ? false : true;
    if (this.state && this.state.isLoading === false) {
      return (
        <Col xs={24} sm={24} md={18} lg={12} xl={12}>
          <Spin
            tip="Loading..."
            spinning={this.state.loading}
            className="center"
          >
            <Card
              title={
                <div className="size-15 font-main">
                  <Icon
                    type="idcard"
                    className="mr-2 "
                    style={{ verticalAlign: -7 }}
                  />
                  ข้อมูลส่วนตัว
                </div>
              }
              bordered={true}
              className="mx-auto  font-sub bg-234 shadow"
            >
              <div className="text-center">
                <Upload
                  accept="image/*"
                  name="avatar"
                  listType="picture"
                  className="pr-0 mx-auto"
                  showUploadList={false}
                  onChange={this.handleChange}
                  beforeUpload={uploadService.beforeUpload}
                  multiple={false}
                >
                  <Tooltip
                    placement="bottom"
                    title="อัพโหลดรูปภาพ"
                    className="font-sub"
                  >
                    {imageUrl ? (
                      <Avatar className="pointer" size={150} src={imageUrl} />
                    ) : profile.m_pic ? (
                      <Avatar
                        size={150}
                        src={`${BASE_URL}/${profile.m_pic_path}${profile.m_pic}`}
                        className="pointer"
                      />
                    ) : (
                      <Avatar size={150} icon="user" className="pointer" />
                    )}
                  </Tooltip>
                </Upload>
              </div>
              <Divider className="bg-gray" />
              <Form onSubmit={this.handleSubmit}>
                <div className="profile-form font-sub">
                  <Form.Item label="โทรศัพท์">
                    <Row gutter={8}>
                      <Col span={24}>
                        {getFieldDecorator("m_tel_number", {
                          initialValue: profile.m_tel_number,
                          rules: [
                            {
                              required: true,
                              message: "ระบุหมายเลขโทรศัพท์",
                            },
                            {
                              len: 10,
                              message: "หมายเลขโทรศัพท์ไม่ถูกต้อง",
                            },
                          ],
                        })(
                          <Input
                            prefix={
                              <Icon
                                type="phone"
                                style={{ color: "rgba(0,0,0,.25)" }}
                              />
                            }
                            placeholder="Phone"
                          />
                        )}
                      </Col>
                    </Row>
                  </Form.Item>
                  <Form.Item label="อีเมล">
                    {getFieldDecorator("m_mail", {
                      initialValue: profile.m_mail,
                      rules: [
                        {
                          type: "email",
                          message: "กรอกอีเมลให้ถูกต้อง",
                        },
                        {
                          required: false,
                          message: "Please input your E-mail!",
                        },
                      ],
                    })(
                      <Input
                        prefix={
                          <Icon
                            type="mail"
                            style={{ color: "rgba(0,0,0,.25)" }}
                          />
                        }
                      />
                    )}
                  </Form.Item>
                  <Form.Item label="ชื่อ">
                    {getFieldDecorator("m_name", {
                      initialValue: profile.m_name,
                      rules: [
                        {
                          required: true,
                          message: "กรอกชื่อ",
                        },
                      ],
                    })(<Input placeholder="ชื่อ" />)}
                  </Form.Item>
                  <Form.Item label="นามสกุล">
                    {getFieldDecorator("m_lastname", {
                      initialValue: profile.m_lastname,
                      rules: [
                        {
                          required: true,
                          message: "กรอกนามสกุล",
                        },
                      ],
                    })(<Input placeholder="นามสกุล" />)}
                  </Form.Item>

                  <Form.Item label="องค์กร">
                    {getFieldDecorator("rid", {
                      initialValue: member.rid,
                    })(
                      <Select
                        showSearch
                        placeholder="Select a organis"
                        onChange={this.onChangeRid}
                        optionFilterProp="children"
                      >
                        {organiz}
                      </Select>
                    )}
                  </Form.Item>
                  {member.rid === "1" ? (
                    <>
                      {" "}
                      <Form.Item label="สถานะภาพ">
                        {getFieldDecorator("pid", {
                          initialValue: member.pid,
                        })(
                          <Select
                            showSearch
                            placeholder="Select a persones"
                            onChange={this.onChangePid}
                            optionFilterProp="children"
                          >
                            {personez}
                          </Select>
                        )}
                      </Form.Item>
                      {member.pid === "1" || member.pid === "2" ? (
                        <>
                          {" "}
                          <Form.Item label="คณะ">
                            {getFieldDecorator("fid", {
                              initialValue: member.fid,
                            })(
                              <Select
                                showSearch
                                placeholder="Select a facuries"
                                onChange={this.onChangeFid}
                                optionFilterProp="children"
                              >
                                {facuriez}
                              </Select>
                            )}
                          </Form.Item>
                        </>
                      ) : (
                        <></>
                      )}{" "}
                    </>
                  ) : member.rid === "2" ? (
                    <>
                      {" "}
                      <Form.Item label="  สถานะภาพ">
                        {getFieldDecorator("pid", {
                          initialValue: member.pid,
                        })(
                          <Select
                            showSearch
                            placeholder="Select a persones"
                            onChange={this.onChangePid}
                            optionFilterProp="children"
                          >
                            {personez}
                          </Select>
                        )}
                      </Form.Item>
                    </>
                  ) : (
                    <></>
                  )}
                  <Form.Item label="เพศ">
                    {getFieldDecorator("m_gender", {
                      initialValue: Number(profile.m_gender),
                    })(
                      <Radio.Group>
                        <Radio value={0}>ชาย</Radio>
                        <Radio value={1}>หญิง</Radio>
                        <Radio value={2}>ไม่ระบุ</Radio>
                      </Radio.Group>
                    )}
                  </Form.Item>
                  <Form.Item label="วันเกิด">
                    {getFieldDecorator("m_birth_date", {
                      initialValue: moment(
                        profile.m_birth_date || new Date(),
                        "YYYY-MM-DD"
                      ),
                      rules: [
                        {
                          type: "object",
                          required: true,
                          message: "ระบุวันเกิด",
                        },
                      ],
                    })(
                      <DatePicker
                        disabled
                        format="DD-MM-YYYY"
                        placeholder="วันเกิด"
                      />
                    )}
                  </Form.Item>
                  <Form.Item label="การแจ้งเตือน">
                    {getFieldDecorator("notify_status", {
                      valuePropName: "checked",
                      initialValue: notify_status,
                    })(<Switch />)}
                  </Form.Item>
                  <Row type="flex" justify="space-between">
                    <Button
                      className="font-main size-15 line-h-12"
                      onClick={this.handleReset}
                    >
                      ยกเลิก
                    </Button>
                    <Button
                      type="primary"
                      htmlType="submit"
                      loading={this.state.loading}
                      className="font-main size-15 line-h-12"
                    >
                      บันทึก
                    </Button>
                  </Row>
                </div>
              </Form>
            </Card>
          </Spin>
        </Col>
      );
    } else {
      return <Skeleton loading={true} active avatar></Skeleton>;
    }
  }
}
const mapStateToProps = (state) => ({
  user: state.user,
  organis: state.organis,
});

const ProfileForm = Form.create({ name: "register" })(Profile);

export default connect(mapStateToProps, { getProfile, saveProfile })(
  ProfileForm
);
