import React from "react";
import { Radio, Avatar } from "antd";
import OrderNextStepBtn from "./OrderNextStepBtn";

export default function ChoosePayment({ nextStep }) {
  return (
    <>
      <div className="text-center mt-3">
        <span className="size-15 font-main c-gray">เลือกวิธีชำระเงิน</span>
      </div>
      <Radio.Group
        className="w-100 size-11 bold"
        style={{ paddingBottom: "4rem" }}
        onChange={() => console.log("d")}
        value={1}
      >
        <div className=" b-w pd-2">
          <Radio value={1}>QE Payment / ชำระผ่าน QR Code</Radio>
          <div className="pl-3">
            <Avatar src="/icon-how-to-qr.png" />
          </div>
        </div>
        {/* <div className=" mt-2 bg-w pd-2">
          <Radio value={2} disabled>
            บัตรเครดิต / เดบิต
            <br />
            <Avatar src="/visa.png" className="ml-3" />
          </Radio>
        </div>
        <div className=" mt-2 bg-w pd-2">
          <Radio value={3} disabled>
            โอนเงินเข้าบัญชีธนาคาร หรือพร้อมเพย์
            <br />
            <Avatar src="/scb.png" className="ml-3" />
            <Avatar src="/ktb.png" className="ml-3" />
            <Avatar src="/ibanking.png" className="ml-3" />
          </Radio>
        </div> */}
      </Radio.Group>
      <OrderNextStepBtn onClick={() => nextStep(1)} isBack />
      <OrderNextStepBtn onClick={() => nextStep(3)} />
    </>
  );
}
