import http from "./HttpService";
import { APP_URL } from "../config";

const FeedService = {
  async getAllFeeds(data) {
    const url = `${APP_URL}allShopsFeeds`;
    return await http.requestPost(url, data);
  },
  async getFeedsNear(data) {
    const url = `${APP_URL}get_feed_neer`;
    return await http.requestPost(url, data);
  },
  async getAllFeedsFollowed(data) {
    const url = `${APP_URL}allShopsFeedsFollowed`;
    return await http.requestPost(url, data);
  },
  async getFeed(data) {
    const url = `${APP_URL}loadFeedAns`;
    return await http.requestPost(url, data);
  },
  async feedClickLike(data) {
    const url = `${APP_URL}feedClickLike`;
    return await http.requestPost(url, data);
  },
  async feedComment(data) {
    const url = `${APP_URL}feedAns`;
    return await http.requestPost(url, data);
  },
  async getFeedShared(data) {
    const url = `${APP_URL}loadFeedAnsShared`;
    return await http.requestPost(url, data);
  },
};
export default FeedService;
