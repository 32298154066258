import React from "react";
import { Tag } from "antd";
import PendingPayment from "./order-status/PendingPayment";
import PendingShopReceive from "./order-status/PendingShopReceive";
import ShopReceive from "./order-status/ShopReceive";

export default function CheckOrderStatus({
  orderId,
  orderStatus,
  contact,
  onCancelOrder,
}) {
  if (orderStatus === "-1") {
    return (
      <Tag color="red" className="font-sub pointer">
        ถูกยกเลิก
      </Tag>
    );
  } else if (orderStatus === "0") {
    return (
      <PendingPayment
        contact={contact}
        orderId={orderId}
        onCancelOrder={onCancelOrder}
      />
    );
  } else if (orderStatus === "1") {
    return <PendingShopReceive contact={contact} orderId={orderId} />;
  } else if (orderStatus === "2") {
    return <ShopReceive contact={contact} />;
  } else if (orderStatus === "3") {
    return (
      <Tag color="#40a832" className="font-sub pointer">
        ทำรายการสำเร็จ
      </Tag>
    );
  } else {
    return <></>;
  }
}
