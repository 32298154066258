import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { HashRouter } from "react-router-dom";
import { ConfigProvider, message } from "antd";
import thTH from "antd/lib/locale-provider/th_TH";
import moment from "moment";
import "moment/locale/th";

import configureStore from "./store/index";
import "./index.css";
import "./styles/sm.css";
import "./styles/customAnt.css";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import { initialState } from "./store/reducers/index";

message.config({
  duration: 5,
});
moment.locale("th");

const store = configureStore(initialState);

ReactDOM.render(
  <Provider store={store}>
    <HashRouter>
      <ConfigProvider locale={thTH}>
        <App />
      </ConfigProvider>
    </HashRouter>
  </Provider>,
  document.querySelector("#root")
);
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
