import React, { Component } from "react";
import { connect } from "react-redux";
import {
  Form,
  Icon,
  Input,
  Button,
  Row,
  Col,
  Avatar,
  message,
  Tooltip,
  Spin,
  Tag,
  Modal,
  Result,
} from "antd";
import {
  getCheckShopDetail,
  getCheckInShop,
  getCheckOutShop,
} from "../store/actions/shop";
import ShopService from "../services/ShopService";

import PoweredByGebtam from "../components/gebtam/PoweredByGebtam";

import { setFeedDate } from "../helpers/time";
import { BASE_URL } from "../config";

function hasErrors(fieldsError) {
  return Object.keys(fieldsError).some((field) => fieldsError[field]);
}

class Application extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      shopImg: "/gebtam.png",
    };
  }

  componentWillReceiveProps(nextProps) {
    const { location } = this.props;
    const { location: nextLocation } = nextProps;
    if (nextLocation.search !== location.search) {
      window.location.reload();
    }
  }

  componentWillMount() {}

  // getOsGebtamManagerIOS() {
  //   window.open(
  //     "https://apps.apple.com/us/app/gebtammanager/id1469896800?l=th&ls=1"
  //   );
  // }

  // getOsGebtamManagerAndroid() {
  //   window.open(
  //     "https://play.google.com/store/apps/details?id=com.gebtammanager"
  //   );
  // }

  handleOpenApp() {
    const param = this.props.history.location.search.split("?")[1];
    // console.log("param :>> ", param);
    if (param) window.open(`gebtam://app/?${param}`);
    else window.open(`gebtam://app/`);
  }

  render() {
    const { shopImg } = this.state;
    return (
      <div
        className="login-page font-sub"
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
      >
        <Form className="app-form">
          <Spin spinning={this.state.isLoading}>
            <div type="flex" justify="center" className="text-center mb-3">
              <Tooltip placement="top">
                <Avatar
                  shape="square"
                  size={100}
                  style={{ backgroundColor: "white", borderRadius: "20px" }}
                  src={shopImg}
                />
              </Tooltip>
            </div>
            <p className="font-main size-19 text-center c-w mg-1">
              ดาวน์โหลดแอปพลิเคชั่น
            </p>
            <div align="center" style={{ marginBottom: "0.3rem" }}>
              {" "}
              <a
                href={
                  "https://play.google.com/store/apps/details?id=com.gebtam"
                }
                // eslint-disable-next-line react/jsx-no-target-blank
                target="_blank"
              >
                <img alt="playstore" height="60" src="/GooglePlay.png" />
              </a>
            </div>{" "}
            <div align="center" style={{ marginBottom: "1.5rem" }}>
              {" "}
              <a
                href={
                  "https://apps.apple.com/us/app/gebtam/id1451290526?l=th&ls=1"
                }
                // eslint-disable-next-line react/jsx-no-target-blank
                target="_blank"
              >
                <img alt="appstore" height="60" src="/AppleStore.png" />
              </a>
            </div>
            <p className="font-main size-19 text-center c-w mg-1">
              มีแอปพลิเคชั่นอยู่แล้ว
            </p>
            <Button
              block
              shape="round"
              className="font-main size-19 bg-sub no-border"
              style={{ height: "3rem", borderRadius: "0.6rem" }}
              onClick={() => {
                this.handleOpenApp();
              }}
            >
              เปิดแอปพลิเคชั่น
            </Button>
          </Spin>
        </Form>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  shops: state.shops,
});
const ApplicationForm = Form.create({ name: "checkin" })(Application);

export default connect(mapStateToProps, {})(ApplicationForm);
