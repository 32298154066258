import "moment/locale/th";

import {
  Avatar,
  Button,
  Col,
  DatePicker,
  Form,
  Icon,
  Input,
  Radio,
  Row,
  Switch,
  Tooltip,
  message,
} from "antd";
import React, { Component } from "react";

import AuthService from "../services/AuthService";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import md5 from "react-native-md5";
import md5V2 from "md5";
import moment from "moment";
import { registerUser } from "../store/actions/auth";
import { setFormatDate } from "../helpers/util";
import thTH from "antd/lib/locale-provider/th_TH";

moment.locale("th");

class Register extends Component {
  constructor(props) {
    super(props);
    this.state = {
      errors: {},
      isLoading: false,
      isOtpLoading: false,
      confirmDirty: false,
      autoCompleteResult: [],
      code: "",
      member: {
        m_tel_number: "",
        m_password: "",
        m_gender: 3,
        m_name: "",
        m_nickname: "",
        m_lastname: "",
        m_mail: "",
        m_birth_date: new Date(),
        code: "",
        notify_status: true,
      },
    };
  }

  componentDidMount() {
    const param = new URLSearchParams(this.props.history.location.search);
    if (param.get("tel")) {
      const tel = param.get("tel");
      this.setState({ member: { ...this.state.member, m_tel_number: tel } });
    }
  }

  componentWillReceiveProps(nextProps) {
    if (
      nextProps.errors.registerError &&
      nextProps.errors.isRegister !== this.props.errors.isRegister
    ) {
      this.setState({
        isLoading: false,
      });
      message.warning(nextProps.errors.registerError);
    }
  }

  compareToFirstPassword = (rule, value, callback) => {
    const { form } = this.props;
    if (value && value !== form.getFieldValue("m_password")) {
      callback("รหัสผ่านไม่ตรงกัน");
    } else {
      callback();
    }
  };

  validateToNextPassword = (rule, value, callback) => {
    const { form } = this.props;
    if (value && this.state.confirmDirty) {
      form.validateFields(["confirm"], { force: true });
    }
    callback();
  };

  handleSubmit = (e) => {
    e.preventDefault();
    this.props.form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        this.setState({ isLoading: true });
        const newValues = { ...values };
        newValues.m_birth_date = setFormatDate(newValues.m_birth_date);
        newValues.notify_status = newValues.notify_status ? 1 : 0;
        newValues.m_password = md5V2(
          // eslint-disable-next-line no-control-regex
          `${md5.str_md5(values.m_password).split(/\u0000/)[0]}AppDD2018`
        );
        newValues.fromWeb = true;
        this.props.registerUser(newValues, this.props.history);
      }
    });
  };

  handleSendOtp = () => {
    const m_tel_number = this.props.form.getFieldValue("m_tel_number");
    const telError = this.props.form.getFieldError("m_tel_number");
    if (!telError && !m_tel_number) {
      return message.error("กรุณาระบุหมายเลขโทรศัพท์");
    } else if (telError) {
      return message.error("กรุณาระบุหมายเลขโทรศัพท์ให้ถูกต้อง");
    }
    const postData = { to: m_tel_number, user: "test" };
    this.setState({ isOtpLoading: true });
    AuthService.sendVerifyOtp(postData)
      .then((resp) => {
        const data = resp;
        if (data.success) {
          message.success("ส่ง OTP สำเร็จ");
        } else {
          message.warning("ไม่สามารถส่ง sms ได้");
        }
        this.setState({ isOtpLoading: false });
      })
      .catch((error) => {
        this.setState({ isOtpLoading: false });
        console.log(error);
        message.error("เกิดข้อผิดพลาด");
      });
  };

  render() {
    const { getFieldDecorator } = this.props.form;
    const formItemLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 8 },
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 16 },
      },
    };
    const { member } = this.state;

    const param = new URLSearchParams(this.props.history.location.search);
    const path = param.get("path") ? param.get("path") : "/";
    const login = path !== "/" ? `/login?path=${path}` : "/login";
    return (
      <div className="login-page">
        <Form
          {...formItemLayout}
          onSubmit={this.handleSubmit}
          className="regis-form font-sub"
        >
          <div type="flex" justify="center" className="text-center mb-3">
            <div className="text-right">
              <Link to={login}>
                <span className="font-main size-12 c-main">
                  เป็นสมาชิกอยู่แล้วให้กดที่นี่
                </span>
              </Link>
            </div>
            <Link to="/">
              <Tooltip
                placement="bottom"
                title={<span className="font-sub">หน้าแรก</span>}
              >
                <Avatar shape="square" size={64} src="/gebtam.png" />
              </Tooltip>
            </Link>
            <div className="text-center">
              <span className="font-main size-15 c-main">
                สมัครสมาชิกเก็บแต้ม
              </span>
            </div>
          </div>
          <Form.Item label="โทรศัพท์">
            <Row gutter={8}>
              <Col span={16}>
                {getFieldDecorator("m_tel_number", {
                  initialValue: this.state.member.m_tel_number,
                  rules: [
                    { required: true, message: "ระบุหมายเลขโทรศัพท์" },
                    { len: 10, message: "หมายเลขโทรศัพท์ไม่ถูกต้อง" },
                  ],
                })(
                  <Input
                    prefix={
                      <Icon type="phone" style={{ color: "rgba(0,0,0,.25)" }} />
                    }
                    placeholder="Phone"
                  />
                )}
              </Col>
              <Col span={8}>
                <Button
                  type="primary"
                  shape="round"
                  loading={this.state.isOtpLoading}
                  onClick={this.handleSendOtp}
                >
                  ส่ง otp
                </Button>
              </Col>
            </Row>
          </Form.Item>

          <Form.Item label="OTP">
            {getFieldDecorator("code", {
              rules: [
                {
                  required: true,
                  message: "กรอก OTP",
                },
              ],
            })(<Input />)}
          </Form.Item>

          <Form.Item label="อีเมล">
            {getFieldDecorator("m_mail", {
              rules: [
                {
                  type: "email",
                  message: "กรอกอีเมลให้ถูกต้อง",
                },
                {
                  required: false,
                  message: "Please input your E-mail!",
                },
              ],
            })(<Input />)}
          </Form.Item>
          <Form.Item label="ชื่อ">
            {getFieldDecorator("m_name", {
              rules: [
                {
                  required: true,
                  message: "กรอกชื่อ",
                },
              ],
            })(<Input placeholder="ชื่อ" />)}
          </Form.Item>
          <Form.Item label="นามสกุล">
            {getFieldDecorator("m_lastname", {
              rules: [
                {
                  required: true,
                  message: "กรอกนามสกุล",
                },
              ],
            })(<Input placeholder="นามสกุล" />)}
          </Form.Item>
          <Form.Item label="เพศ">
            {getFieldDecorator("m_gender", {
              initialValue: member.m_gender,
            })(
              <Radio.Group>
                <Radio value={1}>ชาย</Radio>
                <Radio value={2}>หญิง</Radio>
                <Radio value={3}>ไม่ระบุ</Radio>
              </Radio.Group>
            )}
          </Form.Item>
          <Form.Item label="วันเกิด">
            {getFieldDecorator("m_birth_date", {
              rules: [
                { type: "object", required: true, message: "ระบุวันเกิด" },
              ],
            })(
              <DatePicker
                format="DD-MM-YYYY"
                locale={thTH}
                placeholder="เลือกวันเกิด"
              />
            )}
          </Form.Item>
          <Form.Item label="การแจ้งเตือน">
            {getFieldDecorator("notify_status", {
              valuePropName: "checked",
              initialValue: member.notify_status,
            })(<Switch />)}
          </Form.Item>
          <Form.Item label="รหัสผ่าน" hasFeedback>
            {getFieldDecorator("m_password", {
              rules: [
                {
                  required: true,
                  message: "กรอกรหัสผ่าน",
                },
                {
                  validator: this.validateToNextPassword,
                },
                {
                  min: 8,
                  message: "รหัสผ่านอย่างน้อย 8 ตัวอักษร",
                },
              ],
            })(<Input.Password />)}
          </Form.Item>
          <Form.Item label="ยันยันรหัสผ่าน" hasFeedback>
            {getFieldDecorator("confirm", {
              rules: [
                {
                  required: true,
                  message: "ยืนยันรหัสผ่าน",
                },
                {
                  validator: this.compareToFirstPassword,
                },
                {
                  min: 8,
                  message: "รหัสผ่านอย่างน้อย 8 ตัวอักษร",
                },
              ],
            })(<Input.Password onBlur={this.handleConfirmBlur} />)}
          </Form.Item>
          <Row>
            <Col span={24}>
              <Button
                type="primary"
                htmlType="submit"
                block
                shape="round"
                className="font-main size-15"
                loading={this.state.isLoading}
              >
                สมัครสมาชิก
              </Button>
            </Col>
          </Row>
          <Row className="text-center mt-2">
            <Link to={login} className="font-main text-center size-12 ">
              เข้าสู่ระบบ
            </Link>
          </Row>
        </Form>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  errors: state.errors,
});
const RegisterForm = Form.create({ name: "register" })(Register);

export default connect(mapStateToProps, { registerUser })(RegisterForm);
