import React, { useState } from "react";
import ContactShop from "../ContactShop";
import { Tooltip, Tag, Spin, Popconfirm, message } from "antd";
import { OrderHistoryContext } from "../../../context/OrderHistoryContext";
import OrderService from "../../../services/OrderService";
import ScanQrcodePayment from "../order-card/ScanQrcodePayment";
import { useParams, useHistory } from "react-router-dom";
import { AppPath } from "../../../router/path";

export default function PendingPayment({ contact, orderId }) {
  const [isLoading, setLoading] = useState(false);
  const history = useHistory();
  const [popVisible, setPopVisible] = useState(false);
  const [scanOrcodeVisible, setScanQrcodeVisible] = useState(false);
  const [orderDetail, setOrderDetail] = useState({});
  const {
    ordersHistory: [ordersHistory, setOrdersHistory],
  } = React.useContext(OrderHistoryContext);

  const onCancelOrder = async () => {
    setPopVisible(false);
    setLoading(true);
    try {
      const resp = await OrderService.cancelOrder(orderId);
      console.log(resp);
      if (resp.success) {
        const newOrderHistory = [...ordersHistory];
        const order = newOrderHistory.find(
          (order) => order.order_id === orderId
        );
        order.status = "-1";
        message.success("ยกเลิกออเดอร์เรียบร้อย");
        setOrdersHistory(newOrderHistory);
      } else {
        message.error("ไม่สามารถยกเลิกออเดอร์ได้");
      }
    } catch (error) {
      message.error("เกิดข้อผิดพลาด");
    }
    setLoading(false);
  };

  const onPaymentClick = async () => {
    setLoading(true);
    try {
      const resp = await OrderService.getOrderDetail(orderId);
      console.log(resp);
      if (resp.success) {
        setOrderDetail(resp.result);
        setScanQrcodeVisible(true);
      } else {
        message.error("ไม่สามารถยกเลิกออเดอร์ได้");
      }
    } catch (error) {
      message.error("เกิดข้อผิดพลาด");
    }
    setLoading(false);
  };

  const onPaymentNextTime = () => {
    setScanQrcodeVisible(false);
  };

  return (
    <Spin spinning={isLoading}>
      {scanOrcodeVisible && (
        <ScanQrcodePayment
          visible={scanOrcodeVisible}
          onNextTime={onPaymentNextTime}
          orderId={orderId}
          qrcode={orderDetail.qr_code}
          ref1={orderDetail.ref1}
          // tabs={"pendingShopReceive"}
        />
      )}
      <ContactShop contact={contact} className="mr-2" />
      <div className="mt-2">
        <Popconfirm
          title="ต้องการยกเลิกออเดอร์ ?"
          visible={popVisible}
          // onVisibleChange={this.handleVisibleChange}
          onConfirm={onCancelOrder}
          onCancel={() => setPopVisible(false)}
          okType="danger"
          okText="ยืนยัน"
          cancelText="ปิด"
        >
          <Tag
            color="red"
            className="font-sub pointer"
            onClick={() => setPopVisible(true)}
          >
            ยกเลิก
          </Tag>
        </Popconfirm>
        <Tooltip
          placement="top"
          title={<span className="font-sub">ชำระเงิน</span>}
        >
          <Tag
            color="#f8d00f"
            className="font-sub pointer"
            onClick={onPaymentClick}
          >
            ชำระเงิน
          </Tag>
        </Tooltip>
      </div>
    </Spin>
  );
}
