import React, { Component } from "react";
import { Skeleton, Avatar, Row, Col, Icon } from "antd";
import { withRouter } from "react-router-dom";

import ShopService from "../../services/ShopService";

class ShopDetail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      shop: {},
      isLoading: true,
    };
  }

  gotoMap(latLong) {
    window.open(`https://www.google.com/maps/search/?api=1&query=${latLong}`);
  }

  render() {
    const { dataDetail, pending } = this.props.shops;
    const sid = this.props.match.params.id;
    const detail = dataDetail[sid];
    if (!pending && detail) {
      return (
        <Row type="flex" className="mt-3">
          <Col xs={24} sm={24} md={10} lg={8} xl={6} className="text-center">
            <Avatar
              size={128}
              src={ShopService.getShopLogo(detail.head_id, detail.head_pic)}
              className=" mx-auto"
            />
          </Col>
          <Col
            xs={24}
            sm={24}
            md={14}
            lg={16}
            xl={18}
            className="font-sub  size-10 d-flex"
          >
            <div className="mt-auto mb-auto bold pl-3">
              <div>
                <span>{detail.shop_name}</span>
              </div>
              <div>
                <span>{detail.shop_details}</span>
              </div>
            </div>
          </Col>
          {detail.branchs.map((branch) => {
            return (
              <Col
                span={24}
                className="b-w mt-2 pt-3 pl-3 pb-3 font-sub pr-6"
                key={branch.sid}
              >
                <div>
                  <span className="bold  size-12">{branch.shop_name}</span>
                </div>
                <div>{branch.shop_details}</div>
                <div>โทร. {branch.shop_owner_tel}</div>
                <div
                  className="ab"
                  style={{
                    right: "10px",
                    top: "50%",
                    transform: "translate(0,-50%)",
                  }}
                >
                  <Icon
                    type="environment"
                    className="size-15"
                    title="ไปยังแผนที่"
                    onClick={() => this.gotoMap(branch.shop_lat_long)}
                  />
                </div>
              </Col>
            );
          })}
        </Row>
      );
    } else {
      return (
        <Row type="flex" justify="center">
          <Col xs={22} sm={22} md={18} lg={16} className="mt-3 mb-3">
            <Skeleton loading active avatar />
          </Col>
        </Row>
      );
    }
  }
}

export default withRouter(ShopDetail);
