import React from 'react';
import Linkify from 'react-linkify';

import FeedCardHeader from './FeedCardHeader';
import CheckTypeFeed from '../CheckTypeFeed';
import FeedCardBuy from './FeedCardBuy';
import FeedCardAction from './FeedCardAction';
import ShopService from '../../../services/ShopService';
import ComponentDecorator from '../ComponentDecorator';

export default function FeedCardView({ feed, onLikeClick, onCommentClick }) {
  const pathShopLogo = ShopService.getLogoHasPath(
    feed.path_shop_logo,
    feed.head_pic
  );
  const likeClick = () => {
    onLikeClick(feed);
  };
  return (
    <div className="bg-w">
      <FeedCardHeader
        isNotResize
        pathLogo={pathShopLogo}
        shopName={feed.shop_name}
        shopId={feed.sid}
        time={feed.feed_create_time}
      />
      <div className=" pd-2 size-09 sm-size-08 custom-link">
        <Linkify componentDecorator={ComponentDecorator}>
          {feed.feed_text}
        </Linkify>
      </div>
      <CheckTypeFeed feed={feed} isNotResize />
      {feed.price && (
        <FeedCardBuy
          price={feed.price}
          stock={feed.stock}
          feedNode={feed.feed_node}
          contact={feed.contact}
        />
      )}
      <FeedCardAction
        isLiked={Number(feed.liked)}
        countComment={feed.all_comment}
        countLike={feed.all_liked}
        onLikeClick={likeClick}
        onCommentClick={onCommentClick}
        feedId={feed.feed_node}
      />
    </div>
  );
}
