import React, { Component } from "react";
import { connect } from "react-redux";
import {
  InfiniteLoader,
  List,
  WindowScroller,
  AutoSizer,
  CellMeasurer,
  CellMeasurerCache,
} from "react-virtualized";
import { Row, Col, message } from "antd";
import { withRouter } from "react-router-dom";

import AuthService from "../../services/AuthService";
import { getAllFeeds, setFeedsAlls } from "../../store/actions/feed";
import FeedSkeleton from "../feed/FeedSkeleton";
import CheckTypeFeed from "../feed/CheckTypeFeed";
import {
  getRowsAmount,
  getMaxItemsAmountPerRow,
  generateIndexesForRow,
} from "../../helpers/util";
import FeedService from "../../services/FeedService";

class FeedAll extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLocationError: false,
    };
    this._cache_grid = new CellMeasurerCache({
      fixedWidth: true,
      minHeight: 100,
    });
  }
  componentWillMount() {
    const { user, history } = this.props;
    if (!user.isAuthenticated) {
      AuthService.goLogin(history, "กรุณาเข้าสู่ระบบ");
    } else {
      this.getAllFeeds();
    }
  }

  getAllFeeds() {
    const { history, getAllFeeds, feeds } = this.props;
    const length = Object.keys(feeds.data).length;
    if (length === 0) {
      const postData = {
        start: "0",
        limit: 50,
      };
      getAllFeeds(history, postData);
    }
  }

  loadFeed() {
    const { history, getAllFeeds, feeds } = this.props;
    const { data, hasMore } = feeds;
    if (!hasMore || !data) return;
    const postData = {
      start: Object.keys(data).length,
      limit: 50,
    };

    getAllFeeds(history, postData);
  }

  onLikeClick = async (feed) => {
    const { data } = this.props.feeds;
    const { setFeedsAlls } = this.props;
    const act = JSON.parse(AuthService.getToken());
    const postData = { feed_node: feed.feed_node, sid: feed.sid };
    if (act) {
      postData.access_token = act.access_token;
    }
    try {
      const resp = await FeedService.feedClickLike(postData);
      if (resp.success) {
        const newFeed = { ...feed };
        if (Number(feed.liked)) {
          newFeed.liked = 0;
          newFeed.all_liked = Number(feed.all_liked) - 1;
        } else {
          newFeed.liked = 1;
          newFeed.all_liked = Number(feed.all_liked) + 1;
        }
        data[feed.feed_node] = newFeed;
        setFeedsAlls(data);
      }
    } catch (error) {
      console.log(error);
      message.error("เกิดข้อผิดพลาด");
    }
  };

  renderFeedStyleGrid = () => {
    const { data, hasMore } = this.props.feeds;
    const { itemWidth, onResize } = this.props;
    const dataArr = Object.values(data).sort(
      (a, b) => b.feed_node - a.feed_node
    );
    return (
      <div>
        {dataArr.length === 0 ? null : (
          <AutoSizer disableHeight onResize={onResize}>
            {({ width }) => {
              const rowCount = getRowsAmount(
                width,
                dataArr.length,
                hasMore,
                itemWidth
              );
              return (
                <InfiniteLoader
                  ref={this.infiniteLoaderRef}
                  rowCount={rowCount}
                  isRowLoaded={({ index }) => {
                    const maxItemsPerRow = getMaxItemsAmountPerRow(
                      width,
                      itemWidth
                    );
                    const allItemsLoaded =
                      generateIndexesForRow(
                        index,
                        maxItemsPerRow,
                        dataArr.length
                      ).length > 0;
                    return !hasMore || allItemsLoaded;
                  }}
                  loadMoreRows={this.loadFeed.bind(this)}
                >
                  {({ onRowsRendered, registerChild }) => (
                    <WindowScroller>
                      {({ height, scrollTop }) => (
                        <List
                          autoHeight
                          ref={registerChild}
                          height={height + 300}
                          scrollTop={scrollTop}
                          width={width}
                          rowCount={rowCount}
                          rowHeight={this._cache_grid.rowHeight}
                          onRowsRendered={onRowsRendered}
                          rowRenderer={({ index, style, key, parent }) => {
                            const maxItemsPerRow = getMaxItemsAmountPerRow(
                              width,
                              itemWidth
                            );
                            const feedsIds = generateIndexesForRow(
                              index,
                              maxItemsPerRow,
                              dataArr.length
                            ).map((shopIndex) => dataArr[shopIndex]);
                            return (
                              <CellMeasurer
                                cache={this._cache_grid}
                                columnIndex={0}
                                key={key}
                                rowIndex={index}
                                parent={parent}
                              >
                                <Row
                                  style={style}
                                  key={key}
                                  type="flex"
                                  justify="center"
                                  className="mx-auto"
                                  gutter={[16, 16]}
                                >
                                  {feedsIds.map((feed) => {
                                    return (
                                      <Col
                                        key={feed.feed_node}
                                        xs={12}
                                        sm={10}
                                        md={10}
                                        lg={10}
                                        xl={8}
                                        xxl={6}
                                      >
                                        <CheckTypeFeed feed={feed} />
                                      </Col>
                                    );
                                  })}
                                </Row>
                              </CellMeasurer>
                            );
                          }}
                        />
                      )}
                    </WindowScroller>
                  )}
                </InfiniteLoader>
              );
            }}
          </AutoSizer>
        )}
        {hasMore ? <FeedSkeleton /> : null}
      </div>
    );
  };

  render() {
    return this.renderFeedStyleGrid();
  }
}

const mapStateToProps = (state) => ({
  feeds: state.feeds,
  user: state.user,
});

export default connect(mapStateToProps, { getAllFeeds, setFeedsAlls })(
  withRouter(FeedAll)
);
