import React, { Component } from "react";
import { connect } from "react-redux";
import {
  Form,
  Icon,
  Input,
  Button,
  Row,
  Col,
  Avatar,
  message,
  Tooltip,
  Spin,
  Tag,
  Modal,
  Result,
} from "antd";
import {
  getCheckShopDetail,
  getCheckInShop,
  getCheckOutShop,
} from "../store/actions/shop";
import ShopService from "../services/ShopService";

import PoweredByGebtam from "../../src/components/gebtam/PoweredByGebtam";

import { setFeedDate } from "../helpers/time";
import { BASE_URL } from "../config";

function hasErrors(fieldsError) {
  return Object.keys(fieldsError).some((field) => fieldsError[field]);
}

class CheckIn extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isFound: true,
      isLoading: false,
      isCheck: true,
      // isOtp: false,
      // isOtpLoading: false,
      // isOtpDisabled: false,
      amoutCus: 0,
      refOtp: [],
      isSid: "",
      isTel: "",
      visible: false,
      textModal: "",
      m_tel_number: localStorage.getItem("m_tel_number"),
      shopName: null,
      shopImg: "/gebtam.png",
      checkInDate: "",
    };
  }

  componentWillReceiveProps(nextProps) {
    const { location } = this.props;
    const { location: nextLocation } = nextProps;
    if (nextLocation.search !== location.search) {
      window.location.reload();
    }
  }

  componentWillMount() {
    const param = new URLSearchParams(this.props.history.location.search);
    const sid = param.get("sid");
    if (sid) {
      this.checkShopDetail();
      if (localStorage.getItem("sid") === sid) {
        // if (this.state.isOtp) {
        //   this.setState({
        //     isTel: localStorage.getItem("m_tel_number"),
        //     isCheck: true,
        //   });
        // } else {
        this.setState({
          isTel: localStorage.getItem("m_tel_number"),
          isCheck: true,
        });
        // }
      } else {
        if (localStorage.getItem("m_tel_number")) {
          this.setState({
            isTel: localStorage.getItem("m_tel_number"),
          });
        } else {
          this.setState({
            isCheck: true,
          });
        }
      }
    } else {
      message.warning("ไม่มีรหัสร้านค้า");
    }

    const m_tel = localStorage.getItem("temp_tel");
    if (m_tel) {
      this.setState({
        m_tel_number: m_tel,
      });
    } else {
      this.setState({
        m_tel_number: "",
      });
    }
  }

  // call api CheckShopDetail
  checkShopDetail = async () => {
    const param = new URLSearchParams(this.props.history.location.search);
    const sid = param.get("sid");
    await ShopService.getCheckShopDetail({
      sid: sid,
      m_tel_number: localStorage.getItem("m_tel_number"),
    })
      .then((res) => {
        if (res.success) {
          this.setState({
            shopName: res.result.shop_name,
            shopImg: BASE_URL + res.result.path_shop_logo + res.result.head_pic,
            amoutCus: res.result.person_qty,
          });
          if (res.result.action === "checkout") {
            this.setState({
              checkInDate: res.result.action_time,
              isCheck: true,
              isLoading: false,
            });
          } else if (res.result.action === "checkin") {
            this.setState({
              checkInDate: res.result.action_time,
              isCheck: false,
              isLoading: false,
            });
          } else {
            this.setState({
              checkInDate: "",
              isCheck: true,
              isLoading: false,
            });
          }
        } else {
          this.setState({
            shopName: "ไม่พบร้านค้า",
            isFound: false,
            shopImg: "/gebtam.png",
            isLoading: false,
          });
          message.error("ไม่พบร้านค้า");
        }
      })
      .catch((error) => {
        this.setState({ isLoading: false });
        console.log(error);
        message.error("เกิดข้อผิดพลาด");
      });
  };

  // call api CheckInShop
  checkInShop = async (sid, m_tel_number) => {
    const param = new URLSearchParams(this.props.history.location.search);
    const sids = param.get("sid");
    await ShopService.getCheckInShop({
      m_tel_number: m_tel_number,
      sid: sid,
    })
      .then((res) => {
        if (res.success) {
          this.setState({
            m_tel_number: m_tel_number,
            isLoading: false,
            visible: true,
            textModal: "เช็คอินสำเร็จ",
          });
          message.success(res.message);
        } else {
          message.warning(res.Field.m_tel_number);
        }
      })
      .catch((error) => {
        this.setState({ isLoading: false });
        console.log(error);
        message.error("เกิดข้อผิดพลาด");
      });
    this.checkShopDetail(sids);
  };

  // call api CheckOutShop
  checkOutShop = async (sid, m_tel_number) => {
    const param = new URLSearchParams(this.props.history.location.search);
    const sids = param.get("sid");
    await ShopService.getCheckOutShop({
      m_tel_number: m_tel_number,
      sid: sid,
    })
      .then((res) => {
        if (res.success) {
          this.setState({
            isLoading: false,
            visible: true,
            textModal: "เช็คเอ้าท์สำเร็จ",
          });
          message.success(res.message);
        }
      })
      .catch((error) => {
        this.setState({ isLoading: false });
        console.log(error);
        message.error("เกิดข้อผิดพลาด");
      });
    this.checkShopDetail(sids);
  };

  // //Check OTP
  // checkOtp = async (m_tel_number, ref, code) => {
  //   await ShopService.getCheckOtpShop({
  //     to: m_tel_number,
  //     ref: ref,
  //     code: code,
  //   })
  //     .then((res) => {
  //       if (res.success) {
  //         localStorage.setItem("m_tel_number", m_tel_number);
  //         this.setState({
  //           // isSid: localStorage.getItem("sid"),
  //           isLoading: false,
  //           isTel: localStorage.getItem("m_tel_number"),
  //           isOtp: true,
  //         });
  //         message.success("ยืนยัน OTP สำเร็จ");
  //         localStorage.setItem("temp_tel", m_tel_number);
  //       } else {
  //         if (res.result.ERROR_CODE === "CE") {
  //           message.warning("OTP หมดอายุ");
  //         } else if (res.result.ERROR_CODE === "WC") {
  //           message.warning("OTP ไม่ถูกต้อง");
  //         } else if (res.result.ERROR_CODE === "WF") {
  //           message.warning("กรุณากดส่ง OTP");
  //         } else {
  //           message.warning("ไม่สามารถส่ง sms ได้");
  //         }
  //         this.setState({
  //           // isSid: localStorage.getItem("sid"),
  //           isLoading: false,
  //           isTel: localStorage.getItem("m_tel_number"),
  //           isOtp: true,
  //         });
  //       }
  //     })
  //     .catch((error) => {
  //       this.setState({ isLoading: false });
  //       console.log(error);
  //       message.error("เกิดข้อผิดพลาด");
  //     });
  // };

  handleSubmit = (e) => {
    e.preventDefault();
  };

  // ปุ่ม Check out
  handleCheckOutUser = () => {
    this.setState({ isLoading: true });
    const param = new URLSearchParams(this.props.history.location.search);
    const sids = param.get("sid");
    this.checkShopDetail(sids);
    this.checkOutShop(sids, localStorage.getItem("m_tel_number"));
    localStorage.removeItem("sid");
    this.setState({
      isCheck: false,
    });
  };

  // // ปุ่ม ส่ง otp
  // handleSendOtp = () => {
  //   const m_tel_number = this.props.form.getFieldValue("m_tel_number");
  //   const telError = this.props.form.getFieldError("m_tel_number");
  //   this.setState({ m_tel_number: localStorage.getItem("m_tel_number") });
  //   if (!telError && !m_tel_number) {
  //     return message.error("กรุณาระบุหมายเลขโทรศัพท์");
  //   } else if (telError) {
  //     return message.error("กรุณาระบุหมายเลขโทรศัพท์ให้ถูกต้อง");
  //   }
  //   const postData = { to: m_tel_number };
  //   this.setState({ isOtpLoading: true });
  //   ShopService.sendVerifyOtpShop(postData)
  //     .then((resp) => {
  //       const data = resp;
  //       if (data.success) {
  //         message.success("ส่ง OTP สำเร็จ");
  //         this.setState({ refOtp: [...this.state.refOtp, data.ref] });
  //       } else {
  //         if (data.result.ERROR_CODE === 5) {
  //           message.warning("สามารถส่ง OTP ได้เพียง 5 ครั้ง ต่อวัน");
  //         } else {
  //           message.warning("ไม่สามารถส่ง sms ได้");
  //         }
  //       }
  //       this.setState({ isOtpLoading: false, isOtpDisabled: true });
  //     })
  //     .catch((error) => {
  //       this.setState({ isOtpLoading: false, isOtpDisabled: true });
  //       console.log(error);
  //       message.error("เกิดข้อผิดพลาด");
  //     });
  //   setTimeout(() => {
  //     this.setState({ isOtpDisabled: false });
  //   }, 20000);
  // };

  // ปุ่ม ถัดไป
  handleCheckPhone = () => {
    const param = new URLSearchParams(this.props.history.location.search);
    const sids = param.get("sid");
    this.setState({ isLoading: true });
    this.props.form.validateFields((err, values) => {
      if (err) {
        console.log("err", err);
      }
      const m_tel = localStorage.getItem("temp_tel");
      if (values.m_tel_number) {
        localStorage.setItem("m_tel_number", values.m_tel_number);
        localStorage.setItem("temp_tel", values.m_tel_number);
        this.setState({
          // isOtp: true,
          isTel: values.m_tel_number,
          m_tel_number: values.m_tel_number,
          isLoading: false,
        });
      } else {
        if (m_tel) {
          localStorage.setItem("m_tel_number", m_tel);
          this.setState({
            isTel: m_tel,
            m_tel_number: m_tel,
            isLoading: false,
          });
        } else {
          message.warning("กรุณาลงทะเบียนเบอร์ เพื่อเข้าใช้งานครั้งแรก");
          this.setState({
            isLoading: false,
          });
        }
      }
      this.checkShopDetail(sids);
    });
  };

  // // ปุ่ม ถัดไป < OTP >
  // handleCheckUser = () => {
  //   this.setState({ isLoading: true });
  //   this.props.form.validateFields((err, values) => {
  //     this.checkOtp(values.m_tel_number, this.state.refOtp, values.code);
  //   });
  // };

  // ปุ่ม Check in
  handleCheckInUser = () => {
    this.setState({ isLoading: true });
    const param = new URLSearchParams(this.props.history.location.search);
    const sids = param.get("sid");
    this.checkShopDetail(sids);
    this.checkInShop(sids, localStorage.getItem("m_tel_number"));
    this.setState({
      isSid: localStorage.getItem("sid"),
      isCheck: true,
    });
    localStorage.setItem("sid", sids);
  };

  // ปุ่ม ย้อนกลับ
  handleBackFormPhone = () => {
    this.setState({ isLoading: true });
    this.setState({
      // isOtp: false,
      isLoading: false,
    });
  };

  // ปุ่มแก้ไข Phone
  handleClearPhoneNumber = () => {
    this.setState({ isLoading: true });
    localStorage.removeItem("m_tel_number");
    this.setState({
      // isOtp: false,
      isLoading: false,
      isTel: "",
      checkInDate: "",
    });
  };

  // ปุ่มแก้ไข ข้าม
  handleSkip = () => {
    this.setState({ isLoading: true });
    const m_tel = localStorage.getItem("temp_tel");
    if (m_tel) {
      this.setState({ isTel: m_tel });
      localStorage.setItem("m_tel_number", m_tel);
    } else {
      message.warning("กรุณาลงทะเบียนเบอร์ เพื่อเข้าใช้งานครั้งแรก");
    }
    this.setState({
      isLoading: false,
      // isOtp: false,
    });
  };

  renderLoginForm() {
    return (
      <div
        className="text-center mb-3"
        style={{
          borderRadius: "2rem",
          border: "1px solid rgb(212, 212, 212)",
          boxShadow: "0px 0px 3px 0px rgb(212, 212, 212)",
          paddingTop: "0rem",
          color: "rgba(240, 57, 117, 1)",
        }}
      >
        <Tooltip onClick={() => this.handleCheckInUser()} placement="bottom">
          <Icon
            type="environment"
            style={{
              paddingTop: 10,
              cursor: "pointer",
              type: "flex",
              fontSize: "50px",
            }}
          />
          <p align="center">Check In</p>
        </Tooltip>
      </div>
    );
  }

  renderLogoutForm() {
    return (
      <div
        className="text-center mb-3"
        style={{
          borderRadius: "2rem",
          border: "1px solid rgb(212, 212, 212)",
          boxShadow: "0px 0px 3px 0px rgb(212, 212, 212)",
          paddingTop: "0rem",
          color: "#e01f26",
        }}
      >
        <Tooltip onClick={() => this.handleCheckOutUser()} placement="bottom">
          <Icon
            type="logout"
            style={{
              paddingTop: 10,
              cursor: "pointer",
              type: "flex",
              fontSize: "50px",
            }}
          />
          <p align="center">Check Out</p>
        </Tooltip>
      </div>
    );
  }

  renderLoddingForm() {
    return (
      <div
        className="text-center mb-3"
        style={{
          borderRadius: "2rem",
          border: "1px solid rgb(212, 212, 212)",
          boxShadow: "0px 0px 3px 0px rgb(212, 212, 212)",
          paddingTop: "0rem",
          color: "rgba(240, 57, 117, 1)",
        }}
      >
        <Tooltip onClick={() => window.location.reload()} placement="bottom">
          {" "}
          <Icon
            type="exclamation-circle"
            style={{
              paddingTop: 10,
              cursor: "pointer",
              type: "flex",
              fontSize: "50px",
              color: "rgba(240, 57, 117, 1)",
            }}
          />
          <p align="center">Not found</p>
        </Tooltip>
      </div>
    );
  }

  // renderOtpForm() {
  //   const {
  //     getFieldDecorator,
  //     getFieldsError,
  //     isFieldTouched,
  //     getFieldError,
  //   } = this.props.form;
  //   const telError =
  //     isFieldTouched("m_tel_number") && getFieldError("m_tel_number");
  //   const otpError = isFieldTouched("code") && getFieldError("code");
  //   const { isOtpDisabled, refOtp } = this.state;
  //   return (
  //     <div>
  //       <Form.Item
  //         validateStatus={telError ? "error" : ""}
  //         help={telError || ""}
  //       >
  //         <Row gutter={[8, 16]}>
  //           <Col span={16}>
  //             {getFieldDecorator("m_tel_number", {
  //               initialValue: this.state.m_tel_number,
  //               rules: [
  //                 { required: true, message: "ระบุหมายเลขโทรศัพท์" },
  //                 { len: 10, message: "หมายเลขโทรศัพท์ไม่ถูกต้อง" },
  //               ],
  //             })(
  //               <Input
  //                 prefix={
  //                   <Icon type="phone" style={{ color: "rgba(0,0,0,.25)" }} />
  //                 }
  //                 shape="round"
  //                 placeholder="Phone"
  //                 disabled
  //               />
  //             )}
  //           </Col>
  //           <Col span={8}>
  //             <Spin spinning={this.state.isOtpLoading}>
  //               <Button
  //                 type="primary"
  //                 shape="round"
  //                 onClick={this.handleSendOtp}
  //                 disabled={isOtpDisabled}
  //               >
  //                 ส่ง otp
  //               </Button>
  //             </Spin>
  //           </Col>
  //         </Row>{" "}
  //         <Row span={24}>
  //           <div style={{ marginBottom: "-1rem" }}>รหัสอ้างอิง OTP :</div>
  //           {refOtp.map((item, i) => (
  //             <Tag key={i} style={{ color: "rgba(0,0,0,.25)" }}>
  //               {item}
  //             </Tag>
  //           ))}
  //         </Row>
  //       </Form.Item>
  //       <Form.Item
  //         validateStatus={otpError ? "error" : ""}
  //         help={otpError || ""}
  //       >
  //         {getFieldDecorator("code", {
  //           rules: [{ required: true, message: "กรอก OTP" }],
  //         })(
  //           <Input
  //             prefix={<Icon type="key" style={{ color: "rgba(0,0,0,.25)" }} />}
  //             placeholder="OTP"
  //           />
  //         )}
  //       </Form.Item>
  //       <Form.Item>
  //         <Row gutter={[8, 16]} justify="center">
  //           <Col span={12}>
  //             <Button
  //               type="primary"
  //               shape="round"
  //               className="login-form-button font-main"
  //               onClick={() => this.handleBackFormPhone()}
  //             >
  //               ย้อนกลับ
  //             </Button>
  //           </Col>{" "}
  //           <Col span={12}>
  //             <Button
  //               type="primary"
  //               shape="round"
  //               className="login-form-button font-main"
  //               disabled={hasErrors(getFieldsError())}
  //               onClick={() => this.handleCheckUser()}
  //             >
  //               ถัดไป
  //             </Button>
  //           </Col>
  //         </Row>
  //       </Form.Item>
  //     </div>
  //   );
  // }

  render() {
    const {
      getFieldDecorator,
      getFieldsError,
      isFieldTouched,
      getFieldError,
    } = this.props.form;
    const telError =
      isFieldTouched("m_tel_number") && getFieldError("m_tel_number");
    const {
      isLoading,
      isTel,
      isCheck,
      // isOtp,
      shopName,
      shopImg,
      checkInDate,
      isFound,
      amoutCus,
      visible,
      textModal,
    } = this.state;
    return (
      <div className="login-page font-sub">
        <Form onSubmit={this.handleSubmit} className="login-form">
          <Spin spinning={this.state.isLoading}>
            <Modal
              width="20rem"
              centered={true}
              visible={visible}
              footer={null}
              onCancel={() => {
                this.setState({
                  visible: false,
                });
              }}
            >
              <Result
                style={{ padding: 0, align: "center" }}
                status="success"
                title={textModal}
                extra={[
                  <Button
                    shape="round"
                    style={{ height: "auto", padding: "0 20px" }}
                    className="font-main text-center size-20"
                    onClick={() => {
                      this.setState({
                        visible: false,
                      });
                    }}
                  >
                    ตกลง
                  </Button>,
                ]}
              />
              ,
            </Modal>
            <div align="right">ปัจจุบัน : {amoutCus} คน</div>
            <div type="flex" justify="center" className="text-center mb-3">
              <Tooltip placement="top">
                <Avatar shape="square" size={85} src={shopImg} />
              </Tooltip>
              <div
                align="center"
                style={{
                  type: "flex",
                  fontSize: "20px",
                  color: "rgba(240, 57, 117, 1)",
                }}
              >
                {shopName}
                {/* <br />
                {!isTel ? (
                  <>
                    {isOtp ? (
                      <div className="text-center">
                        {" "}
                        <span
                          style={{ opacity: 0.8 }}
                          className="size-07 c-gray"
                        >
                          กรอกรหัส OTP เพื่อยืนยันตัวตน
                        </span>
                      </div>
                    ) : ( */}

                {/* )}
                  </>
                ) : (
                  <></>
                )} */}
                {checkInDate && isCheck === true ? (
                  <p
                    style={{
                      fontSize: "12px",
                      color: "rgba(1, 1, 1, 1)",
                    }}
                  >
                    {" "}
                    เช็คเอ้าท์เมื่อ : {setFeedDate(checkInDate)}
                  </p>
                ) : checkInDate && isCheck === false ? (
                  <p
                    style={{
                      fontSize: "12px",
                      color: "rgba(1, 1, 1, 1)",
                    }}
                  >
                    {" "}
                    เช็คอินเมื่อ : {setFeedDate(checkInDate)}
                  </p>
                ) : (
                  <div className="text-center">
                    {" "}
                    <span style={{ opacity: 0.8 }} className="size-07 c-gray">
                      กรุณากรอกเบอร์โทรฯ เพื่อ Check-in
                    </span>
                  </div>
                )}
                {isTel ? (
                  <div style={{ fontSize: 12, align: "center" }}>
                    {localStorage.getItem("m_tel_number")}
                    {"  "}
                    <Button
                      type="link"
                      shape="circle"
                      onClick={this.handleClearPhoneNumber}
                    >
                      เปลี่ยน
                    </Button>
                  </div>
                ) : (
                  <div></div>
                )}
              </div>
            </div>
            {!isFound ? (
              <>{this.renderLoddingForm()}</>
            ) : !isTel ? (
              // <>
              //   {!isOtp ? (
              <>
                <Form.Item
                  validateStatus={telError ? "error" : ""}
                  help={telError || ""}
                >
                  <Row gutter={16}>
                    {getFieldDecorator("m_tel_number", {
                      initialValue: this.state.m_tel_number,
                      rules: [
                        { required: true, message: "ระบุหมายเลขโทรศัพท์" },
                        { len: 10, message: "หมายเลขโทรศัพท์ไม่ถูกต้อง" },
                      ],
                    })(
                      <Input
                        prefix={
                          <Icon
                            type="phone"
                            style={{ color: "rgba(0,0,0,.25)" }}
                          />
                        }
                        placeholder="Phone"
                      />
                    )}
                  </Row>
                </Form.Item>
                <Form.Item>
                  <Row gutter={16}>
                    <Button
                      type="primary"
                      shape="round"
                      loading={isLoading}
                      className="login-form-button font-main"
                      disabled={hasErrors(getFieldsError())}
                      onClick={() => this.handleCheckPhone()}
                    >
                      ถัดไป
                    </Button>
                  </Row>{" "}
                  {/* <Row gutter={16}>
                        <Button
                          type="primary"
                          shape="round"
                          loading={isLoading}
                          className="login-form-button font-main"
                          onClick={() => this.handleSkip()}
                        >
                          ข้าม
                        </Button>
                      </Row> */}
                </Form.Item>
              </>
            ) : // ) : (
            //   <>{this.renderOtpForm()}</>
            // )}
            // </>
            isCheck ? (
              <>{this.renderLoginForm()}</>
            ) : (
              <>{this.renderLogoutForm()}</>
            )}
          </Spin>
          <PoweredByGebtam />
        </Form>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  shops: state.shops,
});
const CheckinForm = Form.create({ name: "checkin" })(CheckIn);

export default connect(mapStateToProps, {
  getCheckShopDetail,
  getCheckInShop,
  getCheckOutShop,
})(CheckinForm);
