import React from "react";
import { Button, Icon } from "antd";

export default function OrderNextStepBtn({ onClick, isBack }) {
  return (
    <Button
      block
      type={`${isBack ? "default" : "primary"}`}
      className={` size-15 font-main  bt l-0  h-3 radius-0`}
      onClick={onClick}
    >
      {isBack ? (
        <>
          <Icon className="size-10 " type="left" /> ย้อนกลับ
        </>
      ) : (
        <>
          {" "}
          ถัดไป <Icon className="size-10 " type="right" />
        </>
      )}
    </Button>
  );
}
