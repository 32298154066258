import React from "react";
import { PageHeader } from "antd";

import Main from "./Main";

import MyCardList from "../components/shop/MyCardList";

function Mycard() {
  return (
    <Main title="my card">
      <PageHeader
        className="b-sub"
        style={{
          marginBottom: "2rem",
        }}
      >
        <span className="c-w font-main size-15">My card</span>
      </PageHeader>
      <MyCardList />
    </Main>
  );
}

export default Mycard;
