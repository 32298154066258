import React, { useState } from "react";
import VerifyFormV2 from "../components/verify-v2/VerifyFormV2";
import VerifyRegisV2 from "../components/verify-v2/VerifyRegisV2";

export default function VerifyV2() {
  const [isVerify, setIsVerify] = useState(false);
  const [telNumber, setTelNumber] = useState("");
  const [code, setCode] = useState("");

  if (isVerify) {
    return (
      <VerifyRegisV2
        m_tel_number={telNumber}
        setIsVerify={setIsVerify}
        setTelNumber={setTelNumber}
        code={code}
      />
    );
  } else {
    return (
      <VerifyFormV2
        setIsVerify={setIsVerify}
        setTelNumber={setTelNumber}
        setCode={setCode}
      />
    );
  }
}
