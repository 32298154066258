import {
  APP_URL,
  BASE_URL,
  APP_URL_V12,
  APP_SHOP_V1,
  APP_SMS,
  APP_CHECKIN,
} from "../config";
import http from "./HttpService";
const ShopService = {
  async getAllShop(data) {
    const url = `${APP_URL}allShops`;
    return await http.requestPost(url, data);
  },
  async updateShopFollower(data) {
    const url = `${APP_URL}shopFollow`;
    return await http.requestPost(url, data);
  },
  async getShopDetail(data) {
    const url = `${APP_URL}shopDetail`;
    return await http.requestPost(url, data);
  },
  async getShopBranchs(data) {
    const url = `${APP_URL}shopInfo`;
    return await http.requestPost(url, data);
  },
  async getShopFeeds(data) {
    const url = `${APP_URL}homeShopFeeds`;
    return await http.requestPost(url, data);
  },
  async getMyshops(data) {
    const url = `${APP_URL_V12}myShops`;
    return await http.requestPost(url, data);
  },
  async getMyPointShop(data) {
    const url = `${APP_URL}memberPoints`;
    return await http.requestPost(url, data);
  },
  async getShopPromotions(data) {
    const url = `${APP_URL}shopPromotions`;
    return await http.requestPost(url, data);
  },
  // Checkin
  async getCheckShopDetail(data) {
    const url = `${APP_CHECKIN}checkShopDetail`;
    return await http.requestPost(url, data);
  },
  async getCheckInShop(data) {
    const url = `${APP_CHECKIN}checkin`;
    return await http.requestPost(url, data);
  },
  async getCheckOutShop(data) {
    const url = `${APP_CHECKIN}checkout`;
    return await http.requestPost(url, data);
  },
  async getCheckOtpShop(data) {
    const url = `${APP_CHECKIN}signUpOtpCode`;
    return await http.requestPost(url, data);
  },
  async sendVerifyOtpShop(data = {}) {
    const url = `${APP_CHECKIN}signUpSendCode`;
    return await http.requestPost(url, data);
  },
  async sendVerifyOtpEvent(data = {}) {
    const url = `${APP_CHECKIN}signUpSendEventCode`;
    return await http.requestPost(url, data);
  },
  async getIn(data = {}) {
    const url = `${APP_CHECKIN}member_getin`;
    return await http.requestPost(url, data);
  },
  // SMS
  async getMemberSMS(data) {
    const url = `${APP_SMS}get_member_sms`;
    return await http.requestPost(url, data);
  },
  async setMemberSMS(data) {
    const url = `${APP_SMS}set_member_sms`;
    return await http.requestPost(url, data);
  },

  // Province
  async getProvince() {
    const url = `${APP_SHOP_V1}get_province`;
    return await http.requestGet(url);
  },

  getShopLogo(headId, headPic) {
    return `${BASE_URL}pics/shops/${headId}/logos/${headPic}`;
  },
  getLogoHasPath(pathHeadPic, headPic) {
    return `${BASE_URL}${pathHeadPic}${headPic}`;
  },
};
export default ShopService;
