import {
  Avatar,
  Button,
  Col,
  Form,
  Icon,
  Input,
  Row,
  Tabs,
  Tooltip,
  message,
} from "antd";
import React, { Component } from "react";
import {
  chkUser,
  loginUpdateUser,
  loginUpdateUserEvent,
  loginUser,
  loginUserOTP,
  registerUpdateUser,
  registerUpdateUserEvent,
  registerUser,
} from "../store/actions/auth";

import AuthService from "../services/AuthService";
import { BASE_URL } from "../config";
import { Link } from "react-router-dom";
import PoweredByGebtam from "../../src/components/gebtam/PoweredByGebtam";
import ShopService from "../services/ShopService";
import { checkLoginUser } from "../store/actions/auth";
import { connect } from "react-redux";
import md5 from "react-native-md5";
import md5V2 from "md5";

function hasErrors(fieldsError) {
  return Object.keys(fieldsError).some((field) => fieldsError[field]);
}

function callback(key) {
  // console.log(key);
}

class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      m_tel_number: "",
      m_password: "",
      code: "",
      confirm: "",
      notify_status: true,
      errors: {},
      isLoading: false,
      isOtpLoading: false,
      confirmDirty: false,
      isPwd: false,
      isOtp: false,
      isRig: false,
      tabKey: "1",
      shopName: "",
      shopImg: "/gebtam.png",
    };
  }

  componentDidMount() {
    const param = new URLSearchParams(this.props.history.location.search);
    const sid = param.get("sid");
    const path = param.get("path") ? param.get("path") : "/";
    if (sid === "3800001") {
      this.handleCheckShopDetails(sid);
      if (this.props.user.isAuthenticated) {
        this.props.history.push(
          `/updateProfile?tel=${this.props.user.profile.m_tel_number}`
        );
      } else {
        this.props.history.push(`login?sid=${sid}`);
      }
    } else if (sid === "3820001") {
      this.handleCheckShopDetails(sid);
      if (this.props.user.isAuthenticated) {
        this.props.history.push(
          `/updateProfileEvent?tel=${this.props.user.profile.m_tel_number}&sid=${sid}`
        );
      } else {
        this.props.history.push(`login?sid=${sid}`);
      }
    } else {
      if (this.props.user.isAuthenticated) {
        this.props.history.push(`${path}`);
      } else {
        if (path !== "/") {
          this.props.history.push(`/login?path=${path}`);
        } else {
          this.props.history.push(`/login`);
        }
      }
    }
    this.props.checkLoginUser();

    this.props.form.validateFields();
  }

  //เมื่อเกิดเหตุการณ์ที่ store
  componentWillReceiveProps(nextProps) {
    const { location } = this.props;
    const { location: nextLocation } = nextProps;
    if (nextLocation.search !== location.search) {
      window.location.reload();
    }
    if (nextProps.user && nextProps.user.isAuthenticated) {
      const param = new URLSearchParams(this.props.history.location.search);
      const path = param.get("path") || "/";
      this.props.history.push(`${path}`);
    }
    if (
      nextProps.errors.loginError &&
      nextProps.errors.isLogin !== this.props.errors.isLogin
    ) {
      this.setState({
        isLoading: false,
      });
      message.warning(nextProps.errors.loginError);
    }

    if (
      nextProps.errors.registerError &&
      nextProps.errors.isRegister !== this.props.errors.isRegister
    ) {
      this.setState({
        isLoading: false,
      });
      message.warning(nextProps.errors.registerError);
    }
  }

  handleToShop = () => {
    const param = new URLSearchParams(this.props.history.location.search);
    const path = param.get("path") ? param.get("path") : "/";
    if (path.includes("/feed")) {
      const location = path ? `/shared${path}` : "/";
      this.props.history.push(`${location}`);
    } else {
      const location = "/";
      this.props.history.push(`${location}`);
    }
  };

  handleGoBack = () => {
    this.setState({ isLoading: true });

    this.setState(
      {
        isRig: false,
        isPwd: false,
        isOtp: false,
        tabKey: "1",
        isLoading: false,
      },
      () => {
        this.props.form.validateFields();
      }
    );
  };

  handleSubmit = (e) => {
    e.preventDefault();
    const param = new URLSearchParams(this.props.history.location.search);
    const sid = param.get("sid");
    if (!this.state.isRig) {
      // TODO : ลูกค้า ไม่ได้เป็น สมาชิก
      this.props.form.validateFields((err, values) => {
        console.log("values :>> ", values);
        if (!err) {
          let newState = { ...this.state };
          if (values.m_password)
            newState.m_password = md5V2(
              // eslint-disable-next-line no-control-regex
              `${md5.str_md5(values.m_password).split(/\u0000/)[0]}AppDD2018`
            );

          console.log(
            this.state.isOtp,
            this.state.isPwd,
            this.state.m_tel_number
          );
          if (sid === "3800001" && this.state.m_tel_number) {
            newState.fromWeb = true;
            newState.m_tel_number = this.state.m_tel_number;
            newState.isLoading = true;
            this.setState(newState);
            this.props.loginUpdateUser(newState, this.props.history);
          } else if (sid === "3820001" && this.state.m_tel_number) {
            newState.fromWeb = true;
            newState.m_tel_number = this.state.m_tel_number;
            newState.isLoading = true;
            this.setState(newState);
            this.props.loginUpdateUserEvent(newState, this.props.history, sid);
          } else {
            if (
              this.state.isOtp &&
              !this.state.isPwd &&
              this.state.m_tel_number
            ) {
              // TODO : Login ด้วย OTP
              newState.fromWeb = true;
              newState.m_tel_number = this.state.m_tel_number;
              newState.code = values.code;
              newState.isLoading = true;
              this.setState(newState);
              this.props.loginUserOTP(newState, this.props.history);
            }
            if (
              !this.state.isOtp &&
              this.state.isPwd &&
              this.state.m_tel_number
            ) {
              // TODO : Login ด้วย Password
              newState.fromWeb = true;
              newState.m_tel_number = this.state.m_tel_number;
              newState.isLoading = true;
              this.setState(newState);
              this.props.loginUser(newState, this.props.history);
            }
          }
        }
      });
    } else {
      // TODO : ลูกค้า เป็น สมาชิก
      this.props.form.validateFieldsAndScroll((err, values) => {
        if (!err) {
          this.setState({ isLoading: true });
          const newValues = { ...values };
          newValues.notify_status = newValues.notify_status ? 1 : 0;
          newValues.m_password = md5V2(
            // eslint-disable-next-line no-control-regex
            `${md5.str_md5(values.m_password).split(/\u0000/)[0]}AppDD2018`
          );

          if (sid === "3800001" && this.state.m_tel_number) {
            newValues.fromWeb = true;
            newValues.m_tel_number = this.state.m_tel_number;
            newValues.notify_status = 1;
            delete newValues.confirm;
            this.props.registerUpdateUser(newValues, this.props.history);
          } else if (sid === "3820001" && this.state.m_tel_number) {
            newValues.fromWeb = true;
            newValues.m_tel_number = this.state.m_tel_number;
            newValues.notify_status = 1;
            delete newValues.confirm;
            this.props.registerUpdateUserEvent(
              newValues,
              this.props.history,
              sid
            );
          } else {
            newValues.fromWeb = true;
            delete newValues.confirm;
            this.props.registerUser(newValues, this.props.history);
          }
        }
      });
    }
  };

  handleCheckShopDetails = async (sid) => {
    await ShopService.getCheckShopDetail({ sid: sid })
      .then((res) => {
        if (res.success) {
          this.setState({
            shopName: res.result.shop_name,
            shopImg: BASE_URL + res.result.path_shop_logo + res.result.head_pic,
          });
        } else {
          this.setState({
            shopName: "ไม่พบร้านค้า",
            shopImg: "/gebtam.png",
          });
          message.error("ไม่พบร้านค้า");
        }
      })
      .catch((error) => {
        this.setState({ isLoading: false });
        console.log(error);
        message.error("เกิดข้อผิดพลาด");
      });
  };

  handleCheckUser = () => {
    this.props.form.validateFields(async (err, values) => {
      if (!err) {
        this.setState({ isLoading: true });
        this.props
          .chkUser(values)
          .then((res) => {
            if (res.success) {
              this.setState({
                isRig: true, // TODO : เบอร์ลูกค้าไม่ได้เป็นสมาชิก <true>
                isOtp: false,
                isPwd: false,
                tabKey: "2",
                m_tel_number: values.m_tel_number,
              });
            } else {
              this.setState({
                // isRig: false,
                isOtp: true, // TODO : ลูกค้าเก่า เมื่อล็อคอินให้เข้าหน้า OTP <true>
                isPwd: false, // TODO : ลูกค้าเก่า เมื่อล็อคอินให้เข้าหน้า Password <true>
                tabKey: "2",
                m_tel_number: values.m_tel_number,
              });
            }
            this.setState({ isLoading: false });
          })
          .catch((error) => {
            this.setState({ isLoading: false });
          });
      }
    });
  };

  handleSendOtp = () => {
    const m_tel_number = this.props.form.getFieldValue("m_tel_number");
    const telError = this.props.form.getFieldError("m_tel_number");
    if (!telError && !m_tel_number) {
      return message.error("กรุณาระบุหมายเลขโทรศัพท์");
    } else if (telError) {
      return message.error("กรุณาระบุหมายเลขโทรศัพท์ให้ถูกต้อง");
    }
    const postData = { to: m_tel_number };
    this.setState({ isOtpLoading: true });
    AuthService.loginSendCode(postData)
      .then((resp) => {
        const data = resp;
        console.log("data :>> ", data);
        if (data.success) {
          message.success("ส่ง OTP สำเร็จ");
        } else {
          message.warning("ไม่สามารถส่ง sms ได้");
        }
        this.setState({ isOtpLoading: false });
      })
      .catch((error) => {
        this.setState({ isOtpLoading: false });
        console.log(error);
        message.error("เกิดข้อผิดพลาด");
      });
  };

  compareToFirstPassword = (rule, value, callback) => {
    const { form } = this.props;
    if (value && value !== form.getFieldValue("m_password")) {
      callback("รหัสผ่านไม่ตรงกัน");
    } else {
      callback();
    }
  };

  validateToNextPassword = (rule, value, callback) => {
    const { form } = this.props;
    if (value && this.state.confirmDirty) {
      form.validateFields(["confirm"], { force: true });
    }
    callback();
  };

  render() {
    const {
      getFieldDecorator,
      getFieldsError,
      getFieldError,
      isFieldTouched,
    } = this.props.form;
    const { TabPane } = Tabs;
    // Only show error after a field is touched.
    const telError =
      isFieldTouched("m_tel_number") && getFieldError("m_tel_number");
    const {
      isLoading,
      isRig,
      isPwd,
      isOtp,
      tabKey,
      shopImg,
      shopName,
    } = this.state;
    const param = new URLSearchParams(this.props.history.location.search);
    const path = param.get("path") ? param.get("path") : "/";
    const forgot = path !== "/" ? `/forgot?path=${path}` : "/forgot";
    const register = path !== "/" ? `/register?path=${path}` : "/register";
    return (
      <div className="login-page font-sub">
        <Form onSubmit={this.handleSubmit} className="login-form">
          {isPwd || isOtp ? (
            <Row type="flex" justify="space-between">
              <Col flex="1" className="font-main">
                <Button
                  className="size-12 text-left pd-0"
                  type="link"
                  block
                  onClick={(e) => this.handleGoBack()}
                >
                  กลับ
                </Button>
              </Col>
              <Col flex="1" className="font-main">
                <Button
                  className="size-10 text-right pd-0"
                  type="link"
                  block
                  onClick={() =>
                    this.setState({
                      isOtp: !isOtp,
                      isPwd: !isPwd,
                    })
                  }
                >
                  {isPwd ? "Login with OTP" : "Login with Password"}
                </Button>
              </Col>
            </Row>
          ) : (
            <div
              className="font-main text-right size-15"
              style={{ padding: 10, margin: -10, cursor: "pointer" }}
              onClick={() => this.handleToShop()}
            >
              ข้าม
            </div>
          )}
          {shopImg === "/gebtam.png" ? (
            <>
              <div type="flex" justify="center" className="text-center mb-3">
                <Link to="/">
                  <Tooltip
                    placement="bottom"
                    title={<span className="font-sub">หน้าแรก</span>}
                  >
                    <Avatar shape="square" size={64} src={shopImg} />
                    <br />
                    {!isRig && this.state.m_tel_number
                      ? isPwd
                        ? "เข้าสู่ระบบด้วย Password"
                        : "เข้าสู่ระบบด้วย OTP"
                      : null}
                  </Tooltip>
                </Link>
              </div>
            </>
          ) : (
            <>
              <>
                <div type="flex" justify="center" className="text-center mb-3">
                  <Tooltip
                    placement="bottom"
                    title={<span className="font-sub">{shopName}</span>}
                  >
                    <Avatar shape="square" size={64} src={shopImg} />
                  </Tooltip>
                </div>
              </>
            </>
          )}
          <Tabs defaultActiveKey="1" activeKey={tabKey} onChange={callback}>
            <TabPane tab="โทรศัพท์" key="1">
              <div style={{ marginLeft: 4 }}>
                <Form.Item
                  validateStatus={telError ? "error" : ""}
                  help={telError || ""}
                >
                  {!isPwd &&
                    getFieldDecorator("m_tel_number", {
                      initialValue: this.state.m_tel_number,
                      rules: [
                        { required: true, message: "ระบุหมายเลขโทรศัพท์" },
                        { len: 10, message: "หมายเลขโทรศัพท์ไม่ถูกต้อง" },
                      ],
                    })(
                      <Input
                        prefix={
                          <Icon
                            type="phone"
                            style={{ color: "rgba(0,0,0,.25)" }}
                          />
                        }
                        autoFocus
                        placeholder="Phone"
                        onKeyPress={(e) => {
                          if (e.key === "Enter") {
                            this.handleCheckUser();
                          }
                        }}
                      />
                    )}
                </Form.Item>
              </div>
            </TabPane>
            <TabPane tab="ระบุรหัส" key="2">
              {isRig ? (
                <>
                  <Form.Item
                  // validateStatus={telError ? "error" : ""}
                  // help={telError || ""}
                  >
                    <Row gutter={[8, 24]}>
                      <Col span={16}>
                        {getFieldDecorator("m_tel_number", {
                          initialValue: this.state.m_tel_number,
                          rules: [
                            { required: true, message: "ระบุหมายเลขโทรศัพท์" },
                            { len: 10, message: "หมายเลขโทรศัพท์ไม่ถูกต้อง" },
                          ],
                        })(
                          <Input
                            prefix={
                              <Icon
                                type="phone"
                                style={{ color: "rgba(0,0,0,.25)" }}
                              />
                            }
                            placeholder="Phone"
                            disabled
                          />
                        )}
                      </Col>
                      <Col span={8}>
                        <Button
                          type="primary"
                          shape="round"
                          block
                          loading={this.state.isOtpLoading}
                          onClick={this.handleSendOtp}
                        >
                          ส่ง otp
                        </Button>
                      </Col>
                    </Row>
                  </Form.Item>
                  <Form.Item
                  // validateStatus={otpError ? "error" : ""}
                  // help={otpError || ""}
                  >
                    {getFieldDecorator("code", {
                      rules: [{ required: true, message: "กรอก OTP" }],
                    })(
                      <Input
                        prefix={
                          <Icon
                            type="key"
                            style={{ color: "rgba(0,0,0,.25)" }}
                          />
                        }
                        placeholder="OTP"
                      />
                    )}
                  </Form.Item>

                  <Form.Item
                    //  validateStatus={passwordError ? "error" : ""}
                    //  help={passwordError || ""}
                    hasFeedback
                  >
                    {getFieldDecorator("m_password", {
                      rules: [
                        {
                          required: true,
                          message: "กรอกรหัสผ่าน",
                        },
                        {
                          validator: this.validateToNextPassword,
                        },
                        {
                          min: 8,
                          message: "รหัสผ่านอย่างน้อย 8 ตัวอักษร",
                        },
                      ],
                    })(
                      <Input.Password
                        prefix={
                          <Icon
                            type="lock"
                            style={{ color: "rgba(0,0,0,.25)" }}
                          />
                        }
                        placeholder="ตั้งรหัสผ่าน"
                      />
                    )}
                  </Form.Item>
                  <Form.Item
                    //  validateStatus={confirmError ? "error" : ""}
                    //  help={confirmError || ""}
                    hasFeedback
                  >
                    {getFieldDecorator("confirm", {
                      rules: [
                        {
                          required: true,
                          message: "ยืนยันรหัสผ่าน",
                        },
                        {
                          validator: this.compareToFirstPassword,
                        },
                        {
                          min: 8,
                          message: "รหัสผ่านอย่างน้อย 8 ตัวอักษร",
                        },
                      ],
                    })(
                      <Input.Password
                        prefix={
                          <Icon
                            type="unlock"
                            style={{ color: "rgba(0,0,0,.25)" }}
                          />
                        }
                        placeholder="ยืนยันรหัสผ่าน"
                        onBlur={this.handleConfirmBlur}
                      />
                    )}
                  </Form.Item>
                </>
              ) : (
                <div style={{ marginLeft: 4 }}>
                  {isPwd ? (
                    <Form.Item
                      // validateStatus={passwordError ? "error" : ""}
                      // help={passwordError || ""}
                      hasFeedback
                    >
                      {getFieldDecorator("m_password", {
                        rules: [{ required: true, message: "ระบุรหัสผ่าน" }],
                      })(
                        <Input.Password
                          prefix={
                            <Icon
                              type="lock"
                              style={{ color: "rgba(0,0,0,.25)" }}
                            />
                          }
                          autoFocus
                          type="password"
                          placeholder="รหัสผ่าน"
                        />
                      )}
                    </Form.Item>
                  ) : (
                    <>
                      <Form.Item
                      // validateStatus={telError ? "error" : ""}
                      // help={telError || ""}
                      >
                        <Row gutter={[8, 24]}>
                          <Col span={16}>
                            {getFieldDecorator("m_tel_number", {
                              initialValue: this.state.m_tel_number,
                              rules: [
                                {
                                  required: true,
                                  message: "ระบุหมายเลขโทรศัพท์",
                                },
                                {
                                  len: 10,
                                  message: "หมายเลขโทรศัพท์ไม่ถูกต้อง",
                                },
                              ],
                            })(
                              <Input
                                prefix={
                                  <Icon
                                    type="phone"
                                    style={{ color: "rgba(0,0,0,.25)" }}
                                  />
                                }
                                placeholder="Phone"
                                disabled
                              />
                            )}
                          </Col>
                          <Col span={8}>
                            <Button
                              type="primary"
                              shape="round"
                              block
                              loading={this.state.isOtpLoading}
                              onClick={this.handleSendOtp}
                            >
                              ส่ง otp
                            </Button>
                          </Col>
                        </Row>
                      </Form.Item>
                      <Form.Item
                      // validateStatus={otpError ? "error" : ""}
                      // help={otpError || ""}
                      >
                        {getFieldDecorator("code", {
                          // rules: [{ required: true, message: "กรอก OTP" }],
                        })(
                          <Input
                            prefix={
                              <Icon
                                type="key"
                                style={{ color: "rgba(0,0,0,.25)" }}
                              />
                            }
                            placeholder="OTP"
                          />
                        )}
                      </Form.Item>
                    </>
                  )}
                </div>
              )}
            </TabPane>
          </Tabs>

          <Form.Item>
            {tabKey === "1" ? (
              <Button
                type="primary"
                // htmlType="submit"
                shape="round"
                loading={isLoading}
                className="login-form-button font-main"
                disabled={hasErrors(getFieldsError())}
                onClick={() => this.handleCheckUser()}
              >
                ถัดไป
              </Button>
            ) : (
              <Button
                type="primary"
                htmlType="submit"
                shape="round"
                loading={isLoading}
                className="login-form-button font-main"
                disabled={hasErrors(getFieldsError())}
              >
                {isRig ? "สมัครสมาชิก" : "เข้าสู่ระบบ"}
              </Button>
            )}
            {(isPwd || isOtp) && !isRig ? (
              <Row type="flex" justify="space-between">
                <Col span={12}>
                  <Link to={register} className="font-main size-12">
                    สมัครสมาชิก
                  </Link>
                </Col>
                <Col span={12} className="text-right ">
                  <Link to={forgot} className="font-main text-right size-12">
                    ลืมรหัสผ่าน ?
                  </Link>
                </Col>
              </Row>
            ) : (
              <div></div>
            )}
          </Form.Item>
          <PoweredByGebtam />
        </Form>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.user,
  errors: state.errors,
});

const LoginForm = Form.create({ name: "normal_login" })(Login);

export default connect(mapStateToProps, {
  loginUser,
  checkLoginUser,
  chkUser,
  registerUser,
  loginUpdateUser,
  loginUserOTP,
  registerUpdateUser,
  registerUpdateUserEvent,
  loginUpdateUserEvent,
})(LoginForm);
