import React from "react";
import { Button, Icon } from "antd";
import { Link } from "react-router-dom";

export default function FeedOrderBtn({ path }) {
  return (
    <Link to={path}>
      <Button
        shape="circle"
        type="primary"
        className="ab font-main size-12 sm-size-10 bg-sub no-border mr-2 mb-2"
        style={{ bottom: 0, right: 0, opacity: 0.8 }}
      >
        <Icon
          type="shopping-cart"
          className="ml-0"
          style={{ verticalAlign: -7, marginTop: 2 }}
        />
      </Button>
    </Link>
  );
}
