import React, { Component } from "react";
import { Layout, Affix } from "antd";
import LeftMenu from "./LeftMenu";

const { Sider } = Layout;
class LayoutSider extends Component {
  constructor(props) {
    super(props);
    this.state = {
      collapsed: false,
    };
  }

  onCollapse = (collapsed) => {
    this.setState({ collapsed });
  };

  render() {
    return (
      <Affix className="b-main">
        <Sider
          theme="dark"
          onCollapse={this.onCollapse}
          className="font-main size-12 rela"
        >
          <LeftMenu />
        </Sider>
      </Affix>
    );
  }
}

export default LayoutSider;
