import React from "react";
import FeedCardImage from "./feed-card/FeedCardImage";
import ShopService from "../../services/ShopService";
import FeedCardTextAndImage from "./feed-card/FeedCardTextAndImage";
import FeedCardText from "./feed-card/FeedCardText";
import FeedCardDefaultText from "./feed-card/FeedCardDefaultText";

export default function CheckTypeFeed({
  feed,
  isNotResize,
  isNotShowOrderBtn,
}) {
  const pathShopLogo = ShopService.getLogoHasPath(
    feed.path_shop_logo,
    feed.head_pic
  );
  if (!feed.feed_type) {
    if (feed.feed_pic) {
      return (
        <FeedCardImage
          isNotResize={isNotResize}
          feed={feed}
          pathShopLogo={pathShopLogo}
          shopName={feed.shop_name}
          isNotShowOrderBtn={isNotShowOrderBtn}
        />
      );
    } else {
      return (
        <FeedCardDefaultText
          isNotResize={isNotResize}
          feed={feed}
          pathShopLogo={pathShopLogo}
          shopName={feed.shop_name}
          isNotShowOrderBtn={isNotShowOrderBtn}
        />
      );
    }
  } else if (feed.feed_type === "1") {
    return (
      <FeedCardText
        isNotResize={isNotResize}
        feed={feed}
        pathShopLogo={pathShopLogo}
        shopName={feed.shop_name}
        isNotShowOrderBtn={isNotShowOrderBtn}
      />
    );
  } else if (feed.feed_type === "2") {
    return (
      <FeedCardTextAndImage
        isNotResize={isNotResize}
        feed={feed}
        pathShopLogo={pathShopLogo}
        shopName={feed.shop_name}
        isNotShowOrderBtn={isNotShowOrderBtn}
      />
    );
  } else if (feed.feed_type === "3") {
    return (
      <FeedCardImage
        isNotResize={isNotResize}
        feed={feed}
        pathShopLogo={pathShopLogo}
        shopName={feed.shop_name}
        isNotShowOrderBtn={isNotShowOrderBtn}
      />
    );
  }
}
