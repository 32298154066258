import {
  GET_PRODUCTS_PENDING,
  GET_PRODUCTS_SUCCESS,
  GET_PRODUCTS_ERROR,
  GET_ALL_PRODUCTS_SUCCESS,
  SET_IS_PAYMENT,
  SET_PAYMENT_VISIBLE,
} from "../actions/types";

const products = (state = {}, action) => {
  switch (action.type) {
    case GET_PRODUCTS_PENDING:
      return {
        ...state,
        pending: true,
      };
    case GET_PRODUCTS_SUCCESS:
      return {
        ...state,
        pending: false,
        data: { ...state.data, ...action.payload },
      };
    case GET_ALL_PRODUCTS_SUCCESS:
      return {
        ...state,
        hasMore: false,
        pending: false,
        data: { ...state.data, ...action.payload },
      };
    case GET_PRODUCTS_ERROR:
      return {
        ...state,
        pending: false,
        error: action.error,
      };
    case SET_IS_PAYMENT:
      return {
        ...state,
        isPayment: action.payload,
      };
    case SET_PAYMENT_VISIBLE:
      return {
        ...state,
        visible: action.payload,
      };
    default:
      return state;
  }
};

export default products;
