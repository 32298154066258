import React from 'react';
import Main from './Main';
import FeedShared from '../components/feed/FeedShared';
import { Row, Col } from 'antd';

export default function Shared() {
  return (
    <Main title="shared">
      <Row type="flex" justify="center">
        <Col
          xs={24}
          sm={16}
          md={18}
          lg={16}
          xl={12}
          xxl={10}
          className="mt-3 mb-3 sm-mt-0 sm-mb-0"
        >
          <FeedShared />
        </Col>
      </Row>
    </Main>
  );
}
