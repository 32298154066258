import React, { useEffect } from "react";
import { connect } from "react-redux";

import { getMyShops, updateShopFollower } from "../../store/actions/shop";
import { Row, Col } from "antd";
import FeedSkeleton from "../feed/FeedSkeleton";
import ShopCard from "./ShopCard";
import { useHistory } from "react-router-dom";

function MyCardList({ shops, updateShopFollower, ...props }) {
  const history = useHistory();
  const { dataFollow, hasMoreFollow } = shops;
  const dataArr = Object.values(dataFollow);

  useEffect(() => {
    const getMyShops = () => {
      const postData = {
        start: 0,
        limit: 100,
      };
      props.getMyShops(history, postData);
    };
    const { dataFollow, hasMoreFollow } = shops;
    const len = Object.values(dataFollow).length;
    if (len === 0 || hasMoreFollow) {
      getMyShops();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const followerChange = (e, shop) => {
    shop.status = e ? 1 : 0;
    shop.followed = e;
    updateShopFollower(history, shop);
  };
  return (
    <>
      {" "}
      <Row
        type="flex"
        gutter={[8, 8]}
        justify="center"
        style={{ overflowX: "auto" }}
        className="pd-2"
      >
        {dataArr.map((shop) => {
          return (
            <Col xs={24} sm={24} md={24} lg={24} xl={12} xxl={8} key={shop.sid}>
              <ShopCard
                shop={shop}
                history={history}
                followerChange={followerChange}
              />
            </Col>
          );
        })}
      </Row>
      {hasMoreFollow ? <FeedSkeleton /> : null}
    </>
  );
}
const mapStateToProps = (state) => ({
  shops: state.shops,
  user: state.user,
});

export default connect(mapStateToProps, { getMyShops, updateShopFollower })(
  MyCardList
);
