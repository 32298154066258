import moment from "moment";

export const setThaiDate = (date = new Date(), type = "/") => {
  const format = moment(date).format(`DD${type}MM${type}YYYY`);
  return setThaiYear(format);
};

export const setDayAgo = (date = new Date(), day = 1) =>
  date.setDate(date.getDate() - day);

export const setFormatEventDay = (date = new Date()) => {
  const format = moment(date).format("dddd D MMM YYYY");
  return setThaiYear(format);
};

export const setThaiYear = (dateString) => {
  return dateString.replace(/\d{4}/, (num) => Number(num) + 543);
};

export const setFormatOverview = (date = new Date()) => {
  const format = moment(date).format("DD MMM YYYY");
  return setThaiYear(format);
};

export const setFormatDateTime = (date = new Date()) => {
  const format = moment(date).format("DD MMM YYYY HH.mm a.");
  return setThaiYear(format);
};

export const setFeedDate = (date = new Date()) => {
  return moment(date, "YYYY-MM-DD hh:mm a").format("DD/MM/YYYY : HH.mm ");
};

export const timeOnly = (date = new Date()) => {
  return moment(date).format("HH:mm");
};

export const checkPostActiveByTime = (
  openTime = new Date(),
  closeTime = new Date()
) => {
  const now = moment(new Date());
  const openTimeStamp = moment(openTime);
  const closeTimeStamp = moment(closeTime);

  return now > openTimeStamp && now < closeTimeStamp;
};

export const setFormatDatabase = (date = new Date()) => {
  return moment(new Date(date)).format("YYYY-MM-DD HH:mm:ss");
};
