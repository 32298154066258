import {
  browserName,
  isAndroid,
  isChrome,
  isIOS,
  isMobile,
  isSafari,
} from "react-device-detect";

import { message } from "antd";
import moment from "moment";

export const setFormatDate = (date = new Date(), type = "/") => {
  return moment(date).format(`YYYY${type}MM${type}DD`);
};
export const objectToFormData = (data = {}) => {
  const form = new FormData();
  Object.entries(data).forEach((item) => {
    form.append(item[0], item[1] || "");
  });
  return form;
};
export const setStyleFormatDate = (date = new Date(), type = "/") => {
  return moment(date).format(`DD${type}MM${type}YY`);
};
export const setStyleFormatDateFullYear = (
  date = new Date(),
  type = "/",
  spec = ""
) => {
  return moment(date).format(
    `DD${spec}${type}${spec}MM${spec}${type}${spec}YYYY`
  );
};
export const setStyleFormatDateTH = (data = new Date()) => {
  return moment(data).format(`DD MMMM YYYY เวลา HH:mm น.`);
};
export const generateIndexesForRow = (
  rowIndex,
  maxItemsPerRow,
  itemsAmount
) => {
  const result = [];
  const startIndex = rowIndex * maxItemsPerRow;
  for (
    let i = startIndex;
    i < Math.min(startIndex + maxItemsPerRow, itemsAmount);
    i++
  ) {
    result.push(i);
  }
  return result;
};
export const getMaxItemsAmountPerRow = (width, ITEM_WIDTH) => {
  return Math.max(Math.floor(width / ITEM_WIDTH), 1);
};
export const getRowsAmount = (width, itemsAmount, hasMore, ITEM_WIDTH) => {
  const maxItemsPerRow = getMaxItemsAmountPerRow(width, ITEM_WIDTH);
  return Math.ceil(itemsAmount / maxItemsPerRow) + (hasMore ? 1 : 0);
};
export const checkTrue = (bool) => {
  if (bool === true || bool === "true" || bool === "1") return true;
  return false;
};
export const clientLoadImage = (qrCode, resultName = "image.png") => {
  // message.warning(`${browserName}`);
  // message.warning(`${isMobile}`);
  if (
    isIOS &&
    (browserName === "WebKit" ||
      browserName === "Chrome" ||
      browserName === "Line")
  ) {
    message.warning("กรุณาเปิดด้วยBrowserอื่นหรือCaptureหน้าจอเพื่อชำระเงิน");
  } else {
    let downloadLink = document.createElement("a");
    downloadLink.href = qrCode;
    downloadLink.download = `${resultName}.png`;
    // downloadLink.target = "_blank";
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
  }

  // export const clientLoadImage = (id, resultName = "image.png") => {
  //   message.warning(`${browserName}`);
  //   message.warning(`${isMobile}`);
  //   var node = document.getElementById(id);
  //   if (isMobile) {
  //     message.warning(
  //       "กรุณาเปิดด้วย browser อื่น หรือ Capture หน้าจอเพื่อชำระเงิน"
  //     );
  //     // const pngUrl = node
  //     //   .toDataURL("image/png")
  //     //   .replace("image/png", "image/octet-stream");
  //     let downloadLink = document.createElement("a");
  //     document.body.appendChild(downloadLink);
  //     downloadLink.href =
  //       "http://chart.apis.google.com/chart?cht=qr&chs=300x300&chl=qr&chld=H|0";
  //     downloadLink.download = `${resultName}.jpg`;
  //     downloadLink.target = "_blank";
  //     downloadLink.click();
  //     document.body.removeChild(downloadLink);
  //   } else {
  //     const pngUrl = node
  //       .toDataURL("image/png")
  //       .replace("image/png", "image/octet-stream");
  //     let downloadLink = document.createElement("a");
  //     downloadLink.href = pngUrl;
  //     downloadLink.download = `${resultName}.png`;
  //     document.body.appendChild(downloadLink);
  //     downloadLink.click();
  //     document.body.removeChild(downloadLink);
  //   }
  // if (
  //   browserName === "Chrome" ||
  //   browserName === "Firefox" ||
  //   browserName === "Mobile Safari" ||
  //   browserName === "Opera" ||
  //   browserName === "Edge"
  // ) {
  //   var node = document.getElementById(id);
  //   const pngUrl = node
  //     .toDataURL("image/png")
  //     .replace("image/png", "image/octet-stream");
  //   let downloadLink = document.createElement("a");
  //   downloadLink.href = pngUrl;
  //   downloadLink.download = resultName;
  //   document.body.appendChild(downloadLink);
  //   downloadLink.click();
  //   document.body.removeChild(downloadLink);
  // } else {
  //   message.error(
  //     "กรุณาเปิดด้วย browser อื่น หรือ Capture หน้าจอเพื่อชำระเงิน"
  //   );
  // }
};
export const randomOtp = () => {
  return Math.floor(1000 + Math.random() * 9000);
};
export const randomRef = () => {
  return `${Math.random().toString(36).substring(9)}${randomOtp()}`;
};
export const getBreakpoint = (width = 1000) => {
  if (width >= 1600) {
    return "xxl";
  } else if (width >= 1200) {
    return "xl";
  } else if (width >= 992) {
    return "lg";
  } else if (width >= 768) {
    return "md";
  } else if (width >= 576) {
    return "sm";
  } else if (width < 576) {
    return "xs";
  }
};
