import React, { Component } from "react";
import { Link } from "react-router-dom";
import {
  Form,
  Input,
  Icon,
  Row,
  Col,
  Button,
  Avatar,
  message,
  Radio,
  Tooltip,
  Modal,
  Result,
} from "antd";
import { connect } from "react-redux";
import moment from "moment";
import "moment/locale/th";
import { BASE_URL } from "../config";

import PoweredByGebtam from "../../src/components/gebtam/PoweredByGebtam";
import { logoutToPath, updateProfileUserEvent } from "../store/actions/auth";
import AuthService from "../services/AuthService";
import ShopService from "../services/ShopService";

moment.locale("th");

class UpdateProfileEvent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      errors: {},
      isLoading: false,
      confirmDirty: false,
      autoCompleteResult: [],
      visible: false,
      textModal: "",
      shopName: "",
      shopImg: "/gebtam.png",
      member: {
        m_tel_number: "",
        m_gender: "2",
        m_name: "",
        m_lastname: "",
        event_code: "",
      },
    };
  }

  componentDidMount() {
    const param = new URLSearchParams(this.props.history.location.search);
    const tel = param.get("tel");
    const sid = param.get("sid");
    this.checkShopDetail();
    if (
      this.props.user.isAuthenticated ||
      localStorage.getItem("m_tel_number")
    ) {
      this.checkUserDetails(tel, sid);
    } else {
      message.error("ไม่พบข้อมูล กรุณาสแกน QR Code อีกครั้ง");
      // const path = param.get("path") || `/`;
      // this.props.history.push(`${path}`);
    }
  }

  componentWillReceiveProps(nextProps) {
    if (
      nextProps.errors.registerError &&
      nextProps.errors.isRegister !== this.props.errors.isRegister
    ) {
      this.setState({
        isLoading: false,
      });
      message.warning(nextProps.errors.registerError);
    }
  }

  // call api CheckShopDetail
  checkShopDetail = async () => {
    const param = new URLSearchParams(this.props.history.location.search);
    const sid = param.get("sid");
    const tel = param.get("tel");
    await ShopService.getCheckShopDetail({
      sid: sid,
      m_tel_number: tel,
    })
      .then((res) => {
        if (res.success) {
          this.setState({
            shopName: res.result.shop_name,
            shopImg: BASE_URL + res.result.path_shop_logo + res.result.head_pic,
          });
        } else {
          this.setState({
            shopName: "ไม่พบร้านค้า",
            isFound: false,
            shopImg: "/gebtam.png",
            isLoading: false,
          });
          message.error("ไม่พบร้านค้า");
        }
      })
      .catch((error) => {
        this.setState({ isLoading: false });
        console.log(error);
        message.error("เกิดข้อผิดพลาด");
      });
  };

  checkUserDetails = async (tel, sid) => {
    const param = new URLSearchParams(this.props.history.location.search);
    await ShopService.getIn({ m_tel_number: tel, sid: sid }).then((user) => {
      if (user.result) {
        if (user.result.event_code === "DRT") {
          this.setState({
            visible: true,
            textModal: "ท่านได้ลงทะเบียนเรียบร้อยแล้ว",
          });
        }
        this.setState({
          member: {
            ...this.state.member,
            m_tel_number: user.result.m_tel_number,
            m_name: user.result.m_name,
            m_gender: user.result.m_gender,
            m_lastname: user.result.m_lastname,
          },
        });
      } else {
        const path = param.get("path") || "/";
        this.props.history.push(`${path}`);
        message.warning("ไม่พบข้อมูล");
      }
    });
  };

  validateToNextPassword = (rule, value, callback) => {
    const { form } = this.props;
    if (value && this.state.confirmDirty) {
      form.validateFields(["confirm"], { force: true });
    }
    callback();
  };

  logout = () => {
    const param = new URLSearchParams(this.props.history.location.search);
    const sid = param.get("sid");
    if (localStorage.getItem("m_tel_number")) {
      this.props.history.push(`/loginEvent?sid=${sid}`);
      localStorage.removeItem("m_tel_number");
    }
    if (this.props.user.isAuthenticated) {
      this.props.logoutToPath(this.props.history, `/login?sid=${sid}`);
    }
  };

  handleSubmit = (e) => {
    e.preventDefault();
    this.props.form.validateFieldsAndScroll((err, values) => {
      this.setState({ isLoading: true });
      if (!err) {
        const newValues = { ...values };
        newValues.event_code = "DRT";
        newValues.fromWeb = true;
        this.props.updateProfileUserEvent(newValues, this.props.history);
        this.setState({
          isLoading: false,
          visible: true,
          textModal: "ท่านได้ลงทะเบียนเรียบร้อย",
        });
      } else {
        console.log("err :>> ", err);
        this.setState({
          isLoading: false,
          visible: true,
          textModal: "กรุณากรอกข้อมูลให้ครบ",
        });
      }
    });
  };

  render() {
    const { getFieldDecorator } = this.props.form;
    const formItemLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 8 },
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 16 },
      },
    };

    const { member, visible, textModal, shopImg, shopName } = this.state;
    return (
      <div className="login-page">
        <Form
          {...formItemLayout}
          onSubmit={this.handleSubmit}
          className="regis-form2 font-sub"
        >
          <div
            className="font-main text-left size-15"
            style={{ padding: 10, margin: -10, cursor: "pointer" }}
            onClick={this.logout}
          >
            กลับ
          </div>
          <div type="flex" justify="center" className="text-center mb-3">
            <Tooltip placement="bottom">
              <Avatar shape="square" size={64} src={shopImg} />
            </Tooltip>
            <div className="text-center">
              <span className="font-main size-15 c-main">{shopName}</span>
            </div>
          </div>
          <Form.Item label="โทรศัพท์">
            {getFieldDecorator("m_tel_number", {
              initialValue: member.m_tel_number,
              rules: [
                { required: true, message: "ระบุหมายเลขโทรศัพท์" },
                { len: 10, message: "หมายเลขโทรศัพท์ไม่ถูกต้อง" },
              ],
            })(
              <Input
                prefix={
                  <Icon type="phone" style={{ color: "rgba(0,0,0,.25)" }} />
                }
                placeholder="Phone"
                disabled
              />
            )}
          </Form.Item>
          <Form.Item label="ชื่อ">
            {getFieldDecorator("m_name", {
              initialValue: member.m_name,
              rules: [
                {
                  required: true,
                  message: "กรอกชื่อ",
                },
              ],
            })(<Input placeholder="ชื่อ" />)}
          </Form.Item>{" "}
          <Form.Item label="นามสกุล">
            {getFieldDecorator("m_lastname", {
              initialValue: member.m_lastname,
              rules: [
                {
                  required: true,
                  message: "กรอกนามสกุล",
                },
              ],
            })(<Input placeholder="นามสกุล" />)}
          </Form.Item>
          <Form.Item label="เพศ">
            {getFieldDecorator("m_gender", {
              initialValue: member.m_gender,
            })(
              <Radio.Group>
                <Radio value={0}>ชาย</Radio>
                <Radio value={1}>หญิง</Radio>
                <Radio value={2}>ไม่ระบุ</Radio>
              </Radio.Group>
            )}
          </Form.Item>
          <Row>
            <Col span={24}>
              <Button
                type="primary"
                htmlType="submit"
                block
                shape="round"
                className="font-main size-15"
                loading={this.state.isLoading}
              >
                ลงทะเบียน
              </Button>
            </Col>
            <Modal
              width="20rem"
              centered={true}
              visible={visible}
              footer={null}
              onCancel={() => {
                this.setState({
                  visible: false,
                });
              }}
            >
              <Result
                style={{ padding: 0, align: "center" }}
                status={
                  textModal === "ท่านได้ลงทะเบียนเรียบร้อย" ||
                  textModal === "ท่านได้ลงทะเบียนเรียบร้อยแล้ว"
                    ? "success"
                    : "warning"
                }
                title={textModal}
                extra={[
                  <Button
                    key={0}
                    shape="round"
                    className="font-main size-15"
                    onClick={() => {
                      textModal === "ท่านได้ลงทะเบียนเรียบร้อย"
                        ? window.location.reload()
                        : this.setState({
                            visible: false,
                          });
                    }}
                  >
                    ตกลง
                  </Button>,
                ]}
              />
              ,
            </Modal>
          </Row>
          <br />
          <PoweredByGebtam />
        </Form>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.user,
  errors: state.errors,
});

const UpdateProfileEventForm = Form.create({ name: "updateProfileEvent" })(
  UpdateProfileEvent
);

export default connect(mapStateToProps, {
  updateProfileUserEvent,
  logoutToPath,
})(UpdateProfileEventForm);
