import React, { Component } from "react";
import { Switch, Route, Redirect, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import firebase from "firebase";
import { Spin } from "antd";
import NProgress from "nprogress";

import "nprogress/nprogress.css";

import { getProfile } from "./store/actions/user";
import { setIsPaymentAT, setPaymentVisibled } from "./store/actions/product";
import PrivateRoute from "./router/PrivateRoute";
import { AppPath } from "./router/path";
// import PaymentService from './services/PaymentService';
import RegisterForm from "./pages/Register";
import LoginForm from "./pages/Login";
import LoginEventForm from "./pages/LoginEvent";
import CheckinForm from "./pages/Checkin";
import ApplicationForm from "./pages/Application";
import ManageSMS from "./pages/ManageSMS";
import Home from "./pages/Home";
// import ProductList from './pages/ProductList';
// import ProductId from './pages/ProductId';
// import ProductHistory from "./pages/ProductHistory";
import FogotForm from "./pages/Forgot";
import ShopId from "./pages/ShopId";
import Mycard from "./pages/Mycard";
import Verify from "./pages/Verify";
import VerifyV2 from "./pages/VerifyV2";
import Profile from "./pages/Profile";
import Feed from "./pages/feed/Feed";
import UpdateProfile from "./pages/UpdateProfile";
import UpdateProfileEvent from "./pages/UpdateProfileEvent";
import Order from "./pages/feed/order";
import FancyRoute from "./router/FancyRoute";
// import Shops from './pages/Shops';
import OrderHistory from "./pages/OrderHistory";
import OrderHistoryProvider from "./context/OrderHistoryContext";
import Shared from "./pages/Shared";
import Promotion from "./pages/Promotion";
NProgress.configure({ easing: "ease", speed: 700 });
class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      isFirstGet: true,
    };
    const config = {
      apiKey: "AIzaSyBRqG71RNsEmZY-Ie_POEGsrKbDC8tWKGs",
      authDomain: "gebtam-42aa5.firebaseapp.com",
      databaseURL: "https://gebtam-42aa5.firebaseio.com",
      projectId: "gebtam-42aa5",
      storageBucket: "gebtam-42aa5.appspot.com",
      messagingSenderId: "476730313236",
      appId: "1:476730313236:web:91c8baa1ed244922",
    };
    firebase.initializeApp(config);
    this.props.history.listen((location, action) => {
      NProgress.set(0.8);
      // console.log("on route change", location, action);
    });
  }

  componentDidMount() {
    const pathname = this.props.history.location.pathname;
    switch (pathname) {
      case AppPath.app:
        this.setState({ isLoading: false });
        break;
      case AppPath.checkin:
        this.setState({ isLoading: false });
        break;
      default:
        this.props.getProfile(this.props.history).finally(() => {
          this.setState({ isLoading: false });
        });
        break;
    }
  }

  componentWillReceiveProps(nextProps) {
    const nextUser = nextProps.user;
    const { user } = this.props;
    if (
      nextUser.isAuthenticated &&
      user.isAuthenticated !== nextUser.isAuthenticated
    ) {
      // const mid = nextUser.profile.mid;
      // PaymentService.getPayment(mid).on("value", (snapshot) => {
      //   if (snapshot.val()) {
      //     // console.log(snapshot.val());
      //     if (this.state.isFirstGet) {
      //       this.setState({ isFirstGet: false });
      //     } else {
      //       this.props.setIsPaymentAT(false);
      //       this.props.setPaymentVisibled(false);
      //       // const { history } = this.props;
      //       // const value = Object.values(snapshot.val()).slice(-1)[0];
      //       // const query = new URLSearchParams(history.location.search);
      //       // const orderId = query.get("pay");
      //       // if (value.order_id === orderId) {
      //       //   notification.success({
      //       //     message: (
      //       //       <span className="size-12 bold">ชำระเงินเรียบร้อย</span>
      //       //     ),
      //       //     description: (
      //       //       <span className="size-11">คุณได้ชำระเงินเรียบร้อยแล้ว</span>
      //       //     ),
      //       //     placement: "bottomRight",
      //       //     duration: 6,
      //       //   });
      //       //   this.gotoHistory();
      //       // } else {
      //       //   notification.success({
      //       //     message: (
      //       //       <span className="size-12 bold">ชำระเงินเรียบร้อย</span>
      //       //     ),
      //       //     description: (
      //       //       <span className="size-11">
      //       //         คุณได้ชำระเงินเรียบร้อยแล้ว
      //       //         <span
      //       //           className="c-main ml-2 pointer under-hover"
      //       //           onClick={this.gotoHistory.bind(this)}
      //       //         >
      //       //           ไปยังหน้าประวัติสินค้า
      //       //         </span>
      //       //       </span>
      //       //     ),
      //       //     placement: "bottomRight",
      //       //     duration: 6,
      //       //   });
      //       // }
      //     }
      //   } else {
      //     this.setState({ isFirstGet: false });
      //   }
      // });
    }
  }

  componentWillUnmount() {
    const { user } = this.props;
    const mid = user.profile.mid;
    // PaymentService.getPayment(mid).off();
  }

  gotoHistory() {
    const { history } = this.props;
    history.push(AppPath.producthistory + "?tabs=order");
  }

  render() {
    if (this.state && this.state.isLoading === false) {
      return (
        <div>
          {/* <BackTop>
            <div className="ant-back-top"><div className="ant-back-top-content"><div className="ant-back-top-icon"></div></div></div>
          </BackTop> */}
          <Switch>
            <Route exact path="/">
              <Redirect to={AppPath.home}></Redirect>
            </Route>
            <FancyRoute path={AppPath.login} component={LoginForm} />
            <FancyRoute path={AppPath.app} component={ApplicationForm} />
            <FancyRoute path={AppPath.loginEvent} component={LoginEventForm} />
            <FancyRoute
              path={`${AppPath.updateProfile}`}
              component={UpdateProfile}
            />
            <FancyRoute
              path={`${AppPath.updateProfileEvent}`}
              component={UpdateProfileEvent}
            />
            <FancyRoute path={AppPath.checkin} component={CheckinForm} />
            {/* // TODO:  Main Path =>> QRCode */}
            <FancyRoute path={AppPath.qrevent}>
              <Redirect to={`${AppPath.checkin}?sid=3760001`}></Redirect>
            </FancyRoute>
            <FancyRoute path={`${AppPath.sms}/:id`} component={ManageSMS} />
            <FancyRoute path={`${AppPath.sms}`} component={ManageSMS} />
            <FancyRoute path={AppPath.register} component={RegisterForm} />
            <FancyRoute path={AppPath.forgot} component={FogotForm} />
            <FancyRoute path={AppPath.home} component={Home} />
            {/* <FancyRoute path={`${AppPath.product}/:id`} component={ProductId} /> */}
            {/* <FancyRoute path={AppPath.product} component={ProductList} /> */}
            <FancyRoute path={AppPath.verify} component={Verify} />
            <FancyRoute path={AppPath.aui} component={VerifyV2} />
            <FancyRoute
              path={`${AppPath.shared}${AppPath.feed}/:id`}
              component={Shared}
            />
            <PrivateRoute path={AppPath.profile} component={Profile} />
            <PrivateRoute path={`${AppPath.shop}/:id`} component={ShopId} />
            <PrivateRoute path={AppPath.mycard} component={Mycard} />
            {/* <PrivateRoute path={AppPath.shop} component={Shops} /> */}
            <PrivateRoute
              path={`${AppPath.feed}/:id${AppPath.order}`}
              component={Order}
            />
            <PrivateRoute path={AppPath.promotion} component={Promotion} />
            <PrivateRoute path={`${AppPath.feed}/:id`} component={Feed} />
            <OrderHistoryProvider>
              <PrivateRoute
                path={AppPath.orderHistory}
                component={OrderHistory}
              />
            </OrderHistoryProvider>
          </Switch>
        </div>
      );
    } else {
      return (
        <Spin tip="Loading...">
          <div style={{ minHeight: "100vh" }}></div>
        </Spin>
      );
    }
  }
}

const mapStateToProps = (state) => ({
  user: state.user,
  products: state.products,
});

export default connect(mapStateToProps, {
  getProfile,
  setIsPaymentAT,
  setPaymentVisibled,
})(withRouter(App));
