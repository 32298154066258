import React, { useState } from "react";
import VerifyForm from "../components/verify/VerifyForm";
import VerifyRegis from "../components/verify/VerifyRegis";

export default function Verify() {
  const [isVerify, setIsVerify] = useState(false);
  const [telNumber, setTelNumber] = useState("");
  const [code, setCode] = useState("");

  if (isVerify) {
    return (
      <VerifyRegis
        m_tel_number={telNumber}
        setIsVerify={setIsVerify}
        setTelNumber={setTelNumber}
        code={code}
      />
    );
  } else {
    return (
      <VerifyForm
        setIsVerify={setIsVerify}
        setTelNumber={setTelNumber}
        setCode={setCode}
      />
    );
  }
}
