import {
  SET_CURRENT_USER,
  GET_CURRENT_USER,
  REGISTER_SUCCESS,
  LOGOUT,
  GET_ERRORS,
  GET_SHOPS,
  SET_SHOPS,
  SET_SHOP_PRODUCTS,
  GET_PRODUCTS_PENDING,
  GET_PRODUCTS_SUCCESS,
  GET_PRODUCTS_ERROR,
  GET_ALL_PRODUCTS_SUCCESS,
  GET_SHOPS_PENDING,
  GET_SHOPS_SUCCESS,
  GET_ALL_SHOPS_SUCCESS,
  GET_SHOPS_ERROR,
  UPDATE_SHOP_FOLLOW,
  SET_SHOP_DETAIL,
  GET_ALL_FEEDS_SUCCESS,
  GET_FEEDS_PENDING,
  GET_FEEDS_SUCCESS,
  GET_FEEDS_ERROR,
  GET_ALL_FEEDS_NEAR_SUCCESS,
  GET_FEEDS_NEAR_PENDING,
  GET_FEEDS_NEAR_SUCCESS,
  GET_FEEDS_NEAR_ERROR,
  GET_SHOPS_FOLLOW_PENDING,
  GET_SHOPS_FOLLOW_SUCCESS,
  GET_ALL_SHOPS_FOLLOW_SUCCESS,
  GET_SHOPS_FOLLOW_ERROR,
  CLEAR_DATA,
  SET_SHOPS_POINT,
  SET_PAYMENT_VISIBLE,
  SET_IS_PAYMENT,
  SET_FEEDS_NEAR,
  SET_FEEDS_ALL,
  SET_ORGANIS,
} from "./types";

// รวม action ต่างๆ
export function setCurrentUser(user) {
  return {
    type: SET_CURRENT_USER,
    payload: user,
  };
}

export function setOrganis(organis) {
  return {
    type: SET_ORGANIS,
    payload: organis,
  };
}

export function getCurrentUser() {
  return {
    type: GET_CURRENT_USER,
  };
}

export function registerSuccess() {
  return {
    type: REGISTER_SUCCESS,
  };
}

export function logoutUser() {
  return {
    type: LOGOUT,
  };
}

export function clearData() {
  return {
    type: CLEAR_DATA,
  };
}

export function getErrors(errors) {
  return {
    type: GET_ERRORS,
    payload: errors,
  };
}

export function getShops() {
  return {
    type: GET_SHOPS,
  };
}

export function setShops(shops) {
  return {
    type: SET_SHOPS,
    payload: shops,
  };
}

//ส่วนของ product
export function getProductsPending() {
  return {
    type: GET_PRODUCTS_PENDING,
  };
}

export function getProductsSuccess(products) {
  return {
    type: GET_PRODUCTS_SUCCESS,
    payload: products,
  };
}

export function getAllProductsSuccess(products) {
  return {
    type: GET_ALL_PRODUCTS_SUCCESS,
    payload: products,
  };
}

export function getProductsError(error) {
  return {
    type: GET_PRODUCTS_ERROR,
    error: error,
  };
}

export function setShopProducts(shopProducts) {
  return {
    type: SET_SHOP_PRODUCTS,
    payload: shopProducts,
  };
}

export function setShopPoint(shopPoint) {
  return {
    type: SET_SHOPS_POINT,
    payload: shopPoint,
  };
}
//สิ้นสุด product

//ส่วนของ shop
export function getShopsPending() {
  return {
    type: GET_SHOPS_PENDING,
  };
}

export function getShopsSuccess(products) {
  return {
    type: GET_SHOPS_SUCCESS,
    payload: products,
  };
}

export function getAllShopsSuccess(products) {
  return {
    type: GET_ALL_SHOPS_SUCCESS,
    payload: products,
  };
}

export function updateShopFollow(shopFollower) {
  return {
    type: UPDATE_SHOP_FOLLOW,
    payload: shopFollower,
  };
}

export function setShopDetail(details) {
  return {
    type: SET_SHOP_DETAIL,
    payload: details,
  };
}

export function getShopsError(error) {
  return {
    type: GET_SHOPS_ERROR,
    error: error,
  };
}

export function getShopsFollowPending() {
  return {
    type: GET_SHOPS_FOLLOW_PENDING,
  };
}

export function getShopsFollowSuccess(products) {
  return {
    type: GET_SHOPS_FOLLOW_SUCCESS,
    payload: products,
  };
}

export function getAllShopsFollowSuccess(products) {
  return {
    type: GET_ALL_SHOPS_FOLLOW_SUCCESS,
    payload: products,
  };
}

export function getShopsFollowError(error) {
  return {
    type: GET_SHOPS_FOLLOW_ERROR,
    error: error,
  };
}
//สิ้นสุด shop

//ส่วนของ feed
export function getFeedsPending() {
  return {
    type: GET_FEEDS_PENDING,
  };
}

export function getFeedsSuccess(feeds) {
  return {
    type: GET_FEEDS_SUCCESS,
    payload: feeds,
  };
}

export function getAllFeedsSuccess(feeds) {
  return {
    type: GET_ALL_FEEDS_SUCCESS,
    payload: feeds,
  };
}

export function getFeedsError(error) {
  return {
    type: GET_FEEDS_ERROR,
    error,
  };
}

export function getFeedsNearPending() {
  return {
    type: GET_FEEDS_NEAR_PENDING,
  };
}

export function getFeedsNearSuccess(feeds) {
  return {
    type: GET_FEEDS_NEAR_SUCCESS,
    payload: feeds,
  };
}

export function getAllFeedsNearSuccess(feeds) {
  return {
    type: GET_ALL_FEEDS_NEAR_SUCCESS,
    payload: feeds,
  };
}

export function getFeedsNearError(error) {
  return {
    type: GET_FEEDS_NEAR_ERROR,
    error,
  };
}

export function setPaymentVisible(visible) {
  return {
    type: SET_PAYMENT_VISIBLE,
    payload: visible,
  };
}

export function setIsPayment(isPayment) {
  return {
    type: SET_IS_PAYMENT,
    payload: isPayment,
  };
}

export function setFeedsNear(dataNear) {
  return {
    type: SET_FEEDS_NEAR,
    payload: dataNear,
  };
}

export function setFeedsAll(data) {
  return {
    type: SET_FEEDS_ALL,
    payload: data,
  };
}
