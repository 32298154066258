import React from "react";
import { Tag } from "antd";
import ContactShop from "../ContactShop";

export default function PendingShopReceive({ contact, orderId }) {
  return (
    <>
      <ContactShop contact={contact} />
      <Tag color="#7da832" className="font-sub ml-2">
        รอร้านค้ารับออเดอร์
      </Tag>
    </>
  );
}
