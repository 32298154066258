import React, { useState } from "react";
import { Row, Avatar } from "antd";
import { Link } from "react-router-dom";

import { CardRatio, CardItem } from "../../../styles/components/card";
import FeedShopLogo from "./FeedShopLogo";
import ShopService from "../../../services/ShopService";
import { AppPath } from "../../../router/path";
import FeedOrderBtn from "./FeedOrderBtn";

export default function FeedCardImage({
  feed,
  pathShopLogo,
  shopName,
  isNotResize,
  isNotShowOrderBtn,
}) {
  const imagePath = ShopService.getLogoHasPath(
    feed.path_feed_pic,
    feed.feed_pic
  );
  const [imageFeed, setImageFeed] = useState(imagePath);

  return (
    <Link to={`${AppPath.feed}/${feed.feed_node}`} className="w-100">
      <CardRatio pdTop="100%">
        <Avatar
          src={imageFeed}
          alt="image feed"
          className="ab w-100 h-100 t-0"
          style={{ width: "100%" }}
          shape="square"
          onError={() => setImageFeed("/gebtam.png")}
        />
        <CardItem>
          <Row className="pt-3 text-center flex-column h-100 w-100 rela">
            <FeedShopLogo
              isNotResize={isNotResize}
              pathLogo={pathShopLogo}
              shopName={shopName}
              shopId={feed.sid}
            />
            {feed.price && !isNotShowOrderBtn && (
              <FeedOrderBtn
                path={`${AppPath.feed}/${feed.feed_node}${AppPath.order}`}
              />
            )}
            {/* {!isNotShowOrderBtn && (
              <FeedOrderBtn
                path={`${AppPath.feed}/${feed.feed_node}${AppPath.order}`}
              />
            )} */}
          </Row>
        </CardItem>
      </CardRatio>
    </Link>
  );
}
