import React, { useState } from "react";
import { connect } from "react-redux";
import { Row, Col, Progress, message, Modal, Result, Button, Spin } from "antd";

import {
  CardPromotion,
  Center,
  ImgCover,
  LineClamp2,
} from "../../styles/components/style";
import AuthService from "../../services/AuthService";
import ShopService from "../../services/ShopService";
import { setStyleFormatDateFullYear } from "../../helpers/util";
import PromotionService from "../../services/PromotionService";
import QRCode from "qrcode.react";
import moment from "moment";

const ShopPromotion = (props) => {
  const [visible, setVisible] = useState(false);
  const [qrcode, setQRCode] = useState(false);
  const [secondsToGo, setSecondsToGo] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const { promotion, detail, sid, history } = props;

  const promotionPic = () => {
    if (promotion.pro_pic) {
      return ShopService.getLogoHasPath(
        promotion.path_pro_pic,
        promotion.pro_pic
      );
    } else {
      return "/logo.png";
    }
  };

  const checkUsePro = () => {
    const myPoint = parseInt(detail[sid].member_stamp);
    const proAmount = parseInt(promotion.pro_amount);
    const limit_time_per_client = parseInt(promotion.limit_time_per_client);
    const client_pro_used_time = parseInt(promotion.client_pro_used_time);
    const limit_time = parseInt(promotion.limit_time);
    const used_time = parseInt(promotion.used_time);
    const limit_client = parseInt(promotion.limit_client || 0);
    const used_client = parseInt(promotion.used_client || 0);
    const limit_round_type = promotion.limit_round_type;
    const limit_time_per_round = parseInt(promotion.limit_time_per_round);
    const client_pro_can_use = promotion.client_pro_can_use;
    const begindate = setStyleFormatDateFullYear(promotion.begindate);
    const enddate = setStyleFormatDateFullYear(promotion.enddate);
    const now = Date.now();
    if (
      promotion.begindate &&
      now < new Date(begindate).getTime() &&
      now > new Date(enddate).getTime()
    ) {
      return (
        <div className="b-216 size-12 font-main c-w pd-2 text-center pointer">
          ไม่อยู่ในช่วงเวลาใช้
        </div>
      );
    }
    if (
      (limit_time_per_client !== 0 &&
        client_pro_used_time >= limit_time_per_client) ||
      (limit_time !== 0 && used_time >= limit_time) ||
      (limit_client !== 0 && used_client >= limit_time) ||
      (limit_round_type === "0" && client_pro_can_use >= limit_time_per_round)
    ) {
      return (
        <div
          className="b-216 size-10 font-main c-w pd-2 text-center pointer"
          onClick={usePromotion}
        >
          สิทธิ์เต็มแล้ว
        </div>
      );
    } else if (myPoint < proAmount) {
      return (
        <div
          className="b-main bold size-10 font-main c-w pd-2 text-center pointer"
          onClick={() =>
            history.push(`${history.location.pathname}?tabs=point`)
          }
        >
          ดูแต้ม
        </div>
      );
    } else {
      return (
        <div
          className="b-sub bold size-10 font-main c-b pd-2 text-center pointer"
          onClick={usePromotion}
        >
          ใช้สิทธิ์
        </div>
      );
    }
  };

  const usePromotion = () => {
    setVisible(true);
    setIsLoading(true);
    handlePromotion();
  };

  const handlePromotion = async () => {
    const prod_id = promotion.pro_id;
    const act = JSON.parse(AuthService.getToken());

    const postData = {
      access_token: act.access_token,
      pro_id: prod_id,
    };
    try {
      const resp = await PromotionService.genBubblePro(postData);
      if (resp) {
        // var secondsToGo = resp.result.life_time * 60;
        // const timer = setInterval(() => {
        //   secondsToGo -= 1;
        //   setSecondsToGo(secondsToGo);
        // }, 1000);
        // setTimeout(() => {
        //   clearInterval(timer);
        //   setVisible(false);
        // }, secondsToGo * 1000);

        setQRCode(resp.result.use_code);
        // setTimeout(() => {
        setIsLoading(false);
        // }, 10000);
      }
    } catch (error) {
      console.log(error);
      message.error("เกิดข้อผิดพลาด");
    }
  };

  const getPercent = () =>
    (parseInt(detail[sid].member_stamp) / parseInt(promotion.pro_amount)) * 100;

  return (
    <CardPromotion className="mx-auto mb-3">
      <Modal
        width="20rem"
        centered={true}
        visible={visible}
        footer={null}
        onCancel={() => {
          setVisible(false);
        }}
      >
        {" "}
        {/* <p>{qrcode}</p> */}
        <Spin spinning={isLoading}>
          {" "}
          <div
            className="text-center mb-3"
            style={{
              // borderRadius: "2rem",
              // border: "1px solid rgb(212, 212, 212)",
              // boxShadow: "0px 0px 3px 0px rgb(212, 212, 212)",
              paddingTop: "1rem",
              fontSize: "20px",
              fontWeight: "bold",
              // paddingBottom: "1rem",
              color: "#e01f26",
            }}
          >
            <p align="center">QR Code</p>{" "}
          </div>
          <div
            className="font-main text-center size-20"
            style={{ align: "center" }}
          >
            <QRCode value={qrcode} size={250} id="order_qrcode" />
          </div>
          <div
            className="text-center mb-3"
            style={{
              // borderRadius: "2rem",
              // border: "1px solid rgb(212, 212, 212)",
              // boxShadow: "0px 0px 3px 0px rgb(212, 212, 212)",
              paddingTop: "1rem",
              fontSize: "20px",
              fontWeight: "bold",
              // paddingBottom: "1rem",
            }}
          >
            <p align="center">Code : {qrcode}</p>{" "}
          </div>
          <div
            className="font-main text-center size-20"
            style={{ align: "center" }}
          >
            {" "}
            <Button
              shape="round"
              style={{ height: "auto", padding: "0 20px", align: "center" }}
              className="font-main text-center size-20"
              onClick={() => {
                setVisible(false);
              }}
            >
              {/* {moment.utc(secondsToGo * 1000).format("mm:ss")} */}
              ปิด
            </Button>{" "}
          </div>
        </Spin>
      </Modal>
      <Row type="flex">
        <Col style={{ width: 120, height: 130 }}>
          {/* <Avatar src={promotionPic()} shape="square" className="radius-0" size={180} /> */}
          <ImgCover src={promotionPic()}></ImgCover>
        </Col>
        <Col style={{ width: 119, height: 130 }} className="d-flex ">
          <Center className="pd-2">
            <LineClamp2 className="mb-0" title={promotion.pro_text1}>
              {" "}
              {promotion.pro_text1}
            </LineClamp2>
            <span className="c-main size-10 break-all">
              {promotion.promotion_name}
            </span>
          </Center>
        </Col>
      </Row>
      <Row type="flex">
        <Col
          style={{ width: 120, height: 40 }}
          className="d-flex align-auto text-center"
        >
          <Progress
            type="line"
            strokeColor="#f03975"
            percent={getPercent()}
            showInfo={false}
          />
          <span className="bold"> x {promotion.pro_amount}</span>
        </Col>
        <Col style={{ width: 119, height: 40 }}>{checkUsePro()}</Col>
      </Row>
    </CardPromotion>
  );
};

const mapStateToProps = (state) => ({
  detail: state.shops.dataDetail,
});

export default connect(mapStateToProps)(ShopPromotion);
