import { combineReducers } from "redux";
import { CLEAR_DATA } from "../actions/types";
import user from "./user";
import organis from "./organis";
import errors from "./errors";
import shops from "./shop";
import products from "./product";
import feeds from "./feed";

export const initialState = {
  user: {
    isAuthenticated: false,
    profile: {},
  },
  organis: [],
  errors: {},
  shops: {
    pending: false,
    hasMore: true,
    hasMoreFollow: true,
    data: {},
    dataDetail: {},
    dataFollow: {},
    recordsTotal: 0,
    error: null,
  },
  products: {
    pending: false,
    hasMore: true,
    data: {},
    error: null,
    isPayment: false,
    visible: false,
  },
  feeds: {
    pending: false,
    hasMore: true,
    hasMoreNear: true,
    dataNear: {},
    data: {},
    error: null,
    lat: null,
    long: null,
  },
};

const appReducer = combineReducers({
  user,
  organis,
  errors,
  shops,
  products,
  feeds,
});
const rootReducer = (state, action) => {
  if (action.type === CLEAR_DATA) {
    state = initialState;
  }

  return appReducer(state, action);
};

export default rootReducer;
