import React, { useEffect, useState } from "react";
import { useParams, Link, useLocation, useHistory } from "react-router-dom";
import { message, Spin, Result, Button } from "antd";
import { connect } from "react-redux";
import MetaTags from "react-meta-tags";

import FeedService from "../../services/FeedService";
import FeedCardView from "./feed-card/FeedCardView";
import { AppPath } from "../../router/path";
import AuthService from "../../services/AuthService";
import ShopService from "../../services/ShopService";

function FeedShared({ user }) {
  const [feed, setFeed] = useState({});
  const [isLoading, setLoading] = useState(true);
  const location = useLocation();
  const history = useHistory();
  const params = useParams();

  useEffect(() => {
    const { profile } = user;
    const fetchFeedShared = async () => {
      const postData = {
        top_node: params.id,
      };
      const act = JSON.parse(AuthService.getToken());
      if (act) {
        postData.access_token = act.access_token;
      }
      try {
        let resp = {};
        if (profile.mid) {
          resp = await FeedService.getFeed(postData);
        } else {
          resp = await FeedService.getFeedShared(postData);
        }
        if (resp.success) {
          setFeed(resp.top_result || {});
        } else {
          message.error("ไม่สามารถดูข้อมูลได้");
        }
        console.log(resp);
      } catch (error) {
        console.log(error);
        message.error("ไม่สามารถดูข้อมูลได้");
      }
      setLoading(false);
    };
    fetchFeedShared();
  }, [params.id, user]);

  const goLogin = () => {
    history.push({
      pathname: AppPath.login,
      search: `?path=${location.pathname}`,
    });
  };

  const onComment = () => {
    history.push({
      pathname: `${AppPath.feed}/${params.id}`,
      search: `?action=focus`,
    });
  };

  const onLikeClick = async () => {
    const act = JSON.parse(AuthService.getToken());
    const postData = { feed_node: params.id, sid: feed.sid };
    if (act) {
      postData.access_token = act.access_token;
    } else {
      return message.warn({
        content: (
          <>
            {" "}
            <span>กรุณาเข้าสู่ระบบ</span>{" "}
            <Button type="link" onClick={() => goLogin()}>
              Login
            </Button>
          </>
        ),
      });
    }
    try {
      const resp = await FeedService.feedClickLike(postData);
      if (resp.success) {
        const newFeed = { ...feed };
        if (Number(feed.liked)) {
          newFeed.liked = 0;
          newFeed.all_liked = Number(feed.all_liked) - 1;
        } else {
          newFeed.liked = 1;
          newFeed.all_liked = Number(feed.all_liked) + 1;
        }
        setFeed(newFeed);
      }
      console.log(resp);
    } catch (error) {
      console.log(error);
      message.error("เกิดข้อผิดพลาด");
    }
  };

  if (isLoading) {
    return (
      <Spin spinning tip="loading...">
        <div style={{ height: 50 }}></div>
      </Spin>
    );
  } else {
    if (!feed.feed_node) {
      return (
        <Result
          status="404"
          title="404"
          subTitle="ไม่พบข้อมูล"
          extra={
            <Link to={AppPath.home}>
              {" "}
              <Button type="primary">ไปยังหน้าแรก</Button>
            </Link>
          }
        />
      );
    } else {
      console.log(feed);
      let imagePath = "";
      if (feed.feed_pic) {
        imagePath = ShopService.getLogoHasPath(
          feed.path_feed_pic,
          feed.feed_pic
        );
      }
      return (
        <>
          <MetaTags>
            <title>{feed.shop_name}</title>
            <meta name="description" content="Some description." />
            <meta property="og:title" content="ข่าวสารเก็บแต้ม" />
            {imagePath && <meta property="og:image" content={imagePath} />}
          </MetaTags>
          <FeedCardView
            feed={feed}
            onLikeClick={onLikeClick}
            onCommentClick={onComment}
          />
        </>
      );
    }
  }
}
let mapStateToProps = (state) => ({ user: state.user });

export default connect(mapStateToProps, null)(FeedShared);
