import React, { useState } from "react";
import { Button, Modal, Icon, Switch } from "antd";

export default function Follower({ shop_name, followerChange, followed }) {
  const [visible, setVisible] = useState(false);
  const [isfollowed, setFollowed] = useState(followed);
  const followerChangeConfirm = (e) => {
    if (e) {
      setVisible(false);
      followerChange(e);
    } else {
      setVisible(true);
      setFollowed(e);
    }
  };
  return (
    <>
      <Modal width={320} visible={visible} closable={false} footer={null}>
        <span className="font-main size-15">
          <Icon type="exclamation-circle" className="c-sub mr-2 size-12" />
          เลิกติดตาม?
        </span>
        <p className="font-sub bold size-10">เลิกติดตามร้าน {shop_name} ?</p>
        <div className="text-right">
          <Button
            key="back"
            className="font-main size-12"
            onClick={() => {
              setFollowed(true);
              setVisible(false);
            }}
          >
            ติดตามต่อไป
          </Button>
          ,
          <Button
            key="submit"
            className="font-main size-12"
            type="primary"
            onClick={() => {
              setVisible(false);
              followerChange(isfollowed);
            }}
          >
            เลิกติดตาม
          </Button>
        </div>
      </Modal>
      <Switch
        checked={
          isfollowed === "true" ||
          isfollowed === "1" ||
          isfollowed === true ||
          isfollowed === 1 ||
          followed === true
            ? true
            : false
        }
        className="font-main"
        unCheckedChildren={<span className="c-gray">ติดตาม</span>}
        size="small"
        onChange={followerChangeConfirm}
        style={{ width: 50 }}
      />
    </>
  );
}
