import React, { useRef, useEffect, useState } from "react";
import { connect } from "react-redux";
import { message } from "antd";

import { logoutAndGotoLogin } from "../../store/actions/auth";
import AuthService from "../../services/AuthService";
import ShopService from "../../services/ShopService";
import ShopPromotion from "./ShopPromotion";

const ShopPromotionList = (props) => {
  const latestProps = useRef(props);
  const [promotionsState, setPromotionsState] = useState([]);
  useEffect(() => {
    latestProps.current = props;
  });

  useEffect(() => {
    const { sid, history, logoutAndGotoLogin } = latestProps.current;
    const act = JSON.parse(AuthService.getToken());
    const postData = {
      sid,
      access_token: act.access_token,
    };
    ShopService.getShopPromotions(postData)
      .then((resp) => {
        if (resp.success) {
          setPromotionsState(resp.result);
        } else {
          logoutAndGotoLogin(history);
        }
      })
      .catch((error) => {
        console.log(error);
        message.warn("เกิดข้อผิดพลาด");
      });
  }, []);
  return (
    <div>
      {promotionsState.map((promotion) => {
        return (
          <ShopPromotion
            key={promotion.pro_id}
            sid={props.sid}
            promotion={promotion}
            history={props.history}
          />
        );
      })}
    </div>
  );
};

export default connect(null, { logoutAndGotoLogin })(ShopPromotionList);
