import React from "react";
import { PageHeader } from "antd";

import Main from "./Main";
import PromotionList from "../components/promotion/PromotionList";

const Promotion = () => {
  return (
    <Main title="Promotion">
      <PageHeader
        className="b-sub"
        style={{
          marginBottom: "1rem",
        }}
      >
        <span className="c-w font-main size-15">โปรโมชั่นทั้งหมด</span>
      </PageHeader>
      <PromotionList />
    </Main>
  );
};

export default Promotion;
