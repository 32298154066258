import {
  SET_ORGANIS,
} from "../actions/types";

//return state ใหม่ตาม action ต่างๆ
const organis = (state = {}, action) => {
  switch (action.type) {
    case SET_ORGANIS:
      return action.payload
   
    default:
      return state;
  }
};

export default organis;
