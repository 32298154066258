import { APP_URL } from "../config";
import http from "./HttpService";

const PromotionService = {
  async getAllShopPromotion(data) {
    const url = `${APP_URL}allShopPromotion`;
    return await http.requestPost(url, data);
  },
  async genBubblePro(data) {
    const url = `${APP_URL}membersGenBubblePro`;
    return await http.requestPost(url, data);
  },
};

export default PromotionService;
