import styled from "styled-components";

export const MainTitle = styled.h1`
  font-size: 1.5rem;
  color: #2c2e2c;
  font-family: Gebtam !important;
  margin-bottom: 0rem !important;
`;

export const MainTitleColor = styled.h1`
  font-size: 1.7rem;
  color: #fff;
  font-family: Gebtam !important;
  margin-bottom: 0rem !important;
`;

export const SpanSub = styled.span`
  font-size: 1rem;
  font-family: Sukhumvit !important;
`;

export const BoxLogoFeed = styled.div`
  position: absolute;
  top: 0.5rem;
  background-color: #fff;
  border-radius: 1.5rem;
  max-width: 90%;
  font-family: Sukhumvit !important;
  font-weight: bold;
  font-size: 1rem;
  line-height: 1.8rem;
  height: 1.8rem;
  padding-right: 0.5rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  left: 50%;
  transform: translate(-50%, 0);
`;

export const BoxFeed = styled.div`
  position: relative;
  width: 12rem;
  height: 12rem;
  margin: auto;
`;

export const ImgCover = styled.img`
  object-fit: cover;
  object-position: center;
  width: 100%;
  height: 100%;
`;

export const Flex = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
`;

export const Center = styled.div`
  margin: auto;
  text-align: center !important;
  max-width: 100%;
`;

export const BGgrad = styled.div`
  background-image: linear-gradient(
    to right,
    #f3b755,
    #e5366f,
    #e5366f,
    #f3b755
  );
`;

export const ShopDiv = styled.div`
  border: 1px solid black;
  width: 22rem;
  padding: 16px;
  border-radius: 10px;
  background: #fff;
`;
export const OverXAuto = styled.div`
  max-width: 100%;
  overflow-x: auto;
`;

export const LineClamp2 = styled.p`
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
`;

export const BoxSub = styled.div`
  border-radius: 5px;
  padding: 5px;
  background-color: #f8d00f;
`;

export const BoxMain = styled.div`
  border-radius: 5px;
  background-color: #f03975;
`;

export const CardPromotion = styled.div`
  width: 240px;
  height: 170px;
  border: 0.5px solid gainsboro;
`;

export const DateText = styled.span`
  font-size: 0.9rem;
  color: #6f6f6f;
`;

export const TextEllip = styled.span`
  word-break: break-all;
  display: -webkit-box;
  -webkit-line-clamp: ${(props) => props.lineNum};
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const TextTime = styled.span`
  font-size: 14px;
  opacity: 0.7;
`;
