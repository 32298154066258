import React from "react";
import { Row, Col } from "antd";

export default function PriceItem({ price, total, count, layout }) {
  return (
    <Row type="flex" align="middle" justify="center" className="mt-3">
      <Col {...layout} className=" b-sub raduis-1 pd-3 text-center">
        <div>
          <span className="font-sub size-08">ราคาสินค้า</span>
          <span className="font-main c-main size-12"> {price} บาท</span>
        </div>
        <div>
          <span className="font-sub size-08">จำนวน</span>
          <span className="font-main c-main size-12"> {count} ชิ้น</span>
        </div>
        <div>
          <span className="font-sub size-10">ยอดชำระรวม</span>
        </div>
        <p className="font-main size-20 c-main line-12 mb-2">{total} บาท</p>
      </Col>
    </Row>
  );
}
