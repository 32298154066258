import ProductService from "../../services/ProductService";
import {
  getProductsPending,
  getProductsSuccess,
  getProductsError,
  getAllProductsSuccess,
  setPaymentVisible,
  setIsPayment,
} from "./actionCreators";

export const getAllProduct = (data) => (dispatch) => {
  ProductService.getAllProduct(data)
    .then((resp) => {
      if (resp.success) {
        const newData = resp.result.reduce((a, b) => {
          a[b.prod_id] = b;
          return a;
        }, {});
        if (resp.result.length < 10) {
          dispatch(getAllProductsSuccess(newData));
        } else {
          dispatch(getProductsSuccess(newData));
        }
      } else {
        dispatch(getProductsError(resp.result));
      }
    })
    .catch((error) => {
      console.log(error);
      dispatch(getProductsError(error));
    });
};

export const getAllShopProduct = (data) => (dispatch) => {
  dispatch(getProductsPending());
  ProductService.getAllShopProduct(data)
    .then((resp) => {
      if (resp.success) {
        dispatch(getProductsSuccess(resp.result));
      } else {
        dispatch(getProductsError(resp.result));
      }
    })
    .catch((error) => {
      console.log(error);
      dispatch(getProductsError(error));
    });
};

export const setPaymentVisibled = (visible) => (dispatch) => {
  dispatch(setPaymentVisible(visible));
};

export const setIsPaymentAT = (isPayment) => (dispatch) => {
  dispatch(setIsPayment(isPayment));
};
