import React, { useEffect, useState } from "react";
import InfiniteScroll from "react-infinite-scroller";
import { useHistory } from "react-router-dom";
import { message, Row, Col, Spin } from "antd";
import { connect } from "react-redux";

import AuthService from "../../services/AuthService";
import OrderService from "../../services/OrderService";
import FeedSkeleton from "../feed/FeedSkeleton";
import OrderHistoryCard from "./OrderHistoryCard";
import { logoutAndGotoLogin } from "../../store/actions/auth";
import { OrderHistoryContext } from "../../context/OrderHistoryContext";

const limit = 20;
function OrderHistoryList({ logoutAndGotoLogin, orderStatus }) {
  const history = useHistory();
  const [isLoading, setLoading] = useState(true);
  // const [ordersHistory, setOrdersHistory] = useState([]);
  const {
    ordersHistory: [ordersHistory, setOrdersHistory],
  } = React.useContext(OrderHistoryContext);
  const [start, setStart] = useState(0);
  const [hasMore, setHasMore] = useState(true);
  const [isLoadingMore, setLoadingMore] = useState(false);
  useEffect(() => {
    const fetchOrdersHistory = async () => {
      const act = JSON.parse(AuthService.getToken());
      const postData = {
        start: start,
        limit: limit,
      };
      if (act) {
        postData.access_token = act.access_token;
      }
      if (orderStatus) {
        postData.status = [Number(orderStatus)];
      } else {
        postData.status = [-2, -1, 0, 1, 2, 3];
      }
      try {
        setLoadingMore(true);
        const resp = await OrderService.historyOrders(postData);
        if (resp.success) {
          const orders = resp.result;
          if (orders.length < limit) {
            setHasMore(false);
          }
          setOrdersHistory((prevOrder) => [...prevOrder, ...orders]);
        } else if (resp.ERROR) {
          logoutAndGotoLogin(history);
        }
        console.log(resp);
      } catch (error) {
        console.log(error);
        message.error("เกิดข้อผิดพลาด");
      }
      setLoadingMore(false);
      setLoading(false);
    };
    fetchOrdersHistory();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [start]);

  const loadMore = () => {
    if (hasMore) {
      setStart(ordersHistory.length);
    }
  };

  if (isLoading) {
    return <Spin spinning tip="loading..."></Spin>;
  } else {
    return (
      <InfiniteScroll
        pageStart={0}
        loadMore={loadMore}
        hasMore={hasMore}
        loader={
          <div className="loader" key={0}>
            <FeedSkeleton />
          </div>
        }
        useWindow={true}
        className="w-100"
      >
        <Row type="flex" justify="center">
          <Col xs={24} sm={24} md={22} span={22}>
            <Row type="flex" gutter={[8, 16]} justify="center">
              {" "}
              {ordersHistory.map((order, i) => {
                return (
                  <Col xs={22} sm={22} md={12} lg={12} key={i}>
                    <OrderHistoryCard
                      sid={order.sid}
                      shopName={order.shop_name}
                      head_pic={order.head_pic}
                      path_head_pic={order.path_head_pic}
                      dateTime={order.datetime}
                      qty={order.qty}
                      total={order.total_price}
                      orderId={order.order_id}
                      orderStatus={order.status}
                      contact={order.contact}
                    />
                  </Col>
                );
              })}
            </Row>
          </Col>
        </Row>

        {isLoadingMore ? <FeedSkeleton /> : null}
      </InfiniteScroll>
    );
  }
}
export default connect(null, {
  logoutAndGotoLogin,
})(OrderHistoryList);
