import React, { Component } from "react";
import { connect } from "react-redux";
import {
  InfiniteLoader,
  List,
  WindowScroller,
  AutoSizer,
  CellMeasurer,
  CellMeasurerCache,
} from "react-virtualized";
import { Row, Col, message } from "antd";
import { withRouter } from "react-router-dom";

import AuthService from "../../services/AuthService";
import { getAllFeeds, setFeedsAlls } from "../../store/actions/feed";
import FeedSkeleton from "../feed/FeedSkeleton";
import {
  getRowsAmount,
  getMaxItemsAmountPerRow,
  generateIndexesForRow,
} from "../../helpers/util";
import FeedCardView from "../feed/feed-card/FeedCardView";
import { AppPath } from "../../router/path";
import FeedService from "../../services/FeedService";

class FeedAll extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLocationError: false,
    };
    this._cache = new CellMeasurerCache({
      fixedWidth: true,
      minHeight: 300,
    });
  }
  componentWillMount() {
    const { user, history } = this.props;
    if (!user.isAuthenticated) {
      AuthService.goLogin(history, "กรุณาเข้าสู่ระบบ");
    } else {
      this.getAllFeeds();
    }
  }

  getAllFeeds() {
    const { history, getAllFeeds, feeds } = this.props;
    const length = Object.keys(feeds.data).length;
    if (length === 0) {
      const postData = {
        start: "0",
        limit: 50,
      };
      getAllFeeds(history, postData);
    }
  }

  loadFeed() {
    const { history, getAllFeeds, feeds } = this.props;
    const { data, hasMore } = feeds;
    if (!hasMore || !data) return;
    const postData = {
      start: Object.keys(data).length,
      limit: 50,
    };

    getAllFeeds(history, postData);
  }

  onLikeClick = async (feed) => {
    const { data } = this.props.feeds;
    const { setFeedsAlls } = this.props;
    const act = JSON.parse(AuthService.getToken());
    const postData = { feed_node: feed.feed_node, sid: feed.sid };
    if (act) {
      postData.access_token = act.access_token;
    }
    try {
      const resp = await FeedService.feedClickLike(postData);
      if (resp.success) {
        const newFeed = { ...feed };
        if (Number(feed.liked)) {
          newFeed.liked = 0;
          newFeed.all_liked = Number(feed.all_liked) - 1;
        } else {
          newFeed.liked = 1;
          newFeed.all_liked = Number(feed.all_liked) + 1;
        }
        data[feed.feed_node] = newFeed;
        setFeedsAlls(data);
      }
    } catch (error) {
      console.log(error);
      message.error("เกิดข้อผิดพลาด");
    }
  };

  renderFeedStyleCard = () => {
    const { data, hasMoreNear, onResize } = this.props.feeds;
    const { history } = this.props;
    const dataArr = Object.values(data).sort(
      (a, b) => b.feed_node - a.feed_node
    );
    const { isLocationError } = this.state;
    return (
      <div>
        {dataArr.length === 0 ? (
          isLocationError ? (
            <Row>
              <Col className="font-sub size-12 bold text-center">
                ไม่พบข่าวสารกรุณาให้สิทธิ์การเข้าถึงตำแหน่งของท่าน
              </Col>
            </Row>
          ) : null
        ) : (
          <AutoSizer disableHeight onResize={onResize}>
            {({ width }) => {
              const rowCount = getRowsAmount(
                width,
                dataArr.length,
                hasMoreNear,
                width
              );
              return (
                <InfiniteLoader
                  ref={this.infiniteLoaderRef}
                  rowCount={rowCount}
                  isRowLoaded={({ index }) => {
                    const maxItemsPerRow = getMaxItemsAmountPerRow(
                      width,
                      this._cache.rowHeight
                    );
                    const allItemsLoaded =
                      generateIndexesForRow(
                        index,
                        maxItemsPerRow,
                        dataArr.length
                      ).length > 0;
                    return !hasMoreNear || allItemsLoaded;
                  }}
                  loadMoreRows={this.loadFeed.bind(this)}
                >
                  {({ onRowsRendered, registerChild }) => (
                    <WindowScroller>
                      {({ height, scrollTop }) => {
                        return (
                          <List
                            autoHeight
                            ref={registerChild}
                            height={height}
                            scrollTop={scrollTop}
                            width={width}
                            rowCount={rowCount}
                            deferredMeasurementCache={this._cache}
                            rowHeight={this._cache.rowHeight}
                            onRowsRendered={onRowsRendered}
                            rowRenderer={({ index, style, key, parent }) => {
                              const feeds = generateIndexesForRow(
                                index,
                                1,
                                dataArr.length
                              ).map((feedIndex) => dataArr[feedIndex]);
                              return (
                                <CellMeasurer
                                  cache={this._cache}
                                  columnIndex={0}
                                  key={key}
                                  rowIndex={index}
                                  parent={parent}
                                >
                                  {() => (
                                    <Row
                                      style={style}
                                      key={key}
                                      type="flex"
                                      justify="center"
                                      className="mx-auto"
                                      gutter={[8, 8]}
                                    >
                                      {feeds.map((feed) => {
                                        return (
                                          <Col
                                            key={feed.feed_node}
                                            xs={22}
                                            sm={14}
                                            md={16}
                                            lg={14}
                                            span={14}
                                          >
                                            <FeedCardView
                                              feed={feed}
                                              onLikeClick={this.onLikeClick}
                                              onCommentClick={() => {
                                                history.push(
                                                  `${AppPath.feed}/${feed.feed_node}?action=focus`
                                                );
                                              }}
                                            />
                                          </Col>
                                        );
                                      })}
                                    </Row>
                                  )}
                                </CellMeasurer>
                              );
                            }}
                          />
                        );
                      }}
                    </WindowScroller>
                  )}
                </InfiniteLoader>
              );
            }}
          </AutoSizer>
        )}
        {hasMoreNear && !isLocationError ? <FeedSkeleton /> : null}
      </div>
    );
  };

  render() {
    return this.renderFeedStyleCard();
  }
}

const mapStateToProps = (state) => ({
  feeds: state.feeds,
  user: state.user,
});

export default connect(mapStateToProps, { getAllFeeds, setFeedsAlls })(
  withRouter(FeedAll)
);
