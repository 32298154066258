import React, { Component } from "react";
import { Link } from "react-router-dom";
import {
  Form,
  Input,
  Icon,
  Row,
  Col,
  Button,
  Avatar,
  message,
  Radio,
  Tooltip,
  Divider,
  Spin,
  PageHeader,
  Select,
} from "antd";
import moment from "moment";
import "moment/locale/th";
// import AuthService from "../services/AuthService";
import { AppPath } from "../../router/path";
import AuthServiceV2 from "../../services/AuthServiceV2";
import PoweredByGebtam from "../gebtam/PoweredByGebtam";

moment.locale("th");
const { Option } = Select;
let children = [
  "กฎหมาย",
  "การตลาด",
  "เกษตร",
  "ขาย",
  "เขียนแบบ/งานDrawing/AutoCad/ออกแบบวิศวกรรม",
  "คอมพิวเตอร์/IT/โปรแกรมเมอร์",
  "งานการเงิน-ธนาคาร",
  "งานขนส่ง-คลังสินค้า",
  "งานนำเข้า-ส่งออก",
  "งานบริการลูกค้า-Call Center",
  "งานบัญชี",
  "บันเทิง",
  "ธุระการ",
  "จัดซื้อ",
  "ช่างเทคนิค",
  "นักเขียน",
  "ผู้จัดการ",
  "แพทย์/เภสัชกร/สาธารณสุข",
  "แผนกรักษาความปลอดภัย/งานอาคารจอดรถ",
  "ภูมิศาสตร์/แผนที่/GIS/ผังเมือง",
  "แม่บ้าน/พี่เลี้ยง/คนสวน",
  "วิศวกร",
  "ศิลปะ/กราฟฟิค/ออกแบบ/ช่างภาพ",
  "ออกแบบเว็บไซต์/Web",
  "อาจารย์/ครู/งานวิชาการ",
  "งาน Part-time/พนักงานชั่วคราว",
  "Freelance",
  "เสื้อผ้า/สิ่งทอ/ช่างแพทเทิร์น",
];
children = children.map((occupation) => (
  <Option key={occupation}>{occupation}</Option>
));
class RegisterV2 extends Component {
  constructor(props) {
    super(props);
    this.state = {
      errors: {},
      isLoading: false,
      isOtpLoading: false,
      confirmDirty: false,
      isMember: false,
      autoCompleteResult: [],
      code: "",
      member: {
        m_tel_number: "",
        m_password: "",
        m_gender: 3,
        m_name: "",
        m_id_card: "",
        m_nickname: "",
        m_lastname: "",
        m_mail: "",
        code: "",
        m_occupation: "",
      },
    };
  }

  async componentDidMount() {
    const { m_tel_number, setIsVerify, setTelNumber } = this.props;
    this.setState({ isLoading: true });
    try {
      const resp = await AuthServiceV2.checkTelDup({ m_tel_number });
      if (resp.data_cus) {
        if (resp.data_cus.event_code === "SUTSHOP") {
          message.warn("ขออภัยหมายเลขโทรศัพท์ของท่านลงทะเบียนแล้ว");
          setTelNumber("");
          setIsVerify(false);
          return;
        }

        resp.data_cus.m_gender = Number(resp.data_cus.m_gender);
        if (resp.result && resp.result.code === "OLD_APP") {
          this.setState({ member: { ...resp.data_cus }, isMember: true });
        } else {
          this.setState({ member: { ...resp.data_cus } });
        }
      }
      console.log(resp);
    } catch (error) {
      console.log(error);
    }
    this.setState({ isLoading: false });
  }

  handleSubmit = (e) => {
    e.preventDefault();
    this.props.form.validateFieldsAndScroll(async (err, values) => {
      if (!err) {
        const { setIsVerify } = this.props;
        const { isMember } = this.state;
        this.setState({ isLoading: true });
        const newValues = { ...values };
        newValues.fromWeb = true;
        let resp = "";
        try {
          if (isMember) {
            resp = await AuthServiceV2.updateProfile(newValues);
            console.log(resp);
          } else {
            resp = await AuthServiceV2.signup(newValues);
            console.log(resp);
          }
          if (resp.success) {
            const respEvent = await AuthServiceV2.checkEventCode({
              m_tel_number: newValues.m_tel_number,
            });
            console.log(respEvent);
            if (respEvent.success) {
              message.success("ลงทะเบียนสำเร็จ");
              setIsVerify(false);
            } else {
              if (resp.result && resp.result.ERROR_CODE === "WC") {
                message.warn("รหัส OTP ไม่ถูกต้องหรือหมดอายุ");
              }
              message.error("ไม่สามารถลงทะเบียนได้");
            }
          } else {
            message.error("ลงทะเบียนไม่สำเร็จ");
          }
        } catch (error) {
          console.log(error);
          message.error("ลงทะเบียนไม่สำเร็จ");
        }
        this.setState({ isLoading: false });
      }
    });
  };

  handleSendOtp = async () => {
    const m_tel_number = this.props.form.getFieldValue("m_tel_number");
    const telError = this.props.form.getFieldError("m_tel_number");
    if (!telError && !m_tel_number) {
      return message.error("กรุณาระบุหมายเลขโทรศัพท์");
    } else if (telError) {
      return message.error("กรุณาระบุหมายเลขโทรศัพท์ให้ถูกต้อง");
    }
    const postData = { to: m_tel_number, user: "test" };
    this.setState({ isOtpLoading: true });
    try {
      const resp = await AuthServiceV2.sendOtp(postData);
      if (resp.success) {
        message.success("ส่ง otp สำเร็จ");
      } else {
        message.error("ไม่สามารถส่ง otp ได้");
      }
    } catch (error) {
      console.log(error);
      message.error("ไม่สามารถส่ง otp ได้");
    }
    setTimeout(() => {
      this.setState({ isOtpLoading: false });
    }, 30000);
  };

  render() {
    const { getFieldDecorator } = this.props.form;
    const { m_tel_number, setIsVerify, code } = this.props;
    const { isLoading } = this.state;
    const formItemLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 8 },
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 16 },
      },
    };
    const { member } = this.state;

    return (
      <Spin spinning={isLoading} tip="loading...">
        <div className="login-page">
          <Form
            {...formItemLayout}
            onSubmit={this.handleSubmit}
            className="regis-form font-sub"
          >
            <PageHeader
              className="c-main"
              onBack={() => setIsVerify(false)}
              subTitle={
                <span className="c-main font-main size-12">ย้อนกลับ</span>
              }
            />
            <div type="flex" justify="center" className="text-center mb-3">
              <Link to={AppPath.verify}>
                <Tooltip
                  placement="bottom"
                  title={<span className="font-sub">หน้าแรก</span>}
                >
                  <Avatar shape="square" size={64} src="/gebtam.png" />
                </Tooltip>
              </Link>
              <div className="text-center">
                <span className="font-main size-15 c-main">ลงทะเบียน</span>
              </div>
            </div>
            <Form.Item label="โทรศัพท์">
              <Row gutter={8}>
                <Col span={16}>
                  {getFieldDecorator("m_tel_number", {
                    initialValue: m_tel_number,
                    rules: [
                      { required: true, message: "ระบุหมายเลขโทรศัพท์" },
                      { len: 10, message: "หมายเลขโทรศัพท์ไม่ถูกต้อง" },
                    ],
                  })(
                    <Input
                      disabled
                      prefix={
                        <Icon
                          type="phone"
                          style={{ color: "rgba(0,0,0,.25)" }}
                        />
                      }
                      placeholder="Phone"
                    />
                  )}
                </Col>
                <Col span={8}>
                  <Button
                    type="primary"
                    shape="round"
                    loading={this.state.isOtpLoading}
                    onClick={this.handleSendOtp}
                  >
                    ส่ง otp
                  </Button>
                </Col>
              </Row>
            </Form.Item>

            <Form.Item label="OTP">
              {getFieldDecorator("code", {
                initialValue: code,
                rules: [
                  {
                    required: true,
                    message: "กรอก OTP",
                  },
                ],
              })(<Input />)}
            </Form.Item>
            <Form.Item label="ชื่อ">
              {getFieldDecorator("m_name", {
                initialValue: member.m_name,
                rules: [
                  {
                    required: true,
                    message: "กรอกชื่อ",
                  },
                ],
              })(<Input placeholder="ชื่อ" />)}
            </Form.Item>
            <Form.Item label="นามสกุล">
              {getFieldDecorator("m_lastname", {
                initialValue: member.m_lastname,
                rules: [
                  {
                    required: true,
                    message: "กรอกนามสกุล",
                  },
                ],
              })(<Input placeholder="นามสกุล" />)}
            </Form.Item>
            <Form.Item label="หมายเลขบัตรประชาชน">
              {getFieldDecorator("m_id_card", {
                initialValue: member.m_id_card,
                rules: [
                  {
                    required: true,
                    message: "กรอกหมายเลขบัตรประชาชน",
                  },
                  {
                    len: 13,
                    message: "หมายเลขบัตรประชาชน 13 หลัก",
                  },
                ],
              })(<Input placeholder="หมายเลขบัตรประชาชน" />)}
            </Form.Item>
            <Form.Item label="เพศ">
              {getFieldDecorator("m_gender", {
                initialValue: member.m_gender,
              })(
                <Radio.Group>
                  <Radio value={1}>ชาย</Radio>
                  <Radio value={2}>หญิง</Radio>
                  <Radio value={3}>ไม่ระบุ</Radio>
                </Radio.Group>
              )}
            </Form.Item>
            <Form.Item label="อาชีพ">
              {getFieldDecorator("m_occupation", {
                initialValue: member.m_occupation,
                rules: [
                  {
                    required: true,
                    message: "ระบบุอาชีพ",
                  },
                ],
              })(
                <Select
                  showSearch
                  style={{ width: "100%" }}
                  placeholder="กรุณาเลือกอาชีพ"
                  filterOption={(input, option) =>
                    option.props.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                >
                  {children}
                </Select>
              )}
            </Form.Item>
            <Form.Item label="อีเมล (ถ้ามี)">
              {getFieldDecorator("m_mail", {
                initialValue: member.m_mail,
                rules: [
                  {
                    type: "email",
                    message: "กรอกอีเมลให้ถูกต้อง",
                  },
                  {
                    required: false,
                    message: "Please input your E-mail!",
                  },
                ],
              })(<Input />)}
            </Form.Item>

            <Divider type="horizontal" style={{ background: "gainsboro" }} />
            <Row>
              <Col span={24}>
                <Button
                  type="primary"
                  htmlType="submit"
                  block
                  shape="round"
                  className="font-main size-15"
                  loading={this.state.isLoading}
                >
                  ลงทะเบียน
                </Button>
              </Col>
            </Row>
            <h3 className="c-main font-main text-center mt-2">
              หมายเหตุ: สเปรย์แอลกอฮอล์ ล้างมือ 75% ขนาด 30 ml. <br /> (จำกัด 1
              คน/1 ขวด/สัปดาห์)
            </h3>
            <PoweredByGebtam />
          </Form>
        </div>
      </Spin>
    );
  }
}

const RegisterForm = Form.create({ name: "register" })(RegisterV2);

export default RegisterForm;
