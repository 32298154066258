import { message } from "antd";
const uploadService = {
  getBase64(img, callback) {
    const reader = new FileReader();
    reader.addEventListener("load", () => callback(reader.result));
    reader.readAsDataURL(img);
  },
  beforeUpload(file) {
    const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
    if (!isJpgOrPng) {
      message.error("You can only upload JPG/PNG file!");
    }
    const isLt1M = file.size / 1024 / 1024 < 1;
    if (!isLt1M) {
      message.error("ขนาดรูปภาพต้องไม่เกิน 1 MB");
    }
    return false;
    // return isJpgOrPng && isLt2M;
  },
};

export default uploadService;
