import React from 'react'
import { Avatar } from 'antd'

export default function PoweredByGebtam() {
  return (
    <div className="text-center">
      <a href="https://www.gebtam.com" target="_blank">
        <img
          sizes="20px"
          src="/AW_gamtam_logo_PoweredBy.png"
          alt=""
          width="84em"
          height="42em"
        />
      </a>
    </div>
  )
}
