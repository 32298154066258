import {
  clearData,
  getCurrentUser,
  getErrors,
  logoutUser,
  registerSuccess,
  setCurrentUser,
} from "./actionCreators";

import AuthService from "../../services/AuthService";
import { message } from "antd";

//เข้าสู่ระบบ
export const loginUser = (data, history) => (dispatch) => {
  AuthService.login(data)
    .then((resp) => {
      const data = resp;
      if (data.success) {
        const param = new URLSearchParams(history.location.search);
        const path = param.get("path") || "/";
        AuthService.saveToken(JSON.stringify(data));
        dispatch(setCurrentUser(data));
        history.push(path);
        message.success("เข้าสู่ระบบสำเร็จ");
      } else {
        dispatch(
          getErrors({
            loginError: "หมายเลขโทรศัพท์หรือรหัสผ่านไม่ถูกต้อง",
            isLogin: Date.now(),
          })
        );
      }
    })
    .catch((error) => {
      console.log(error);
      dispatch(
        getErrors({
          loginError: "เกิดข้อผิดพลาด",
          isLogin: Date.now(),
        })
      );
    });
};

//เข้าสู่ระบบ OTP
export const loginUserOTP = (data, history) => (dispatch) => {
  AuthService.loginOTP(data)
    .then((resp) => {
      const data = resp;
      if (data.success) {
        const param = new URLSearchParams(history.location.search);
        const path = param.get("path") || "/";
        AuthService.saveToken(JSON.stringify(data));
        dispatch(setCurrentUser(data));
        history.push(path);
        message.success("เข้าสู่ระบบสำเร็จ");
      } else {
        dispatch(
          getErrors({
            loginError: "หมายเลขโทรศัพท์หรือรหัสผ่านไม่ถูกต้อง",
            isLogin: Date.now(),
          })
        );
      }
    })
    .catch((error) => {
      console.log(error);
      dispatch(
        getErrors({
          loginError: "เกิดข้อผิดพลาด",
          isLogin: Date.now(),
        })
      );
    });
};

//เข้าสู่ระบบ อัพเดทโปรไฟล์
export const loginUpdateUser = (data, history) => (dispatch) => {
  AuthService.login(data)
    .then(async (resp) => {
      const data = resp;
      if (data.success) {
        const param = new URLSearchParams(history.location.search);
        const path =
          param.get("path") || `/updateProfile?tel=${data.m_tel_number}`;
        AuthService.saveToken(JSON.stringify(data));
        await dispatch(setCurrentUser(data));
        history.push(path);
        message.success("เข้าสู่ระบบสำเร็จ");
      } else {
        dispatch(
          getErrors({
            loginError: "หมายเลขโทรศัพท์หรือรหัสผ่านไม่ถูกต้อง",
            isLogin: Date.now(),
          })
        );
      }
    })
    .catch((error) => {
      console.log(error);
      dispatch(
        getErrors({
          loginError: "เกิดข้อผิดพลาด",
          isLogin: Date.now(),
        })
      );
    });
};
//เข้าสู่ระบบ อัพเดทโปรไฟล์ อีเว้น
export const loginUpdateUserEvent = (data, history, sid) => (dispatch) => {
  AuthService.login(data)
    .then(async (resp) => {
      const data = resp;
      if (data.success) {
        const param = new URLSearchParams(history.location.search);
        const path =
          param.get("path") ||
          `/updateProfileEvent?tel=${data.m_tel_number}&sid=${sid}`;
        AuthService.saveToken(JSON.stringify(data));
        await dispatch(setCurrentUser(data));
        history.push(path);
        message.success("เข้าสู่ระบบสำเร็จ");
      } else {
        dispatch(
          getErrors({
            loginError: "หมายเลขโทรศัพท์หรือรหัสผ่านไม่ถูกต้อง",
            isLogin: Date.now(),
          })
        );
      }
    })
    .catch((error) => {
      console.log(error);
      dispatch(
        getErrors({
          loginError: "เกิดข้อผิดพลาด",
          isLogin: Date.now(),
        })
      );
    });
};

//ตรวจสอบผู้ใช้ว่ามีในระบบหรือไม่
export const chkUser = (data) => (dispatch) => {
  return AuthService.checkUser(data);
};
//ตรวจสอบการเข้าสู่ระบบกรณี refresh
export const checkLoginUser = (history) => (dispatch) => {
  dispatch(getCurrentUser());
  const isLogin = AuthService.isLogin();
  if (isLogin) {
    dispatch(setCurrentUser(isLogin));
  }
};

//ออกจากระบบ
export const logout = (history) => (dispatch) => {
  AuthService.logout();
  dispatch(logoutUser());
  history.push("/");
  window.location.reload();
};
//ออกจากระบบ
export const logoutToPath = (history, path) => (dispatch) => {
  AuthService.logout();
  dispatch(logoutUser());
  history.push(path);
};

export const logoutAndGotoLogin = (history) => (dispatch) => {
  AuthService.logout();
  dispatch(clearData());
  AuthService.goLogin(history, "เซสชั่นหมดอายุ");
};

export const onLogoutUser = () => (dispatch) => {
  AuthService.logout();
  dispatch(logoutUser());
};

//สมัครสมาชิก
export const registerUser = (data, history) => (dispatch) => {
  AuthService.register(data)
    .then((resp) => {
      if (resp.success) {
        const param = new URLSearchParams(history.location.search);
        const path = param.get("path") || "/";
        dispatch(registerSuccess());
        dispatch(
          getErrors({
            registerError: "",
          })
        );
        message.success("สมัครสมาชิกสำเร็จ");
        if (history.location.pathname === "/login") {
          dispatch(loginUser(data, history));
        } else {
          if (path !== "/") history.push(`/login?path=${path}`);
          else history.push("/login");
        }
      } else {
        if (resp.result && resp.result.ERROR_CODE === "WC") {
          dispatch(
            getErrors({
              registerError: "สมัครสมาชิกไม่สำเร็จ Otp ไม่ถูกต้อง",
              isRegister: Date.now(),
            })
          );
        } else {
          dispatch(
            getErrors({
              registerError: "สมัครสมาชิกไม่สำเร็จ",
              isRegister: Date.now(),
            })
          );
        }
        // dispatch(getErrors({
        //   registerError: ''
        // }))
      }
    })
    .catch((error) => {
      console.log(error);
      dispatch(
        getErrors({
          registerError: "สมัครสมาชิกไม่สำเร็จ หมายเลขโทรศัพท์ถูกใช้งานแล้ว",
          isRegister: Date.now(),
        })
      );
    });
};

export const registerUpdateUser = (data, history) => (dispatch) => {
  AuthService.register(data)
    .then((resp) => {
      if (resp.success) {
        dispatch(registerSuccess());
        dispatch(
          getErrors({
            registerError: "",
          })
        );
        message.success("สมัครสมาชิกสำเร็จ");
        if (history.location.pathname === "/login") {
          dispatch(loginUpdateUser(data, history));
        } else {
          history.push("/login");
        }
      } else {
        if (resp.result && resp.result.ERROR_CODE === "WC") {
          dispatch(
            getErrors({
              registerError: "สมัครสมาชิกไม่สำเร็จ Otp ไม่ถูกต้อง",
              isRegister: Date.now(),
            })
          );
        } else {
          dispatch(
            getErrors({
              registerError: "สมัครสมาชิกไม่สำเร็จ",
              isRegister: Date.now(),
            })
          );
        }
        // dispatch(getErrors({
        //   registerError: ''
        // }))
      }
    })
    .catch((error) => {
      console.log(error);
      dispatch(
        getErrors({
          registerError: "สมัครสมาชิกไม่สำเร็จ หมายเลขโทรศัพท์ถูกใช้งานแล้ว",
          isRegister: Date.now(),
        })
      );
    });
};
export const registerUpdateUserEvent = (data, history, sid) => (dispatch) => {
  AuthService.register(data)
    .then((resp) => {
      if (resp.success) {
        dispatch(registerSuccess());
        dispatch(
          getErrors({
            registerError: "",
          })
        );
        message.success("สมัครสมาชิกสำเร็จ");
        console.log("data :>> ", data);
        if (history.location.pathname === "/login") {
          dispatch(loginUpdateUserEvent(data, history, sid));
        } else {
          history.push("/login");
        }
      } else {
        if (resp.result && resp.result.ERROR_CODE === "WC") {
          dispatch(
            getErrors({
              registerError: "สมัครสมาชิกไม่สำเร็จ Otp ไม่ถูกต้อง",
              isRegister: Date.now(),
            })
          );
        } else {
          dispatch(
            getErrors({
              registerError: "สมัครสมาชิกไม่สำเร็จ",
              isRegister: Date.now(),
            })
          );
        }
        // dispatch(getErrors({
        //   registerError: ''
        // }))
      }
    })
    .catch((error) => {
      console.log(error);
      dispatch(
        getErrors({
          registerError: "สมัครสมาชิกไม่สำเร็จ หมายเลขโทรศัพท์ถูกใช้งานแล้ว",
          isRegister: Date.now(),
        })
      );
    });
};

//แก้ไขโปรไฟล์
export const updateProfileUser = (data, history) => (dispatch) => {
  const act = JSON.parse(AuthService.getToken());
  const newPostData = { ...data };
  newPostData.access_token = act.access_token;
  AuthService.updateProfile(newPostData)
    .then((resp) => {
      if (resp.success) {
        dispatch(registerSuccess());
        dispatch(
          getErrors({
            registerError: "",
          })
        );
        // message.success("แก้ไขโปรไฟล์สำเร็จ");
      } else {
        dispatch(
          getErrors({
            registerError: "แก้ไขโปรไฟล์ไม่สำเร็จ",
            isRegister: Date.now(),
          })
        );
      }
    })
    .catch((error) => {
      console.log(error);
      dispatch(
        getErrors({
          registerError: "แก้ไขโปรไฟล์ไม่สำเร็จ หมายเลขโทรศัพท์ถูกใช้งานแล้ว",
          isRegister: Date.now(),
        })
      );
    });
};

//แก้ไขโปรไฟล์ Evnet
export const updateProfileUserEvent = (data, history) => (dispatch) => {
  const act = JSON.parse(AuthService.getToken());
  const newPostData = { ...data };
  newPostData.access_token = act ? act.access_token : null;
  AuthService.updateProfileEvent(newPostData)
    .then((resp) => {
      if (resp.success) {
        dispatch(registerSuccess());
        dispatch(
          getErrors({
            registerError: "",
          })
        );
        // message.success("แก้ไขโปรไฟล์สำเร็จ");
      } else {
        dispatch(
          getErrors({
            registerError: "แก้ไขโปรไฟล์ไม่สำเร็จ",
            isRegister: Date.now(),
          })
        );
      }
    })
    .catch((error) => {
      console.log(error);
      dispatch(
        getErrors({
          registerError: "แก้ไขโปรไฟล์ไม่สำเร็จ หมายเลขโทรศัพท์ถูกใช้งานแล้ว",
          isRegister: Date.now(),
        })
      );
    });
};
