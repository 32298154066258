import React, { useState } from "react";
import { Row, Col, Icon, Divider } from "antd";
import SharedModal from "../../shared/SharedModal";

export default function FeedCardAction({
  countLike,
  isLiked,
  countComment,
  onLikeClick,
  onCommentClick,
  feedId,
}) {
  const [modalVisible, setModalVisible] = useState(false);

  return (
    <>
      <div>
        <Row
          type="flex"
          justify="space-between"
          className="pd-2 border-bt-gray bold"
        >
          <Col span={12}>
            <Icon
              type="star"
              className={`${isLiked ? "c-main" : ""} mr-2`}
              style={{ lineHeight: ".5rem", verticalAlign: "middle" }}
            />
            <span>{countLike}</span>
          </Col>
          <Col span={12} className="text-right">
            <span>ความคิดเห็น {countComment} รายการ</span>
          </Col>
        </Row>
      </div>
      <Row type="flex" className="font-main size-12 text-center pointer">
        <Col span={7} className="d-flex align-center" onClick={onLikeClick}>
          <span className={`${isLiked ? "c-main" : ""} mx-auto`}>ชอบ</span>
        </Col>
        <Col
          span={10}
          className="d-flex align-center mt-2 mb-2"
          onClick={onCommentClick}
        >
          <Divider
            type="vertical"
            className="ab top-unset"
            style={{ width: 1.5, height: "1.5em" }}
          />
          <span className="mx-auto">แสดงความคิดเห็น</span>
        </Col>
        <Col
          span={7}
          className="d-flex align-center mt-2 mb-2"
          onClick={() => setModalVisible(true)}
        >
          <Divider
            type="vertical"
            className="ab top-unset"
            style={{ width: 1.5, height: "1.5em" }}
          />
          <span className=" mx-auto">แชร์</span>
        </Col>
      </Row>
      <SharedModal
        visible={modalVisible}
        setVisible={setModalVisible}
        feedId={feedId}
      />
    </>
  );
}
