import React from "react";
import { Row, Col } from "antd";

import Main from "../Main";
import OrderCard from "../../components/order_v2/OrderCard";

export default function Order() {
  return (
    <Main title="สั่งซื้อสินค้า">
      <Row type="flex" justify="center">
        <Col xs={24} sm={16} md={18} lg={16} xl={12} xxl={10}>
          <OrderCard />
        </Col>
      </Row>
    </Main>
  );
}
