import styled from "styled-components";

export const FeedTextMiddle = styled.span`
  font-size: 2.5rem;
  line-height: 2rem;
  @media (max-width: 768px) {
    font-size: 2rem;
    line-height: 1.5rem;
  }
  @media (max-width: 576px) {
    font-size: 1.5rem;
    line-height: 1rem;
  }
  @media (max-width: 360px) {
    font-size: 1rem;
    line-height: 0.8rem;
  }
`;
export const FeedTextStyle = styled.span`
  font-size: 1.5rem;
  color: #fff;
  @media (max-width: 768) {
    font-size: 1.2rem;
  }
  @media (max-width: 576px) {
    font-size: 1rem;
  }
  @media (max-width: 360px) {
    font-size: 0.7rem;
  }
`;
