import { Button, Col, Image, Modal, Row, notification } from "antd";
import React, { useEffect, useState } from "react";

import { AppPath } from "../../../router/path";
import PaymentService from "../../../services/PaymentService";
import QRCode from "qrcode";
import { clientLoadImage } from "../../../helpers/util";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";

// import QRCode from "qrcode.react";

function ScanQrcodePayment({
  visible,
  qrcode,
  ref1,
  onNextTime,
  orderId,
  tabs,
  user,
}) {
  const history = useHistory();

  useEffect(() => {
    generateQRCode(qrcode);
  }, [qrcode]);

  const [qrCode, setQRCode] = useState(null);
  useEffect(() => {
    PaymentService.getPayment(user.profile.mid).on(
      "value",
      (snapshot) => {
        if (snapshot.val()) {
          console.log("test");
          const orderNoti = Object.values(snapshot.val()).slice(-1)[0];
          if (orderNoti.order_id === orderId) {
            notification.success({
              message: <span className="size-12 bold">ชำระเงินสำเร็จ</span>,
              description: (
                <span className="size-11">ทำการชำระเงินค่าสินค้าเรียบร้อย</span>
              ),
              placement: "bottomRight",
              duration: 6,
            });
            history.push({
              pathname: `${AppPath.orderHistory}?tabs=${tabs}`,
            });
            window.location.reload();
          }
        }
      },
      []
    );
    return function cleanup() {
      PaymentService.getPayment(user.profile.mid).off("value");
    };
  });
  const generateQRCode = (data) => {
    QRCode.toDataURL(data, {
      errorCorrectionLevel: "H",
      quality: 1,
      scale: 4,
      width: 500,
      margin: 1,
    })
      .then((url) => {
        setQRCode(url);
        // console.log(url);
      })
      .catch((err) => {
        console.error(err);
      });
  };
  return (
    <Modal width={400} visible={visible} closable={false} footer={null}>
      <div className="text-center">
        <span className="font-main size-15 c-main">
          แสกนคิวอาร์โค้ดเพื่อชำระเงิน
        </span>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "middle",
          }}
        >
          <img
            src={qrCode}
            id="order_qrcode"
            style={{ width: "250px", height: "250px" }}
          />
        </div>

        <div className="bold">
          <span>
            บริษัท ออนเน็ท จำกัด <br />
            Ref. {ref1}
          </span>
        </div>
      </div>
      <Row type="flex">
        <Col span={24}>
          <Button
            block
            key="submit"
            className="font-main size-12"
            type="primary"
            // onClick={() => clientLoadImage(`order_qrcode`, `qrcode_${ref1}`)}
            onClick={() => clientLoadImage(qrCode, `qrcode_${ref1}`)}
          >
            โหลดคิวอาร์โค้ด
          </Button>
        </Col>
        <Col span={24} className="mt-2">
          <Button
            block
            key="back"
            className="font-main size-12"
            onClick={() => onNextTime()}
          >
            ไปยังหน้าประวัติการซื้อ
          </Button>
        </Col>
      </Row>
    </Modal>
  );
}

ScanQrcodePayment.defaultProps = {
  tabs: "pendingShopReceive",
};

let mapStateToProps = (state) => ({
  user: state.user,
});
export default connect(mapStateToProps, null)(ScanQrcodePayment);
