import React from "react";
import { Row, Col } from "antd";

import Main from "../Main";
import FeedCard from "../../components/feed/FeedCard";

export default function Feed() {
  return (
    <Main title="post">
      <Row type="flex" justify="center">
        <Col xs={24} sm={16} md={18} lg={16} xl={12} xxl={10}>
          <FeedCard />
        </Col>
      </Row>
    </Main>
  );
}
