import React, { Component } from "react";
import { connect } from "react-redux";
import { Menu, Avatar, Icon } from "antd";
import { Link, withRouter } from "react-router-dom";

import { logout, checkLoginUser } from "../../store/actions/auth";
import { AppPath } from "../../router/path";

import "../../index.css";

// const { SubMenu } = Menu;
class LeftMenu extends Component {
  constructor(props) {
    super(props);
    this.state = {
      collapsed: false,
      isDisplayPhone: false,
    };
  }

  componentWillMount() {
    if (!this.props.user.isAuthenticated) this.props.checkLoginUser();
    // window.addEventListener("resize", this.resize.bind(this));
    // this.resize();
  }

  resize() {
    let currentHideNav = window.innerWidth >= 576;
    if (currentHideNav && !this.state.isDisplayPhone) {
      this.setState({
        ...this.state,
        isDisplayPhone: true,
      });
    } else if (!currentHideNav && this.state.isDisplayPhone) {
      this.setState({
        ...this.state,
        isDisplayPhone: false,
      });
    }
  }

  onCollapse = (collapsed) => {
    this.setState({ collapsed });
  };

  logout = () => this.props.logout(this.props.history);
  render() {
    const { location } = this.props;
    const pathname = "/" + location.pathname.split("/")[1];
    return (
      <div>
        <div className="logo-top">
          <Link to="/home?logo=true">
            <Avatar shape="square" size={50} src="/gebtam2.png" />
          </Link>
        </div>
        <Menu
          theme="dark"
          defaultSelectedKeys={[pathname]}
          selectedKeys={[pathname]}
          mode="vertical"
        >
          <Menu.Item key={AppPath.home} className="size-12">
            <Link to={AppPath.home}>
              {pathname === AppPath.home ? (
                <i className="anticon ">
                  <Avatar shape="square" size={20} src="/icon-feed-A.png" />
                </i>
              ) : (
                <i className="anticon ">
                  <Avatar
                    shape="square"
                    size={20}
                    src="/icon-feed-B.png"
                    className="not-active"
                  />
                </i>
              )}
              <span>ข่าวสาร</span>
            </Link>
          </Menu.Item>
          <Menu.Item key={AppPath.mycard} className="size-12">
            <Link to={AppPath.mycard}>
              {pathname === AppPath.mycard ? (
                <i className="anticon ">
                  <Avatar shape="square" size={20} src="/home.png" />
                </i>
              ) : (
                <i className="anticon ">
                  <Avatar
                    shape="square"
                    size={20}
                    src="/home_2.png"
                    className="not-active"
                  />
                </i>
              )}

              <span>My card</span>
            </Link>
          </Menu.Item>
          <Menu.Item key={AppPath.shop} className="size-12">
            <Link to={AppPath.shop}>
              {pathname === AppPath.shop ? (
                <i className="anticon ">
                  <Avatar shape="square" size={20} src="/shop.png" />
                </i>
              ) : (
                <i className="anticon ">
                  <Avatar
                    shape="square"
                    size={20}
                    src="/shop_2.png"
                    className="not-active"
                  />
                </i>
              )}

              <span>ร้านค้าทั้งหมด</span>
            </Link>
          </Menu.Item>

          <Menu.Item key={AppPath.promotion} className="size-12">
            <Link to={AppPath.promotion}>
              {pathname === AppPath.promotion ? (
                <i className="anticon ">
                  <Avatar shape="square" size={20} src="/mypro_1.png" />
                </i>
              ) : (
                <i className="anticon ">
                  <Avatar
                    shape="square"
                    size={20}
                    src="/mypro_0.png"
                    className="not-active"
                  />
                </i>
              )}

              <span>โปรโมรชั่นทั้งหมด</span>
            </Link>
          </Menu.Item>

          
          {/* <SubMenu
            className="size-12"
            title={
              <span>
                {pathname === AppPath.product ||
                pathname === AppPath.producthistory ? (
                  <i className="anticon ">
                    <Avatar shape="square" size={20} src="/feed.png" />
                  </i>
                ) : (
                  <i className="anticon ">
                    <Avatar
                      shape="square"
                      size={20}
                      src="/feed_2.png"
                      className="not-active"
                    />
                  </i>
                )}

                <span className="size-12 font-main">สินค้า</span>
              </span>
            }
          >
            <Menu.Item key={AppPath.product}>
              <Link to={AppPath.product}>
                <Icon type="stock" />
                <span className="size-12 font-main">สิ้นค้าทั้งหมด</span>
              </Link>
            </Menu.Item>
            <Menu.Item key={AppPath.producthistory}>
              <Link to={AppPath.producthistory}>
                <Icon type="history" />
                <span className="size-12 font-main">ประวัติ</span>
              </Link>
            </Menu.Item>
          </SubMenu> */}
          <Menu.Item key={AppPath.orderHistory}>
            <Link to={AppPath.orderHistory}>
              <Icon type="history" style={{ verticalAlign: -2 }} />
              <span className="size-12 font-main">
                ประวัติการสั่งซื้อสินค้า
              </span>
            </Link>
          </Menu.Item>
        </Menu>
      </div>
    );
  }
}

let mapStateToProps = (state) => ({
  user: state.user,
});
export default connect(mapStateToProps, { logout, checkLoginUser })(
  withRouter(LeftMenu)
);
