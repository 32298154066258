import React, { Component } from "react";
import { connect } from "react-redux";
import md5 from "react-native-md5";
import md5V2 from "md5";
import {
  Form,
  Icon,
  Input,
  Button,
  Row,
  Col,
  Avatar,
  message,
  Tooltip,
  Tabs,
  Tag,
  Spin,
} from "antd";
import { Link } from "react-router-dom";
import {
  loginUser,
  chkUser,
  registerUser,
  registerUpdateUser,
  registerUpdateUserEvent,
  loginUpdateUser,
  loginUpdateUserEvent,
} from "../store/actions/auth";
import { checkLoginUser } from "../store/actions/auth";
import AuthService from "../services/AuthService";
import ShopService from "../services/ShopService";
import PoweredByGebtam from "../components/gebtam/PoweredByGebtam";
import { BASE_URL } from "../config";

function hasErrors(fieldsError) {
  return Object.keys(fieldsError).some((field) => fieldsError[field]);
}

function callback(key) {
  // console.log(key);
}

class LoginEvent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      m_tel_number: localStorage.getItem("temp_tel")
        ? localStorage.getItem("temp_tel")
        : "",
      isFound: true,
      isTel: "",
      m_password: "",
      code: "",
      refOtp: [],
      confirm: "",
      notify_status: true,
      errors: {},
      isLoading: false,
      isOtpLoading: false,
      confirmDirty: false,
      tabKey: "1",
      shopName: "",
      shopImg: "/gebtam.png",
    };
  }

  componentDidMount() {
    const param = new URLSearchParams(this.props.history.location.search);
    const sid = param.get("sid");
    const path = param.get("path") ? param.get("path") : "/";
    if (sid === "3820001") {
      this.handleCheckShopDetails(sid);
      if (localStorage.getItem("m_tel_number")) {
        this.props.history.push(
          `/updateProfileEvent?tel=${localStorage.getItem("m_tel_number")}&sid=${sid}`
        );
      } else {
        this.props.history.push(`loginEvent?sid=${sid}`);
      }
    } else {
      if (this.props.user.isAuthenticated) {
        if (path !== "/") {
          this.props.history.push(`${path}`);
        } else {
          this.setState({ isFound: false });
        }
      } else {
        if (path !== "/") {
          this.props.history.push(`${path}`);
        } else {
          // this.props.history.push(`/loginEvent`);
          this.setState({ isFound: false });
        }
      }
    }
    const m_tel = localStorage.getItem("temp_tel");
    if (m_tel) {
      this.setState({
        m_tel_number: m_tel,
      });
    }
    this.props.form.validateFields();
  }

  //เมื่อเกิดเหตุการณ์ที่ store
  componentWillReceiveProps(nextProps) {
    const { location } = this.props;
    const { location: nextLocation } = nextProps;
    if (nextLocation.search !== location.search) {
      window.location.reload();
    }
    // if (nextProps.user && nextProps.user.isAuthenticated) {
    //   const param = new URLSearchParams(this.props.history.location.search);
    //   const path = param.get("path") || "/";
    //   this.props.history.push(`${path}`);
    // }
    if (
      nextProps.errors.loginError &&
      nextProps.errors.isLogin !== this.props.errors.isLogin
    ) {
      this.setState({
        isLoading: false,
      });
      message.warning(nextProps.errors.loginError);
    }

    if (
      nextProps.errors.registerError &&
      nextProps.errors.isRegister !== this.props.errors.isRegister
    ) {
      this.setState({
        isLoading: false,
      });
      message.warning(nextProps.errors.registerError);
    }
  }

  handleToShop = () => {
    const param = new URLSearchParams(this.props.history.location.search);
    const path = param.get("path") ? `/shared${param.get("path")}` : "/";

    this.props.history.push(`${path}`);
  };

  handleGoBack = () => {
    this.setState({ isLoading: true });

    this.setState(
      { isRig: false, isPwd: false, tabKey: "1", isLoading: false },
      () => {
        this.props.form.validateFields();
      }
    );
  };

  handleSubmit = (e) => {
    e.preventDefault();
    const param = new URLSearchParams(this.props.history.location.search);
    const sid = param.get("sid");
    if (!this.state.isRig) {
      this.props.form.validateFields((err, values) => {
        if (!err) {
          let newState = { ...this.state };
          newState.m_password = md5V2(
            // eslint-disable-next-line no-control-regex
            `${md5.str_md5(values.m_password).split(/\u0000/)[0]}AppDD2018`
          );

          if (sid === "3800001" && this.state.m_tel_number) {
            newState.fromWeb = true;
            newState.m_tel_number = this.state.m_tel_number;
            newState.isLoading = true;
            this.setState(newState);
            this.props.loginUpdateUser(newState, this.props.history);
          } else if (sid === "3820001" && this.state.m_tel_number) {
            newState.fromWeb = true;
            newState.m_tel_number = this.state.m_tel_number;
            newState.isLoading = true;
            this.setState(newState);
            this.props.loginUpdateUserEvent(newState, this.props.history, sid);
          } else {
            newState.fromWeb = true;
            newState.m_tel_number = this.state.m_tel_number;
            newState.isLoading = true;
            this.setState(newState);
            this.props.loginUser(newState, this.props.history);
          }
        }
      });
    } else {
      this.props.form.validateFieldsAndScroll((err, values) => {
        if (!err) {
          this.setState({ isLoading: true });
          const newValues = { ...values };
          newValues.notify_status = newValues.notify_status ? 1 : 0;
          newValues.m_password = md5V2(
            // eslint-disable-next-line no-control-regex
            `${md5.str_md5(values.m_password).split(/\u0000/)[0]}AppDD2018`
          );

          if (sid === "3800001" && this.state.m_tel_number) {
            newValues.fromWeb = true;
            newValues.m_tel_number = this.state.m_tel_number;
            newValues.notify_status = 1;
            delete newValues.confirm;
            this.props.registerUpdateUser(newValues, this.props.history);
          } else if (sid === "3820001" && this.state.m_tel_number) {
            newValues.fromWeb = true;
            newValues.m_tel_number = this.state.m_tel_number;
            newValues.notify_status = 1;
            delete newValues.confirm;
            this.props.registerUpdateUserEvent(
              newValues,
              this.props.history,
              sid
            );
          } else {
            newValues.fromWeb = true;
            delete newValues.confirm;
            this.props.registerUser(newValues, this.props.history);
          }
        }
      });
    }
  };

  // ปุ่ม ถัดไป
  handleCheckPhone = () => {
    const param = new URLSearchParams(this.props.history.location.search);
    const sids = param.get("sid");
    this.setState({ isLoading: true });
    this.props.form.validateFields((err, values) => {
      if (err) {
        console.log("err", err);
      }
      const m_tel = localStorage.getItem("temp_tel");
      if (values.m_tel_number) {
        this.setState({
          // isOtp: true,
          isTel: values.m_tel_number,
          m_tel_number: values.m_tel_number,
          isLoading: false,
        });
      } else {
        if (m_tel) {
          this.setState({
            isTel: m_tel,
            m_tel_number: m_tel,
            isLoading: false,
          });
        } else {
          message.warning("กรุณาลงทะเบียนเบอร์ เพื่อเข้าใช้งานครั้งแรก");
          this.setState({
            isLoading: false,
          });
        }
      }
      this.checkShopDetail(sids);
    });
  };

  handleCheckShopDetails = async (sid) => {
    await ShopService.getCheckShopDetail({ sid: sid })
      .then((res) => {
        if (res.success) {
          this.setState({
            shopName: res.result.shop_name,
            shopImg: BASE_URL + res.result.path_shop_logo + res.result.head_pic,
          });
        } else {
          this.setState({
            shopName: "ไม่พบร้านค้า",
            shopImg: "/gebtam.png",
          });
          message.error("ไม่พบร้านค้า");
        }
      })
      .catch((error) => {
        this.setState({ isLoading: false });
        console.log(error);
        message.error("เกิดข้อผิดพลาด");
      });
  };

  // handleCheckUser = () => {
  //   this.props.form.validateFields(async (err, values) => {
  //     if (!err) {
  //       this.setState({ isLoading: true });
  //       this.props
  //         .chkUser(values)
  //         .then((res) => {
  //           if (res.success) {
  //             this.setState({
  //               isRig: true,
  //               isPwd: true,
  //               tabKey: "2",
  //               m_tel_number: values.m_tel_number,
  //             });
  //           } else {
  //             this.setState({
  //               isPwd: true,
  //               tabKey: "2",
  //               m_tel_number: values.m_tel_number,
  //             });
  //           }
  //           this.setState({ isLoading: false });
  //         })
  //         .catch((error) => {
  //           this.setState({ isLoading: false });
  //         });
  //     }
  //   });
  // };

  handleSendOtp = () => {
    const m_tel_number = this.props.form.getFieldValue("m_tel_number");
    const telError = this.props.form.getFieldError("m_tel_number");
    if (!telError && !m_tel_number) {
      return message.error("กรุณาระบุหมายเลขโทรศัพท์");
    } else if (telError) {
      return message.error("กรุณาระบุหมายเลขโทรศัพท์ให้ถูกต้อง");
    }
    const postData = { to: m_tel_number, user: "test" };
    this.setState({ isOtpLoading: true });
    ShopService.sendVerifyOtpEvent(postData)
      .then((resp) => {
        const data = resp;
        if (data.success) {
          this.setState({ refOtp: [...this.state.refOtp, data.ref] });
          message.success("ส่ง OTP สำเร็จ");
        } else {
          message.warning("ไม่สามารถส่ง sms ได้");
        }
        this.setState({ isOtpLoading: false });
      })
      .catch((error) => {
        this.setState({ isOtpLoading: false });
        console.log(error);
        message.error("เกิดข้อผิดพลาด");
      });
  };

  compareToFirstPassword = (rule, value, callback) => {
    const { form } = this.props;
    if (value && value !== form.getFieldValue("m_password")) {
      callback("รหัสผ่านไม่ตรงกัน");
    } else {
      callback();
    }
  };

  validateToNextPassword = (rule, value, callback) => {
    const { form } = this.props;
    if (value && this.state.confirmDirty) {
      form.validateFields(["confirm"], { force: true });
    }
    callback();
  };

  //Check OTP
  checkOtp = async (m_tel_number, ref, code) => {
    const param = new URLSearchParams(this.props.history.location.search);
    const sid = param.get("sid");
    await ShopService.getCheckOtpShop({
      to: m_tel_number,
      ref: ref,
      code: code,
    })
      .then((res) => {
        if (res.success) {
          this.setState({
            isLoading: false,
            isTel: m_tel_number,
            isOtp: true,
          });
          localStorage.setItem("m_tel_number", m_tel_number);
          localStorage.setItem("temp_tel", m_tel_number);
          message.success("ยืนยัน OTP สำเร็จ");
          this.props.history.push(
            `/updateProfileEvent?tel=${m_tel_number}&sid=${sid}`
          );
        } else {
          if (res.result.ERROR_CODE === "CE") {
            message.warning("OTP หมดอายุ");
          } else if (res.result.ERROR_CODE === "WC") {
            message.warning("OTP ไม่ถูกต้อง");
          } else if (res.result.ERROR_CODE === "WF") {
            message.warning("กรุณากดส่ง OTP");
          } else {
            message.warning("ไม่สามารถส่ง sms ได้");
          }
          this.setState({
            isLoading: false,
            isTel: m_tel_number,
          });
        }
      })
      .catch((error) => {
        this.setState({ isLoading: false });
        console.log(error);
        message.error("เกิดข้อผิดพลาด");
      });
  };

  // ปุ่ม ย้อนกลับ
  handleBackFormPhone = () => {
    this.setState({ isLoading: true });
    this.setState({
      isTel: null,
      isLoading: false,
    });
  };

  // ปุ่ม ถัดไป < OTP >
  handleCheckUser = () => {
    this.setState({ isLoading: true });
    this.props.form.validateFields((err, values) => {
      this.checkOtp(values.m_tel_number, this.state.refOtp, values.code);
    });
  };

  renderLoddingForm() {
    return (
      <div
        className="text-center mb-3"
        style={{
          borderRadius: "2rem",
          border: "1px solid rgb(212, 212, 212)",
          boxShadow: "0px 0px 3px 0px rgb(212, 212, 212)",
          paddingTop: "0rem",
          color: "rgba(240, 57, 117, 1)",
        }}
      >
        <Tooltip onClick={() => window.location.reload()} placement="bottom">
          {" "}
          <Icon
            type="exclamation-circle"
            style={{
              paddingTop: 10,
              cursor: "pointer",
              type: "flex",
              fontSize: "50px",
              color: "rgba(240, 57, 117, 1)",
            }}
          />
          <p align="center">Not found Event</p>
        </Tooltip>
      </div>
    );
  }

  renderOtpForm() {
    const {
      getFieldDecorator,
      getFieldsError,
      isFieldTouched,
      getFieldError,
    } = this.props.form;
    const telError =
      isFieldTouched("m_tel_number") && getFieldError("m_tel_number");
    const otpError = isFieldTouched("code") && getFieldError("code");
    const { isOtpDisabled, refOtp } = this.state;
    return (
      <div>
        <Form.Item
          validateStatus={telError ? "error" : ""}
          help={telError || ""}
        >
          <Row gutter={[8, 16]}>
            <Col span={16}>
              {getFieldDecorator("m_tel_number", {
                initialValue: this.state.m_tel_number,
                rules: [
                  { required: true, message: "ระบุหมายเลขโทรศัพท์" },
                  { len: 10, message: "หมายเลขโทรศัพท์ไม่ถูกต้อง" },
                ],
              })(
                <Input
                  prefix={
                    <Icon type="phone" style={{ color: "rgba(0,0,0,.25)" }} />
                  }
                  shape="round"
                  placeholder="Phone"
                  disabled
                />
              )}
            </Col>
            <Col span={8}>
              <Spin spinning={this.state.isOtpLoading}>
                <Button
                  type="primary"
                  shape="round"
                  onClick={this.handleSendOtp}
                  disabled={isOtpDisabled}
                >
                  ส่ง otp
                </Button>
              </Spin>
            </Col>
          </Row>{" "}
          <Row span={24}>
            <div style={{ marginBottom: "-1rem" }}>รหัสอ้างอิง OTP :</div>
            {refOtp.map((item, i) => (
              <Tag key={i} style={{ color: "rgba(0,0,0,.25)" }}>
                {item}
              </Tag>
            ))}
          </Row>
        </Form.Item>
        <Form.Item
          validateStatus={otpError ? "error" : ""}
          help={otpError || ""}
        >
          {getFieldDecorator("code", {
            rules: [{ required: true, message: "กรอก OTP" }],
          })(
            <Input
              prefix={<Icon type="key" style={{ color: "rgba(0,0,0,.25)" }} />}
              placeholder="OTP"
            />
          )}
        </Form.Item>
        <Form.Item>
          <Row gutter={[8, 16]} justify="center">
            <Col span={12}>
              <Button
                type="primary"
                shape="round"
                className="login-form-button font-main"
                onClick={() => this.handleBackFormPhone()}
              >
                ย้อนกลับ
              </Button>
            </Col>{" "}
            <Col span={12}>
              <Button
                type="primary"
                shape="round"
                className="login-form-button font-main"
                disabled={hasErrors(getFieldsError())}
                onClick={() => this.handleCheckUser()}
              >
                ถัดไป
              </Button>
            </Col>
          </Row>
        </Form.Item>
      </div>
    );
  }

  render() {
    const {
      getFieldDecorator,
      getFieldsError,
      getFieldError,
      isFieldTouched,
    } = this.props.form;
    const { TabPane } = Tabs;
    // Only show error after a field is touched.
    const telError =
      isFieldTouched("m_tel_number") && getFieldError("m_tel_number");
    const { isLoading, isTel, isOtp, isFound, shopImg, shopName } = this.state;
    return (
      <div className="login-page font-sub">
        <Form onSubmit={this.handleSubmit} className="login-form">
          <Spin spinning={this.state.isLoading}>
            <div type="flex" justify="center" className="text-center mb-3">
              <Tooltip placement="top">
                <Avatar shape="square" size={64} src={shopImg} />
              </Tooltip>
              <div
                align="center"
                style={{
                  paddingTop: "1rem",
                  type: "flex",
                  fontSize: "20px",
                  color: "rgba(240, 57, 117, 1)",
                }}
              >
                {shopName}
              </div>
            </div>
            {!isFound ? (
              <>{this.renderLoddingForm()}</>
            ) : !isTel ? (
              <>
                <Form.Item
                  validateStatus={telError ? "error" : ""}
                  help={telError || ""}
                >
                  <Row gutter={16}>
                    {getFieldDecorator("m_tel_number", {
                      initialValue: this.state.m_tel_number,
                      rules: [
                        { required: true, message: "ระบุหมายเลขโทรศัพท์" },
                        { len: 10, message: "หมายเลขโทรศัพท์ไม่ถูกต้อง" },
                      ],
                    })(
                      <Input
                        prefix={
                          <Icon
                            type="phone"
                            style={{ color: "rgba(0,0,0,.25)" }}
                          />
                        }
                        placeholder="Phone"
                      />
                    )}
                  </Row>
                </Form.Item>
                <Form.Item>
                  <Row gutter={16}>
                    <Button
                      type="primary"
                      shape="round"
                      loading={isLoading}
                      className="login-form-button font-main"
                      disabled={hasErrors(getFieldsError())}
                      onClick={() => this.handleCheckPhone()}
                    >
                      ถัดไป
                    </Button>
                  </Row>{" "}
                </Form.Item>
              </>
            ) : (
              <>{this.renderOtpForm()}</>
            )}
          </Spin>
          <PoweredByGebtam />
        </Form>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.user,
  errors: state.errors,
});

const LoginEventForm = Form.create({ name: "login_event" })(LoginEvent);

export default connect(mapStateToProps, {
  loginUser,
  checkLoginUser,
  chkUser,
  registerUser,
  loginUpdateUser,
  registerUpdateUser,
  registerUpdateUserEvent,
  loginUpdateUserEvent,
})(LoginEventForm);
