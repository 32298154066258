import React, { useState } from "react";
import { Avatar } from "antd";
import { Link } from "react-router-dom";

import { TextEllip } from "../../../styles/components/style";
import { AppPath } from "../../../router/path";
import useMediaQuery from "../../hook/useMediaQuery";

function FeedShopLogo({ pathLogo, shopName, shopId, size, isNotResize }) {
  const [path, setPath] = useState(pathLogo);
  const isMedaiSm = useMediaQuery("(max-width: 768px)");
  const customSize = isMedaiSm && !isNotResize ? size - 10 : size;
  return (
    <Link to={`${AppPath.shop}/${shopId}`}>
      <div className="round  pr-2 bg-w d-flex fit-content align-center mx-auto">
        <Avatar
          src={path}
          alt="shop logo"
          shape="circle"
          style={{
            width: customSize,
            height: customSize,
            minWidth: customSize,
            minHeight: customSize,
          }}
          onError={() => setPath("/gebtam.png")}
        />
        <TextEllip
          lineNum={1}
          className={`${
            isNotResize ? "" : "sm-pl-0  sm-size-06"
          } size-08 pl-1 bold c-b `}
        >
          {shopName}
        </TextEllip>
      </div>
    </Link>
  );
}

FeedShopLogo.defaultProps = {
  size: 35,
};

export default FeedShopLogo;
