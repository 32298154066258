import {
  GET_SHOPS_PENDING,
  GET_SHOPS_SUCCESS,
  GET_SHOPS_ERROR,
  GET_ALL_SHOPS_SUCCESS,
  UPDATE_SHOP_FOLLOW,
  SET_SHOP_DETAIL,
  GET_SHOPS_FOLLOW_PENDING,
  GET_SHOPS_FOLLOW_SUCCESS,
  GET_ALL_SHOPS_FOLLOW_SUCCESS,
  GET_SHOPS_FOLLOW_ERROR,
  SET_SHOPS_POINT,
} from "../actions/types";

const shops = (state = {}, action) => {
  const newData = { ...state.data };
  const newDataDetail = { ...state.dataDetail };
  const newDataFollow = { ...state.dataFollow };
  switch (action.type) {
    case GET_SHOPS_PENDING:
      return {
        ...state,
        pending: true,
      };
    case GET_SHOPS_SUCCESS:
      return {
        ...state,
        pending: false,
        recordsTotal: action.payload.recordsTotal,
        data: { ...state.data, ...action.payload.data },
      };
    case GET_ALL_SHOPS_SUCCESS:
      return {
        ...state,
        hasMore: false,
        pending: false,
        recordsTotal: action.payload.recordsTotal,
        data: { ...state.data, ...action.payload.data },
      };
    case GET_SHOPS_ERROR:
      return {
        ...state,
        pending: false,
        error: action.error,
      };
    case UPDATE_SHOP_FOLLOW:
      if (newData[action.payload.sid])
        newData[action.payload.sid].followed = action.payload.followed;
      if (newDataDetail[action.payload.sid])
        newDataDetail[action.payload.sid].followed = action.payload.followed;
      if (newDataFollow[action.payload.sid])
        newDataFollow[action.payload.sid].followed = action.payload.followed;
      return {
        ...state,
        dataDetail: newDataDetail,
        data: newData,
        dataFollow: newDataFollow,
      };
    case SET_SHOP_DETAIL:
      newDataDetail[action.payload.sid] = action.payload;
      return {
        ...state,
        pending: false,
        dataDetail: newDataDetail,
      };
    case GET_SHOPS_FOLLOW_PENDING:
      return {
        ...state,
        pending: true,
      };
    case GET_SHOPS_FOLLOW_SUCCESS:
      return {
        ...state,
        pending: false,
        dataFollow: { ...state.dataFollow, ...action.payload.data },
      };
    case GET_ALL_SHOPS_FOLLOW_SUCCESS:
      return {
        ...state,
        hasMoreFollow: false,
        pending: false,
        dataFollow: { ...state.dataFollow, ...action.payload.data },
      };
    case GET_SHOPS_FOLLOW_ERROR:
      return {
        ...state,
        pending: false,
        error: action.error,
      };
    case SET_SHOPS_POINT:
      if (newDataDetail[action.payload.sid]) {
        newDataDetail[action.payload.sid].member_stamp =
          action.payload.member_stamp;
      } else {
        newDataDetail[action.payload.sid] = {
          sid: action.payload.sid,
          member_stamp: action.payload.member_stamp,
        };
      }
      if (newDataFollow[action.payload.sid])
        newDataFollow[action.payload.sid].member_stamp =
          action.payload.member_stamp;
      return {
        ...state,
        dataDetail: newDataDetail,
        dataFollow: newDataFollow,
      };
    default:
      return state;
  }
};
export default shops;
