import React, { useState } from "react";
import { InputNumber, Modal, TimePicker, Button } from "antd";

import OrderNextStepBtn from "./OrderNextStepBtn";
import PriceItem from "./PriceItem";
import { checkPostActiveByTime } from "../../../helpers/time";

export default function OrderOption({
  sid,
  price,
  stock,
  nextStep,
  count,
  setTotal,
  setCount,
  total,
  openTime,
  closeTime,
  pickupTime,
  setPickupTime,
}) {
  const [openTimePicker, setOpenTimePicker] = useState(false);
  const isPostActive = checkPostActiveByTime(openTime, closeTime);
  const handleChange = (e) => {
    setTotal((price * e).toFixed(2));
    setCount(e);
  };

  const checkPostActive = () => {
    if (isPostActive) {
      setOpenTimePicker(true);
      // nextStep(2);
    } else {
      Modal.error({
        title: "ไม่สามารถสั่งซื้อได้",
        content: "ไม่สามารถสั่งซื้อสินค้าได้ เนื่องจากไม่ได้อยู่ในช่วงเวลา",
      });
    }
  };

  const onTimePickerChange = (time) => {
    setPickupTime(new Date(time));
  };

  const onCloseTimePicker = () => {
    const now = new Date();
    const minPickupTime = new Date().setMinutes(now.getMinutes() + 15);
    if (minPickupTime > pickupTime.getTime()) {
      return Modal.warn({
        title: "เวลารับสินค้าไม่ถูกต้อง",
        content: "เวลารับสินค้าต้องหลังจากเวลาปัจจุบันอย่างน้อย 15 นาที",
        zIndex: 3000,
      });
    }
    setOpenTimePicker(false);
    nextStep(2);
  };

  const getDisabledHours = () => {
    const hoursNow = new Date().getHours();
    return [...new Array(hoursNow).keys()];
  };

  const getDisabledMinutes = (h) => {
    const hoursNow = new Date().getHours();
    const minutesNow = new Date().getMinutes();
    if (hoursNow === h || !h) {
      return [...new Array(minutesNow + 16).keys()];
    } else if (hoursNow === h - 1 && minutesNow + 16 > 60) {
      //เผื่อ 1 นาที
      return [...new Array(minutesNow + 16 - 60).keys()];
    }
    return [];
  };

  return (
    <>
      <div className="text-center mt-3" style={{ paddingBottom: "4rem" }}>
        <span className="font-main c-main size-20 weight-200">
          จำนวนการซื้อ
        </span>
        <br />
        <InputNumber
          autoFocus
          className="text-center bold size-50 h-5 input-h-5 raduis-1 font-main"
          style={{ width: "auto", maxWidth: "10rem" }}
          size="large"
          defaultValue={1}
          min={0}
          max={stock}
          onChange={handleChange}
        />
        <br />
        <PriceItem
          total={total}
          price={price}
          count={count}
          layout={{ sm: 18, md: 12, span: 12 }}
        />
      </div>
      {sid === "3280001" ||
      sid === "3930001" ||
      sid === "3940001" ||
      sid === "3950001" ? (
        <>
          <OrderNextStepBtn
            onClick={() => {
              nextStep(2);
            }}
          />
        </>
      ) : (
        <>
          {" "}
          <div className="text-center">
            <p className="mb-0">
              * หมายเหตุเวลารับสินค้าต้องหลังจากเวลาปัจจุบัน 15 นาที
            </p>

            <TimePicker
              open={openTimePicker}
              className="mt-3 radius-base"
              onChange={onTimePickerChange}
              format={"HH:mm"}
              popupClassName="customtime-width-200"
              placeholder="ระบุเวลารับสินค้า"
              style={{ width: 200, borderRadius: 10, opacity: 0 }}
              disabledHours={getDisabledHours}
              disabledMinutes={getDisabledMinutes}
              addon={() => (
                <div className="text-right">
                  {" "}
                  <Button
                    onClick={onCloseTimePicker}
                    size="small"
                    type="primary"
                    className="font-main ml-auto "
                  >
                    ยืนยัน
                  </Button>
                </div>
              )}
            />
          </div>
          <OrderNextStepBtn onClick={checkPostActive} />
        </>
      )}
    </>
  );
}
