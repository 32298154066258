export const AppPath = {
  home: "/home",
  login: "/login",
  app: "/app",
  loginEvent: "/loginEvent",
  checkin: "/checkin",
  qrevent: "/qrevent",
  sms: "/sms",
  register: "/register",
  profile: "/profile",
  forgot: "/forgot",
  error: "/error",
  product: "/product",
  shop: "/shop",
  producthistory: "/producthistory",
  mycard: "/mycard",
  verify: "/verify",
  korat: "/KoratCity",
  aui: "/AuiSaeTueng",
  feed: "/feed",
  order: "/order",
  orderHistory: "/orderHistory",
  shared: "/shared",
  promotion: "/promotion",
  updateProfile: "/updateProfile",
  updateProfileEvent: "/updateProfileEvent",
};
