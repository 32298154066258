import React, { useState } from "react";
import { CardRatio, CardItem } from "../../../styles/components/card";
import { Row, Col, Avatar } from "antd";
import FeedShopLogo from "./FeedShopLogo";
import ShopService from "../../../services/ShopService";
import { TextEllip } from "../../../styles/components/style";
import { Link } from "react-router-dom";
import { AppPath } from "../../../router/path";
import FeedOrderBtn from "./FeedOrderBtn";

export default function FeedCardTextAndImage({
  feed,
  pathShopLogo,
  shopName,
  isNotResize,
  isNotShowOrderBtn,
}) {
  const imagePath = ShopService.getLogoHasPath(
    feed.path_feed_pic,
    feed.feed_pic
  );
  const [imageFeed, setImageFeed] = useState(imagePath);

  return (
    <Link to={`${AppPath.feed}/${feed.feed_node}`} className="w-100">
      <CardRatio pdTop="100%" bg="#fff">
        <CardItem>
          <Row
            type="flex"
            className="pt-3 text-center w-100  h-100 rela over-hide"
          >
            <Col span={24} className="bg-w">
              <FeedShopLogo
                isNotResize={isNotResize}
                pathLogo={pathShopLogo}
                shopName={shopName}
                shopId={feed.sid}
              />

              <TextEllip
                lineNum={1}
                className="sm-size-08 size-12 bold mt-2 mb-1"
                style={{ color: feed.feed_text_2_color }}
              >
                {feed.feed_text_2}
              </TextEllip>
            </Col>
            <Col className="flex-grow-1 d-flex align-center" span={24}>
              <Avatar
                src={imageFeed}
                shape="square"
                alt="feed photo"
                className="h-auto "
                style={{ width: "100%", height: "100%" }}
                onError={() => setImageFeed("/gebtam.png")}
              />
            </Col>
            {feed.price && !isNotShowOrderBtn && (
              <FeedOrderBtn
                path={`${AppPath.feed}/${feed.feed_node}${AppPath.order}`}
              />
            )}
          </Row>
        </CardItem>
      </CardRatio>
    </Link>
  );
}
