import React, { Component } from "react";
import { connect } from "react-redux";
import { Menu, Icon, Row, Avatar, Affix, Button, Drawer } from "antd";
import { withRouter, Link } from "react-router-dom";

import { logout, checkLoginUser } from "../../store/actions/auth";
import { AppPath } from "../../router/path";
import ShopService from "../../services/ShopService";
import LeftMenu from "./LeftMenu";

// const { Header } = Layout;
import "../../index.css";
const { SubMenu } = Menu;

class LayoutHeader extends Component {
  constructor(props) {
    super(props);
    this.state = { collapsed: false };
  }
  componentWillMount() {
    if (!this.props.user.isAuthenticated) this.props.checkLoginUser();
  }

  state = {
    current: "mail",
    visible: false,
  };
  showDrawer = () => {
    this.setState({
      visible: true,
    });
  };
  onClose = () => {
    this.setState({
      visible: false,
    });
  };

  toggleCollapsed = () => {
    this.setState({
      collapsed: !this.state.collapsed,
    });
  };

  logout = () => this.props.logout(this.props.history);

  onOpengebtam =() =>window.open('https://www.gebtam.com/#!/forshop')
  render() {
    const { location, user } = this.props;
    const pathname = "/" + location.pathname.split("/")[1];
    return (
      // <Header theme="dark" style={{ background: '#fff', padding: 0 }} >
      <Affix className="b-main">
        <Button
          type="default"
          className="barsMenu bg-sub c-w"
          onClick={this.showDrawer}
        >
          <Icon type={this.state.collapsed ? "menu-unfold" : "menu-fold"} />
        </Button>
        <Drawer
          placement="left"
          closable={true}
          onClose={this.onClose}
          visible={this.state.visible}
        >
          <LeftMenu />
        </Drawer>
        <Menu
          onClick={this.handleClick}
          selectedKeys={pathname}
          theme="dark"
          mode="horizontal"
          className="text-right pr-2 font-main size-12 pt-2 pb-2"
        >
          <Menu.Item className="size-15 cursor-default"><Button shape="round" className="size-15 bg-sub no-border line-h-15" onClick={this.onOpengebtam}>สำหรับร้านค้า</Button></Menu.Item>
          {!user.isAuthenticated ? (
            <Menu.Item key="9" className="size-12">
              <Link to="/login">
                <Icon type="login" />
                <span>Login</span>
              </Link>
            </Menu.Item>
          ) : (
            <SubMenu
              title={
                <span className="submenu-title-wrapper font-main">
                  <Row type="flex">
                    <div
                      style={{
                        maxWidth: "6rem",
                        wordSpacing: "nowarp",
                        textOverflow: "ellipsis",
                        overflow: "hidden",
                      }}
                    >
                      <Avatar
                        style={{
                          width: "1.8rem",
                          height: "1.8rem",
                          marginRight: ".5rem",
                          border: "1.5px solid",
                        }}
                        src={ShopService.getLogoHasPath(
                          user.profile.m_pic_path,
                          user.profile.m_pic
                        )}
                        className="pointer"
                      />
                      {user.profile.m_name} {user.profile.m_lastname}
                    </div>
                    <Icon type="setting" className="ml-2 mr-0 mt-3" />
                  </Row>
                </span>
              }
            >
              <Menu.Item key={AppPath.profile} className="size-12 font-main">
                <Link to={AppPath.profile}>
                  <Icon type="idcard" />
                  <span>ข้อมูลส่วนตัว</span>
                </Link>
              </Menu.Item>

              <Menu.Item
                key="10"
                onClick={this.logout}
                className="size-12 font-main"
              >
                <Icon type="logout" />
                <span>Logout</span>
              </Menu.Item>
            </SubMenu>
          )}
        </Menu>
      </Affix>
      // </Header>
    );
  }
}
let mapStateToProps = (state) => ({
  user: state.user,
});
export default connect(mapStateToProps, { logout, checkLoginUser })(
  withRouter(LayoutHeader)
);
