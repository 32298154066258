import React from "react";
import { Layout } from "antd";
const { Footer } = Layout;
const LayoutFooter = () => {
  return (
    <Footer
      style={{ textAlign: "center", padding: 0 }}
      className="font-main size-12"
    >
      © 2018 Gebtam. All Rights Reserved
    </Footer>
  );
};

export default LayoutFooter;
