import React, { Component } from "react";
import { connect } from "react-redux";
import {
  PageHeader,
  Row,
  Select,
  message,
  Button,
  Col,
  Icon,
  Tooltip,
} from "antd";

import { MainTitleColor } from "../styles/components/style";
import Main from "./Main";
import AuthService from "../services/AuthService";
import FeedSkeleton from "../components/feed/FeedSkeleton";
import { getBreakpoint } from "../helpers/util";
import FeedNearCard from "../components/home/FeedNearCard";
import FeedNearGrid from "../components/home/FeedNearGrid";
import FeedAllGrid from "../components/home/FeedAllGrid";
import FeedAllCard from "../components/home/FeedAllCard";

const { Option } = Select;

// const { Search } = Input;
class Home extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showFeedType: 1,
      showFeedStyle: 1,
      itemHeight: 200,
      renderCard: false,
    };
  }
  componentWillMount() {
    const { history } = this.props;
    const location = history.location;
    const query = new URLSearchParams(location.search);
    const logo = query.get("logo");
    const feed = query.get("feed");
    const style = query.get("style");
    if (feed === "all") {
      this.getAllFeeds();
    } else if (feed === "Near") {
      this.getFeedsNear();
    } else {
      this.getFeedsNear();
    }
    if (style === "grid") {
      this.setState({ showFeedStyle: 1 });
    } else {
      this.setState({ showFeedStyle: 2 });
    }
    if (logo === "true") {
    }
  }

  getFeedsNear() {
    this.setState({ showFeedType: 1 });
  }

  getAllFeeds() {
    const { user } = this.props;
    if (!user.isAuthenticated) {
      return message.warn(
        <span>
          ไม่พบข่าวสารกรุณาเข้าสู่ระบบ <span></span>
          <Button type="link" onClick={this.gotoLogin.bind(this)}>
            login
          </Button>
        </span>
      );
    }
    this.setState({ showFeedType: 2 });
  }

  gotoLogin() {
    const { history } = this.props;
    AuthService.goLogin(history);
  }

  checkShowFeedType() {
    const { showFeedType, itemHeight, showFeedStyle } = this.state;
    const itemWidth = itemHeight - 20;
    if (showFeedType === 0) {
      return (
        <FeedSkeleton
          onResize={this.onResize.bind(this)}
          itemHeight={itemHeight}
          itemWidth={itemWidth}
        />
      );
    } else if (showFeedType === 1) {
      if (showFeedStyle === 1) {
        return (
          <FeedNearGrid
            onResize={this.onResize.bind(this)}
            itemHeight={itemHeight}
            itemWidth={itemWidth}
            showFeedStyle={showFeedStyle}
          />
        );
      } else {
        return (
          <FeedNearCard
            onResize={this.onResize.bind(this)}
            itemHeight={itemHeight}
            itemWidth={itemWidth}
            showFeedStyle={showFeedStyle}
          />
        );
      }
    } else if (showFeedType === 2) {
      if (showFeedStyle === 1) {
        return (
          <FeedAllGrid
            onResize={this.onResize.bind(this)}
            itemHeight={itemHeight}
            itemWidth={itemWidth}
            showFeedStyle={showFeedStyle}
          />
        );
      } else {
        return (
          <FeedAllCard
            onResize={this.onResize.bind(this)}
            itemHeight={itemHeight}
            itemWidth={itemWidth}
            showFeedStyle={showFeedStyle}
          />
        );
      }
    }
  }

  handleChange(e) {
    const { user, history } = this.props;
    const location = history.location;
    const query = new URLSearchParams(location.search);
    const style = query.get("style");
    if (e.key === 1) {
      history.push(`/home?feed=Near&style=${style || "card"}`);
    } else if (e.key === 2) {
      history.push(`/home?feed=all&style=${style || "card"}`);
    }
    if (e.key === 2 && !user.isAuthenticated) {
      return message.warn(
        <span>
          ไม่พบข่าวสารกรุณาเข้าสู่ระบบ <span></span>
          <Button type="link" onClick={this.gotoLogin.bind(this)}>
            login
          </Button>
        </span>
      );
    } else {
      this.setState({
        showFeedType: e.key,
      });
    }
  }

  styleHandleChange(e) {
    console.log(e);
    const { history } = this.props;
    const location = history.location;
    const query = new URLSearchParams(location.search);
    const feed = query.get("feed");
    if (e === 1) {
      history.push(`/home?feed=${feed || "Near"}&style=grid`);
    } else if (e === 2) {
      history.push(`/home?feed=${feed || "Near"}&style=card`);
    }
    this.setState({
      showFeedStyle: e,
    });
  }

  onResize() {
    let width = window.innerWidth;
    const breakpoint = getBreakpoint(width);
    if (width <= 320) {
      this.setState({ itemHeight: 150 });
    } else if (width <= 380) {
      this.setState({ itemHeight: 170 });
    } else if (breakpoint === "xxl") {
      this.setState({ itemHeight: 310 });
    } else if (breakpoint === "xl") {
      this.setState({ itemHeight: 310 });
    } else if (breakpoint === "lg") {
      this.setState({ itemHeight: 290 });
    } else if (breakpoint === "md") {
      this.setState({ itemHeight: 230 });
    } else if (breakpoint === "sm") {
      this.setState({ itemHeight: 260 });
    } else if (breakpoint === "xs") {
      this.setState({ itemHeight: 220 });
    }
    console.log(breakpoint);
    // console.log(width);
  }

  render() {
    const { showFeedType, showFeedStyle } = this.state;

    return (
      <Main title="หน้าแรก">
        <PageHeader
          className="b-sub"
          style={{
            marginBottom: "2rem",
          }}
        >
          <Row type="flex" justify="space-between" align="middle">
            <MainTitleColor>
              ข่าวสาร
              {/* {showFeedType === 1 ? "ใกล้เคียง" : "ทั้งหมด"} */}
              <Select
                labelInValue
                defaultValue={{ key: showFeedType }}
                style={{ width: 150 }}
                onChange={this.handleChange.bind(this)}
                className="font-main size-12 ml-2"
                value={{ key: showFeedType }}
              >
                <Option value={1} className="font-sub">
                  ใกล้เคียง
                </Option>
                <Option value={2} className="font-sub">
                  ทั้งหมด
                </Option>
              </Select>
            </MainTitleColor>

            <div>
              <Tooltip title="แสดงผลแบบการ์ด">
                <Button
                  className={`${showFeedStyle === 2 ? "active" : ""}`}
                  size="small"
                  onClick={() => this.styleHandleChange(2)}
                >
                  <Icon type="credit-card" className="d-flex" />
                </Button>
              </Tooltip>
              <Tooltip title="แสดงผลแบบกริด">
                <Button
                  className={`${showFeedStyle === 1 ? "active" : ""}`}
                  size="small"
                  onClick={() => this.styleHandleChange(1)}
                >
                  <Icon type="appstore" className="d-flex" />
                </Button>
              </Tooltip>
            </div>

            {/* <Search
              className="ml-auto"
              placeholder="ค้นหาข่าวสาร"
              onSearch={(value) => console.log(value)}
              style={{ width: 200, height: 30 }}
              size="small"
            /> */}
          </Row>
        </PageHeader>
        <Row type="flex" justify="center">
          <Col span={24} md={24} lg={23} xl={23} xxl={22}>
            {this.checkShowFeedType()}
          </Col>
        </Row>
      </Main>
    );
  }
}

const mapStateToProps = (state) => ({
  feeds: state.feeds,
  user: state.user,
});

export default connect(mapStateToProps)(Home);
// export default Home
