import React from "react";
import FeedCardComment from "./FeedCardComment";

export default function FeedCardCommentList({ comments, onReply }) {
  return (
    <div
      className="pd-2 custom-comment"
      style={{ maxHeight: 400, overflow: "auto" }}
    >
      {comments.map((comment) => {
        return (
          <FeedCardComment
            key={comment.feed_node}
            isUpNode={comment.isUpNode}
            comment={comment}
            onReply={onReply}
          />
        );
      })}
    </div>
  );
}
