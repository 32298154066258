import React, { useState } from "react";
import { Row, Col, Avatar, Comment, Icon } from "antd";
import { TextEllip, TextTime } from "../../../styles/components/style";
import { setFeedDate } from "../../../helpers/time";
import useMediaQuery from "../../hook/useMediaQuery";
import { Link } from "react-router-dom";
import { AppPath } from "../../../router/path";

function FeedCardHeader({
  pathLogo,
  shopName,
  shopId,
  size,
  time,
  isNotResize,
}) {
  const [path, setPath] = useState(pathLogo);
  const isMedaiSm = useMediaQuery("(max-width: 768px)");
  const customSize = isMedaiSm && !isNotResize ? size - 10 : size;
  return (
    <Row
      type="flex"
      justify="space-between"
      align="middle"
      className="bg-w  pt-1 pl-2 pr-2"
    >
      <Col xs={22} sm={22} span={23}>
        <Comment
          className="feed-comment-top"
          style={{ padding: 0 }}
          avatar={
            <Link to={`${AppPath.shop}/${shopId}`}>
              <Avatar
                src={path}
                alt="shop logo"
                style={{
                  width: customSize,
                  height: customSize,
                  minWidth: customSize,
                  minHeight: customSize,
                }}
                onError={() => setPath("/gebtam.png")}
              />
            </Link>
          }
          content={
            <>
              <TextEllip lineNum={1}>
                <Link to={`${AppPath.shop}/${shopId}`}>
                  <h3 className="mb-0 font-main  size-12">{shopName}</h3>
                </Link>
              </TextEllip>
              <TextTime className="size-small">{setFeedDate(time)}</TextTime>
            </>
          }
        />
      </Col>
      <Icon type="more" className="size-12 bold  pointer" />
    </Row>
  );
}

FeedCardHeader.defaultProps = {
  size: 35,
};

export default FeedCardHeader;
