import React, { Component } from "react";
import { Link } from "react-router-dom";
import {
  Form,
  Input,
  Icon,
  Row,
  Col,
  Button,
  Avatar,
  message,
  Radio,
  DatePicker,
  Switch,
  Tooltip,
  Select,
  Modal,
  Result,
} from "antd";
import { connect } from "react-redux";
import thTH from "antd/lib/locale-provider/th_TH";
import moment from "moment";
import "moment/locale/th";

import { updateProfileUser } from "../store/actions/auth";
import AuthService from "../services/AuthService";
import { setFormatDate } from "../helpers/util";

moment.locale("th");

const { Option } = Select;
class UpdateProfile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      errors: {},
      isLoading: false,
      isOtpLoading: false,
      confirmDirty: false,
      autoCompleteResult: [],
      code: "",
      facuries: [],
      organis: [],
      persones: [],
      visible: false,
      textModal: "",
      member: {
        m_tel_number: "",
        m_gender: "2",
        m_name: "",
        m_nickname: "",
        m_lastname: "",
        m_mail: "",
        m_birth_date: null,
        code: "",
        rid: "",
        fid: "",
        pid: "",
        notify_status: true,
      },
    };
  }

  componentDidMount() {
    const param = new URLSearchParams(this.props.history.location.search);
    const tel = param.get("tel");
    if (this.props.user.isAuthenticated) {
      this.checkUserDetails(tel);
    } else {
      const path = param.get("path") || "/";
      this.props.history.push(`${path}`);
    }
  }

  componentWillReceiveProps(nextProps) {
    if (
      nextProps.errors.registerError &&
      nextProps.errors.isRegister !== this.props.errors.isRegister
    ) {
      this.setState({
        isLoading: false,
      });
      message.warning(nextProps.errors.registerError);
    }
  }

  getCategory = async (rid) => {
    await AuthService.getCategory({ rid: rid }).then((user) => {
      this.setState({
        facuries: user.facuries,
        persones: user.persones,
      });
    });
  };

  checkUserDetails = async (tel) => {
    const param = new URLSearchParams(this.props.history.location.search);
    await AuthService.checkInfoUser({ m_tel_number: tel }).then((user) => {
      if (user.data_cus) {
        if (user.data_cus.rid) {
          const path = param.get("path") || "/";
          this.props.history.push(`${path}`);
        } else {
          var notify = user.data_cus.notify_status === "1" ? true : false;
          this.setState({
            member: {
              ...this.state.member,
              m_tel_number: user.data_cus.m_tel_number,
              m_gender: user.data_cus.m_gender,
              m_name: user.data_cus.m_name,
              m_nickname: user.data_cus.m_nickname,
              m_lastname: user.data_cus.m_lastname,
              m_mail: user.data_cus.m_mail,
              m_birth_date: user.data_cus.m_birth_date,
              notify_status: notify,
              rid: user.data_cus.rid,
              pid: user.data_cus.pid,
              fid: user.data_cus.fid,
            },
            organis: user.organis,
          });
          this.getCategory(user.data_cus.rid || 1);
        }
      } else {
        const path = param.get("path") || "/";
        this.props.history.push(`${path}`);
        message.warning("ไม่พบข้อมูล");
      }
    });
  };

  validateToNextPassword = (rule, value, callback) => {
    const { form } = this.props;
    if (value && this.state.confirmDirty) {
      form.validateFields(["confirm"], { force: true });
    }
    callback();
  };

  onChangeRid = (rid) => {
    this.props.form.setFieldsValue({
      rid: rid,
      pid: "",
      fid: "",
    });
    this.setState({
      member: {
        ...this.state.member,
        rid: rid,
        pid: "",
        fid: "",
      },
    });
    this.getCategory(rid);
  };

  onChangePid = (pid) => {
    this.setState({
      member: {
        ...this.state.member,
        pid: pid,
      },
    });
  };

  onChangeFid = (fid) => {
    this.setState({
      member: {
        ...this.state.member,
        fid: fid,
      },
    });
  };

  handleSubmit = (e) => {
    e.preventDefault();
    this.props.form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        this.setState({ isLoading: true });
        const newValues = { ...values };
        newValues.m_birth_date = setFormatDate(newValues.m_birth_date);
        newValues.notify_status = newValues.notify_status ? 1 : 0;
        newValues.fromWeb = true;
        this.props.updateProfileUser(newValues, this.props.history);
        this.setState({
          isLoading: false,
          visible: true,
          textModal: "บันทึกสำเร็จ",
        });
      } else {
        console.log("err :>> ", err);
        this.setState({
          visible: true,
          textModal: "กรุณากรอกข้อมูลให้ครบ",
        });
      }
    });
  };

  handleSendOtp = () => {
    const m_tel_number = this.props.form.getFieldValue("m_tel_number");
    const telError = this.props.form.getFieldError("m_tel_number");
    if (!telError && !m_tel_number) {
      return message.error("กรุณาระบุหมายเลขโทรศัพท์");
    } else if (telError) {
      return message.error("กรุณาระบุหมายเลขโทรศัพท์ให้ถูกต้อง");
    }
    const postData = { to: m_tel_number, user: "test" };
    this.setState({ isOtpLoading: true });
    AuthService.sendVerifyOtp(postData)
      .then((resp) => {
        const data = resp;
        if (data.success) {
          message.success("ส่ง OTP สำเร็จ");
        } else {
          message.warning("ไม่สามารถส่ง sms ได้");
        }
        this.setState({ isOtpLoading: false });
      })
      .catch((error) => {
        this.setState({ isOtpLoading: false });
        console.log(error);
        message.error("เกิดข้อผิดพลาด");
      });
  };

  render() {
    const { getFieldDecorator } = this.props.form;
    const formItemLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 8 },
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 16 },
      },
    };

    const {
      member,
      organis,
      facuries,
      persones,
      visible,
      textModal,
    } = this.state;
    const organiz = organis.map((d) => <Option key={d.rid}>{d.name}</Option>);
    const facuriez = facuries.map((d) => <Option key={d.fid}>{d.name}</Option>);
    const personez = persones.map((d) => <Option key={d.pid}>{d.name}</Option>);
    return (
      <div className="login-page">
        <Form
          {...formItemLayout}
          onSubmit={this.handleSubmit}
          className="regis-form font-sub"
        >
          <div type="flex" justify="center" className="text-center mb-3">
            <Link to="/">
              <Tooltip
                placement="bottom"
                title={<span className="font-sub">หน้าแรก</span>}
              >
                <Avatar shape="square" size={64} src="/gebtam.png" />
              </Tooltip>
            </Link>
            <div className="text-center">
              <span className="font-main size-15 c-main">
                สมัครสมาชิกเก็บแต้ม
              </span>
            </div>
          </div>
          <Form.Item label="โทรศัพท์">
            {getFieldDecorator("m_tel_number", {
              initialValue: member.m_tel_number,
              rules: [
                { required: true, message: "ระบุหมายเลขโทรศัพท์" },
                { len: 10, message: "หมายเลขโทรศัพท์ไม่ถูกต้อง" },
              ],
            })(
              <Input
                prefix={
                  <Icon type="phone" style={{ color: "rgba(0,0,0,.25)" }} />
                }
                placeholder="Phone"
                disabled
              />
            )}
          </Form.Item>
          <Form.Item label="อีเมล">
            {getFieldDecorator("m_mail", {
              initialValue: member.m_mail,
              rules: [
                {
                  type: "email",
                  message: "กรอกอีเมลให้ถูกต้อง",
                },
                {
                  required: false,
                  message: "Please input your E-mail!",
                },
              ],
            })(<Input />)}
          </Form.Item>
          <Form.Item label="ชื่อ">
            {getFieldDecorator("m_name", {
              initialValue: member.m_name,
              rules: [
                {
                  required: true,
                  message: "กรอกชื่อ",
                },
              ],
            })(<Input placeholder="ชื่อ" />)}
          </Form.Item>{" "}
          <Form.Item label="นามสกุล">
            {getFieldDecorator("m_lastname", {
              initialValue: member.m_lastname,
              rules: [
                {
                  required: true,
                  message: "กรอกนามสกุล",
                },
              ],
            })(<Input placeholder="นามสกุล" />)}
          </Form.Item>
          <Form.Item label="องค์กร">
            {getFieldDecorator("rid", {
              initialValue: member.rid,
              rules: [
                {
                  required: true,
                  message: "กรอกองค์กร",
                },
              ],
            })(
              <Select
                showSearch
                placeholder="Select a organis"
                onChange={this.onChangeRid}
                optionFilterProp="children"
              >
                {organiz}
              </Select>
            )}
          </Form.Item>
          {member.rid === "1" ? (
            <>
              {" "}
              <Form.Item label="  สถานะภาพ">
                {getFieldDecorator("pid", {
                  initialValue: member.pid,
                  rules: [
                    {
                      required: true,
                      message: "กรอกสถานะภาพ",
                    },
                  ],
                })(
                  <Select
                    showSearch
                    placeholder="Select a persones"
                    onChange={this.onChangePid}
                    optionFilterProp="children"
                  >
                    {personez}
                  </Select>
                )}
              </Form.Item>
              {member.pid === "1" || member.pid === "2" ? (
                <>
                  {" "}
                  <Form.Item label="คณะ">
                    {getFieldDecorator("fid", {
                      initialValue: member.fid,
                      rules: [
                        {
                          required: true,
                          message: "กรอกคณะ",
                        },
                      ],
                    })(
                      <Select
                        showSearch
                        placeholder="Select a facuries"
                        onChange={this.onChangeFid}
                        optionFilterProp="children"
                      >
                        {facuriez}
                      </Select>
                    )}
                  </Form.Item>
                </>
              ) : (
                <></>
              )}{" "}
            </>
          ) : member.rid === "2" ? (
            <>
              {" "}
              <Form.Item label="  สถานะภาพ">
                {getFieldDecorator("pid", {
                  initialValue: member.pid,
                  rules: [
                    {
                      required: true,
                      message: "กรอกสถานะภาพ",
                    },
                  ],
                })(
                  <Select
                    showSearch
                    placeholder="Select a persones"
                    onChange={this.onChangePid}
                    optionFilterProp="children"
                  >
                    {personez}
                  </Select>
                )}
              </Form.Item>
            </>
          ) : (
            <></>
          )}
          <Form.Item label="เพศ">
            {getFieldDecorator("m_gender", {
              initialValue: member.m_gender,
            })(
              <Radio.Group>
                <Radio value={"0"}>ชาย</Radio>
                <Radio value={"1"}>หญิง</Radio>
                <Radio value={"2"}>ไม่ระบุ</Radio>
              </Radio.Group>
            )}
          </Form.Item>
          <Form.Item label="วันเกิด">
            {getFieldDecorator("m_birth_date", {
              initialValue: member.m_birth_date
                ? moment(member.m_birth_date)
                : null,
              rules: [
                { type: "object", required: true, message: "ระบุวันเกิด" },
              ],
            })(
              <DatePicker
                format="YYYY-MM-DD"
                locale={thTH}
                placeholder="เลือกวันเกิด"
                disabled={member.m_birth_date ? true : false}
              />
            )}
          </Form.Item>
          <Form.Item label="การแจ้งเตือน">
            {getFieldDecorator("notify_status", {
              valuePropName: "checked",
              initialValue: member.notify_status,
            })(
              <Switch
                name="switch"
                onChange={(e) => {
                  this.setState({
                    member: { ...member, notify_status: e },
                  });
                }}
              />
            )}
          </Form.Item>
          <Row>
            <Col span={24}>
              <Button
                type="primary"
                htmlType="submit"
                block
                shape="round"
                className="font-main size-15"
                loading={this.state.isLoading}
              >
                สมัครสมาชิก
              </Button>
            </Col>
            <Modal
              width="20rem"
              centered={true}
              visible={visible}
              footer={null}
              onCancel={() => {
                this.setState({
                  visible: false,
                });
              }}
            >
              <Result
                style={{ padding: 0, align: "center" }}
                status={textModal === "บันทึกสำเร็จ" ? "success" : "warning"}
                title={textModal}
                extra={[
                  <Button
                    shape="round"
                    className="font-main size-15"
                    onClick={() => {
                      textModal === "บันทึกสำเร็จ"
                        ? window.location.reload()
                        : this.setState({
                            visible: false,
                          });
                    }}
                  >
                    ตกลง
                  </Button>,
                ]}
              />
              ,
            </Modal>
          </Row>
          <Row className="text-center mt-2">
            <Link to="/" className="font-main text-center size-12 ">
              ย้อนกลับ
            </Link>
          </Row>
        </Form>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.user,
  errors: state.errors,
});

const UpdateProfileForm = Form.create({ name: "updateProfile" })(UpdateProfile);

export default connect(mapStateToProps, { updateProfileUser })(
  UpdateProfileForm
);
