import http from "./HttpService";
import { APP_URL } from "../config";
import { randomOtp, randomRef } from "../helpers/util";

class AuthV2 {
  async sendOtp(data) {
    const url = `${APP_URL}signUpSendCode`;
    const code = randomOtp();
    const ref = randomRef();
    data.code = code;
    data.ref = ref;
    this.setOtp(data);
    return await http.requestPost(url, data);
  }

  setOtp(data) {
    localStorage.setItem("telNumber", data.to);
    localStorage.setItem("code", data.code);
    localStorage.setItem("ref", data.ref);
  }

  getOtp() {
    return {
      telNumber: localStorage.getItem("telNumber"),
      code: localStorage.getItem("code"),
      ref: localStorage.getItem("ref"),
    };
  }

  async checkTelDup(telData) {
    const url = `${APP_URL}check_tel_dup`;
    return await http.requestPost(url, telData);
  }

  async updateProfile(profileData) {
    const url = `${APP_URL}memberProfileSaveV2`;
    return await http.requestPost(url, profileData);
  }

  async signup(memberData) {
    const url = `${APP_URL}signupV2`;
    return await http.requestPost(url, memberData);
  }

  async checkEventCode(telNumberData) {
    const url = `${APP_URL}check_eventcode`;
    return await http.requestPost(url, telNumberData);
  }

  async checkEventCodeV2(telNumberData) {
    const url = `${APP_URL}check_eventcode2`;
    return await http.requestPost(url, telNumberData);
  }
}
const AuthServiceV2 = new AuthV2();
export default AuthServiceV2;
