import React, { useEffect, useState } from "react";
import { Spin, message, Row, Col } from "antd";
import InfiniteScroll from "react-infinite-scroller";

import AuthService from "../../services/AuthService";
import PromotionService from "../../services/PromotionService";
import PromotionCard from "./PromotionCard";

export default function PromotionList() {
  const limit = 50;
  const [start, setStart] = useState(0);
  const [isLoading, setLoading] = useState(true);
  const [promotions, setPromotions] = useState([]);
  const [hasMore, setHasMore] = useState(true);

  useEffect(() => {
    const fetchShopPromotion = async () => {
      const act = JSON.parse(AuthService.getToken());

      const postData = {
        access_token: act.access_token,
        start,
        limit,
      };
      try {
        const resp = await PromotionService.getAllShopPromotion(postData);
        if (resp.data.length < limit) {
          setHasMore(false);
        }
        setPromotions((prevPromotions) => {
          return [...prevPromotions, ...resp.data];
        });
        setLoading(false);
      } catch (error) {
        console.log(error);
        message.error("เกิดข้อผิดพลาด");
      }
    };
    fetchShopPromotion();
  }, [start]);

  const loadMore = () => {
    setStart(promotions.length);
    console.log("load");
  };

  if (isLoading) {
    return (
      <Spin spinning={true} tip="loading...">
        <div style={{ height: 50 }}></div>
      </Spin>
    );
  } else {
    return (
      <InfiniteScroll
        pageStart={0}
        loadMore={loadMore}
        hasMore={hasMore}
        useWindow={true}
        loader={
          <Spin spinning={true} tip="loading...">
            <div style={{ height: 50 }}></div>
          </Spin>
        }
      >
        <Row gutter={[8, 8]} type="flex" justify="center" className="pd-3">
          {promotions.map((promotion) => {
            return (
              <Col
                key={promotion.pro_id}
                xs={12}
                sm={12}
                md={12}
                lg={8}
                xl={8}
                xxl={6}
              >
                <PromotionCard promotion={promotion} />
              </Col>
            );
          })}
        </Row>
      </InfiniteScroll>
    );
  }
}
