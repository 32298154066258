import React, { Component } from "react";
import { Form, Input, Button, Col, Icon, Row, message, Avatar } from "antd";
import AuthServiceV2 from "../../services/AuthServiceV2";
import PoweredByGebtam from "../gebtam/PoweredByGebtam";
// import SellService from '../../services/SellService'

class VerifyPhoneNumberV2 extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      isOtpLoading: false,
      isVerify: false,
      telNumber: "",
      countDown: 30,
      showCount: false,
    };
  }

  handleSendOtp = async () => {
    const telNumber = this.props.form.getFieldValue("telNumber");
    const telError = this.props.form.getFieldError("telNumber");
    if (!telError && !telNumber) {
      return message.error("กรุณาระบุหมายเลขโทรศัพท์");
    } else if (telError) {
      return message.error("กรุณาระบุหมายเลขโทรศัพท์ให้ถูกต้อง");
    }
    const postData = { to: telNumber };
    this.setState({ isOtpLoading: true, telNumber });
    try {
      const resp = await AuthServiceV2.sendOtp(postData);
      console.log(resp);
      if (resp.success) {
        message.success("ส่ง otp สำเร็จ");
      } else {
        message.error("ส่ง otp ไม่สำเร็จเนื่องจากเกินกำหนดแล้ว");
      }
    } catch (error) {
      console.log(error);
      message.error("ส่ง otp ไม่สำเร็จ");
    }
    this.setState({ isOtpLoading: false, showCount: true, countDown: 30 });

    const time = setInterval(() => {
      if (this.state.countDown <= 0) {
        clearInterval(time);
        this.setState({ showCount: false });
      } else this.setState({ countDown: this.state.countDown - 1 });
    }, 1000);
  };

  handleSubmit = async (e) => {
    e.preventDefault();
    this.props.form.validateFieldsAndScroll(async (err, values) => {
      if (!err) {
        const checkOtp = AuthServiceV2.getOtp();
        const isOtpValid =
          values.telNumber === checkOtp.telNumber &&
          values.code === checkOtp.code;
        if (isOtpValid) {
          this.props.setIsVerify(true);
          this.props.setTelNumber(values.telNumber);
          this.props.setCode(values.code);
        } else {
          this.setState({ isLoading: false });
          message.warn("รหัส opt ไม่ถูกต้อง หรือ หมดอายุ");
        }
      }
    });
  };

  render() {
    const { getFieldDecorator } = this.props.form;
    const { showCount, countDown } = this.state;

    return (
      <div className="login-page font-sub">
        <div className="mx-auto mt-auto mb-auto login-form">
          <div className="text-center">
            <Avatar
              shape="square"
              src="/aui2.jpg"
              style={{ width: 90, height: 64 }}
            />
          </div>
          <h2 className="font-main c-main text-center">
            ยินดีต้อนรับสู่หน้าลงทะเบียน
            <br />
            โดย ร้านอวยแซ่ตึ๊ง <br /> (ผ่านระบบ Gebtam)
          </h2>
          <Form onSubmit={this.handleSubmit} className=" verify-form ">
            {/* <div className="text-center">
              <Avatar
                shape="square" size={50}
                src="/gebtam.png"
              />
            </div> */}
            {/* <Divider type="horizontal" /> */}
            <h4 className="mx-auto text-center  size-10">
              ยืนยันหมายเลขโทรศัพท์
            </h4>
            <Form.Item label="โทรศัพท์" className=" mb-0">
              <Row gutter={8}>
                <Col span={16}>
                  {getFieldDecorator("telNumber", {
                    rules: [
                      { required: true, message: "ระบุหมายเลขโทรศัพท์" },
                      { len: 10, message: "หมายเลขโทรศัพท์ไม่ถูกต้อง" },
                    ],
                  })(
                    <Input
                      prefix={
                        <Icon
                          type="phone"
                          style={{ color: "rgba(0,0,0,.25)" }}
                        />
                      }
                      placeholder="กรอกหมายเลขโทรศัพท์"
                    />
                  )}
                </Col>
                <Col span={8}>
                  <Button
                    type="primary"
                    shape="round"
                    block
                    loading={this.state.isOtpLoading}
                    disabled={showCount}
                    onClick={this.handleSendOtp}
                  >
                    {showCount ? (
                      <span>ส่งได้อีกครั้งใน {countDown}</span>
                    ) : (
                      "ส่ง otp"
                    )}{" "}
                  </Button>
                </Col>
              </Row>
            </Form.Item>

            <Form.Item label="OTP">
              {getFieldDecorator("code", {
                rules: [
                  {
                    required: true,
                    message: "กรอก OTP",
                  },
                ],
              })(<Input />)}
            </Form.Item>
            <Button
              block
              type="primary"
              htmlType="submit"
              shape="round"
              loading={this.state.isLoading}
              className="ml-auto mb-3"
              icon="submit"
            >
              ยืนยัน
            </Button>
          </Form>
          <h3 className="c-main font-main ">
            หมายเหตุ: <br /> จำกัด​ 1 สิทธิ์/คน/สัปดาห์
          </h3>
          <PoweredByGebtam />
          {/* <div className="text-center">
            <Avatar alt="logo" src="/AW_gamtam_logo_EN5.png" style={{
              width: 110,
              height: 40
            }} />
          </div> */}
        </div>
      </div>
    );
  }
}

const VerifyFormV2 = Form.create({ name: "verify" })(VerifyPhoneNumberV2);

export default VerifyFormV2;
