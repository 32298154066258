import React, { useState, useEffect } from "react";
import { Card, message, Spin, Row, Col } from "antd";

import AuthService from "../../services/AuthService";
import { useParams, useHistory } from "react-router-dom";
import FeedService from "../../services/FeedService";
import CheckTypeFeed from "../feed/CheckTypeFeed";
import OrderOption from "./order-card/OrderOption";
import ShopDescription from "./order-card/ShopDescription";
import ChoosePayment from "./order-card/ChoosePayment";
import ConfirmOrder from "./order-card/ConfirmOrder";
import ScanQrcodePayment from "./order-card/ScanQrcodePayment";
import OrderService from "../../services/OrderService";
import { themeColor } from "../../styles/_constant";
import { setFormatDatabase } from "../../helpers/time";
import { AppPath } from "../../router/path";

export default function OrderCard() {
  const history = useHistory();
  const [renew, setRenew] = useState(false);
  const [feed, setFeed] = useState({});
  const [isLoading, setLoading] = useState(true);
  const [isGetingQrcode, setGetingQrcode] = useState(false);
  const [total, setTotal] = useState();
  const [count, setCount] = useState(1);
  const [step, setStep] = useState(1);
  const [confirmOrderVisible, setConfirmOrderVisible] = useState(false);
  const [scanOrderVisible, setScanOrderVisible] = useState(false);
  const [qrcodeResult, setQrcodeResult] = useState("");
  const [pickupTime, setPickupTime] = useState(new Date());

  let params = useParams();
  useEffect(() => {
    const fetchComment = async () => {
      const act = JSON.parse(AuthService.getToken());
      const postData = { top_node: params.id };
      if (act) {
        postData.access_token = act.access_token;
      }
      try {
        const resp = await FeedService.getFeed(postData);
        if (resp.success) {
          setFeed(resp.top_result);
          setTotal(Number(resp.top_result.price));
        }
        console.log(resp);
      } catch (error) {
        console.log(error);
        message.error("เกิดข้อผิดพลาด");
      }
      setLoading(false);
    };
    fetchComment();
  }, [params.id, renew]);

  const nextStep = (e) => {
    console.log(e, count, total);
    setStep(e);
    if (e === 3) {
      setConfirmOrderVisible(true);
    }
  };

  const onConfirmOrder = () => {
    insertOrder();
    console.log("confirm order");
  };

  const insertOrder = async () => {
    setGetingQrcode(true);
    const act = JSON.parse(AuthService.getToken());
    const postData = {
      access_token: act.access_token,
      feed_id: feed.feed_node,
      price: feed.price,
      qty: count,
      sid: feed.sid,
      payment_type: 1,
      pickup_time: setFormatDatabase(pickupTime),
    };
    try {
      const resp = await OrderService.insertOrder(postData);
      if (resp.success) {
        setQrcodeResult(resp.result);
        setScanOrderVisible(true);
      } else {
        message.warn("ไม่สามารถสั่งซื้อสินค้าได้");
      }
      console.log(resp);
    } catch (error) {
      console.log(error);
      message.error("เกิดข้อผิดพลาด");
    }
    setGetingQrcode(false);
  };

  const onPaymentNextTime = () => {
    history.push(`${AppPath.orderHistory}?tabs=order`);
    // setScanOrderVisible(false);
    // setLoading(true);
    // setStep(1);
    // setRenew((prev) => !prev);
  };

  if (isLoading) {
    return (
      <Spin spinning={true} tip="loadign...">
        <div className="mt-6"></div>
      </Spin>
    );
  } else {
    return (
      <Card
        headStyle={{ backgroundColor: themeColor.sub }}
        bordered={false}
        title={
          <span color="#f8d00f" className="font-main c-w size-15">
            สั่งซื้อสินค้า
          </span>
        }
        className="mt-3 sm-mt-0 mb-3 sm-mb-0"
      >
        <Row type="flex" justify="center" className="mt-3">
          <Col xs={22} sm={22} md={22} lg={20} span={20}>
            <CheckTypeFeed feed={feed} isNotResize isNotShowOrderBtn />
          </Col>
        </Row>
        <Spin spinning={isGetingQrcode}>
          <ShopDescription
            shopName={feed.shop_name}
            contact={feed.contact}
            openTime={feed.open_time}
            closeTime={feed.close_time}
          />
          {step === 1 ? (
            <OrderOption
              sid={feed.sid}
              price={Number(feed.price)}
              stock={Number(feed.stock)}
              count={count}
              total={total}
              setTotal={setTotal}
              setCount={setCount}
              nextStep={nextStep}
              openTime={feed.open_time}
              closeTime={feed.close_time}
              pickupTime={pickupTime}
              setPickupTime={setPickupTime}
            />
          ) : (
            <ChoosePayment nextStep={nextStep} />
          )}
          <ConfirmOrder
            visible={confirmOrderVisible}
            setVisible={setConfirmOrderVisible}
            onConfirm={onConfirmOrder}
            total={total}
            price={feed.price}
            count={count}
          />
          {scanOrderVisible && (
            <ScanQrcodePayment
              orderId={String(qrcodeResult.order_id)}
              ref1={qrcodeResult.ref1}
              qrcode={qrcodeResult.qr_code}
              visible={scanOrderVisible}
              onNextTime={onPaymentNextTime}
              // tabs={"pendingShopReceive"}
            />
          )}
        </Spin>
      </Card>
    );
  }
}
