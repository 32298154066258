import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Avatar, Row, Col, Progress } from "antd";

import { ShopDiv, LineClamp2, BoxSub } from "../../styles/components/style";
import ShopService from "../../services/ShopService";
import { AppPath } from "../../router/path";
import Follower from "./Follower";

const ShopCard = ({ shop, ...props }) => {
  const pathLogo = ShopService.getLogoHasPath(
    shop.path_head_pic,
    shop.head_pic
  );
  const [path, setPath] = useState(pathLogo);

  let percent = 0;
  if (shop.minest_pro) {
    if (shop.minest_pro.pro_amount === "0") {
      percent = 100;
    } else {
      percent =
        (parseInt(shop.member_stamp) / parseInt(shop.minest_pro.pro_amount)) *
        100;
    }
  }

  const followerChange = (e) => {
    props.followerChange(e, shop);
  };

  return (
    <ShopDiv className="mx-auto" style={{ maxWidth: "95%" }}>
      <Row type="flex">
        <Col className="d-flex mx-auto" style={{ alignItems: "center" }}>
          <Link to={`${AppPath.shop}/${shop.sid}`}>
            <Avatar
              size={100}
              src={path}
              onError={() => setPath("/gebtam.png")}
            />
          </Link>
        </Col>
        <Col className="pl-2  mx-auto sm-215" style={{ width: 215 }}>
          <Row>
            <Col span={24} className="text-right">
              <Follower
                followed={shop.followed}
                followerChange={followerChange}
                shop_name={shop.shop_name}
              />
            </Col>
            <Col span={24} className="bold size-12 font-sub">
              <Link to={`${AppPath.shop}/${shop.sid}`} className="c-b">
                {shop.shop_name}
              </Link>
            </Col>
            <Col span={24} className="font-sub" style={{ height: "2.5rem" }}>
              <LineClamp2>{shop.shop_details}</LineClamp2>
            </Col>
            <Col span={24}>
              <Link to={`${AppPath.shop}/${shop.sid}?tabs=point`}>
                <BoxSub>
                  <div className="font-sub d-flex bold">
                    <span className="c-b">
                      กดดูแต้มของฉัน <span className="c-main">>></span>
                    </span>
                    <span className="ml-auto c-b">
                      {shop.member_stamp} แต้ม
                    </span>
                  </div>
                  <div>
                    <Progress
                      type="line"
                      strokeColor="#f03975"
                      percent={percent}
                      showInfo={false}
                    />
                  </div>
                </BoxSub>
              </Link>
            </Col>
          </Row>
        </Col>
      </Row>
    </ShopDiv>
  );
};

export default ShopCard;
