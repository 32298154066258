import React from "react";
import { Row } from "antd";

import Main from "./Main";
import ProfileForm from "../components/profile/Profile";

export default function Profile() {
  return (
    <Main title="Profile">
      <Row type="flex" align="middle" justify="center" className="pd-2 ">
        <ProfileForm />
      </Row>
    </Main>
  );
}
