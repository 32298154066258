import React, { useState, useRef } from "react";
import { Comment, Input, Avatar, Icon, Button } from "antd";
import { connect } from "react-redux";
import ShopService from "../../../services/ShopService";

const { TextArea } = Input;
function FeedAddComment({
  onComment,
  placeholder,
  isPadding,
  autoFocus,
  feed_node,
  user,
}) {
  const [textComment, setTextComment] = useState("");
  const textRef = useRef(null);
  const { profile } = user;
  const pathLogo = ShopService.getLogoHasPath(
    profile.m_pic_path,
    profile.m_pic
  );

  const [path, setPath] = useState(pathLogo);
  const onCommentChange = (e) => {
    setTextComment(e.target.value);
  };

  const onKeyPress = (e) => {
    if (e.key === "Enter" && !e.shiftKey) {
      if (textComment) {
        onComment(textComment);
      }
      setTimeout(() => setTextComment(""), 10);
    }
  };

  const onClickComment = () => {
    if (textComment) {
      onComment(textComment);
      setTextComment("");
    }
    // textRef.current.forcus();
    const textCommentRef = document.getElementById(`text-comment${feed_node}`);
    textCommentRef.focus();
  };
  return (
    <Comment
      className={`${isPadding ? "pd-2" : "pt-2 pb-2"} custom-comment`}
      avatar={
        <Avatar
          src={path}
          alt="comment logo"
          onError={() => setPath("/gebtam.png")}
        />
      }
      content={
        <>
          <TextArea
            id={`text-comment${feed_node}`}
            ref={textRef}
            autoFocus={autoFocus}
            placeholder={placeholder}
            className="round no-resize pl-3 pr-6 border-medium"
            autoSize
            value={textComment}
            onChange={onCommentChange}
            // onKeyUp={onCommentChange}
            onKeyPress={onKeyPress}
          />
          <Button
            type="link"
            shape="circle"
            className="ab size-10 d-flex align-center"
            style={{ right: 0, bottom: 0 }}
            onClick={onClickComment}
          >
            <Icon type="arrow-right" className="mx-auto d-flex" />
          </Button>
        </>
      }
    />
  );
}
FeedAddComment.defaultProps = {
  placeholder: "แสดงความคิดเห็น",
};

let mapStateToProps = (state) => ({
  user: state.user,
});
export default connect(mapStateToProps, null)(FeedAddComment);
