import React, { useState } from "react";
import { Comment, Avatar, Divider, Icon } from "antd";
import Linkify from "react-linkify";

import { TextEllip } from "../../../styles/components/style";
import ShopService from "../../../services/ShopService";
import FeedAddComment from "./FeedAddComment";
import ComponentDecorator from "../ComponentDecorator";

export default function FeedCardComment({ comment, isUpNode, onReply }) {
  const [isRepling, setRepling] = useState(false);
  const [path, setPath] = useState(
    ShopService.getLogoHasPath(comment.o_pic_path, comment.o_pic)
  );

  const onComment = (text) => {
    onReply(text, comment);
  };
  let commentProps = {};
  if (!isUpNode) {
    commentProps = {
      actions: [
        <span
          key="comment-nested-reply-to"
          className="c-main font-main size-10 "
          onClick={() => setRepling((prev) => !prev)}
        >
          <Icon
            type={`${isRepling ? "left" : "down"}`}
            className="size-08 bold"
            style={{ verticalAlign: "middle" }}
          />{" "}
          ตอบกลับ
        </span>,
      ],
    };
  }
  return (
    <>
      <Comment
        className={`${isUpNode ? "pl-3" : ""}`}
        {...commentProps}
        datetime={comment.feed_create_time}
        author={
          <TextEllip lineNum={1} className=" font-main size-12">
            {comment.o_name}
          </TextEllip>
        }
        avatar={
          <Avatar
            src={path}
            alt="comment logo"
            onError={() => setPath("/gebtam.png")}
          />
        }
        content={
          <Linkify componentDecorator={ComponentDecorator}>
            <p className="mb-1">{comment.feed_text}</p>
          </Linkify>
        }
      >
        {isRepling && (
          <FeedAddComment
            feed_node={comment.feed_node}
            autoFocus
            onComment={onComment}
            placeholder={`ตอบกลับ ${comment.o_name}`}
          />
        )}
      </Comment>
      <div className={`${isUpNode ? "pl-3" : ""}`}>
        <Divider className="mt-0 mb-2" />
      </div>
    </>
  );
}
