import {
  SET_CURRENT_USER,
  GET_CURRENT_USER,
  LOGOUT,
} from "../actions/types";

//return state ใหม่ตาม action ต่างๆ
const user = (state = {}, action) => {
  switch (action.type) {
    case SET_CURRENT_USER:
      return {
        isAuthenticated: true,
        profile: action.payload,
      };
    case GET_CURRENT_USER:
      const newState = { ...state };
      return newState;
    case LOGOUT:
      return {
        isAuthenticated: false,
        profile: {},
      };
    default:
      return state;
  }
};

export default user;
