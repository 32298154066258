import React from "react";
import { CardRatio, CardItem } from "../../../styles/components/card";
import FeedShopLogo from "./FeedShopLogo";
import { TextEllip } from "../../../styles/components/style";
import { Col } from "antd";
import { FeedTextStyle, FeedTextMiddle } from "../../../styles/components/feed";
import { Link } from "react-router-dom";
import { AppPath } from "../../../router/path";
import FeedOrderBtn from "./FeedOrderBtn";

export default function FeedCardText({
  feed,
  pathShopLogo,
  shopName,
  isNotResize,
  isNotShowOrderBtn,
}) {
  return (
    <Link to={`${AppPath.feed}/${feed.feed_node}`} className="w-100">
      <CardRatio bg={feed.feed_bg_color} pdTop="100%">
        <CardItem>
          <div className="pt-3 text-center flex-column h-100 w-100 rela pl-3 pr-3 sm-pl-1 sm-pr-1 ">
            <FeedShopLogo
              isNotResize={isNotResize}
              pathLogo={pathShopLogo}
              shopName={shopName}
              shopId={feed.sid}
            />
            <Col className="flex-grow-1 d-flex align-center" span={24}>
              <div className="mx-auto max-w-100">
                <TextEllip lineNum={1}>
                  <FeedTextStyle>{feed.feed_text_1}</FeedTextStyle>
                </TextEllip>
                <TextEllip lineNum={2} className="c-w">
                  <FeedTextMiddle>{feed.feed_text_2}</FeedTextMiddle>
                </TextEllip>
                <TextEllip lineNum={1}>
                  <FeedTextStyle>{feed.feed_text_3}</FeedTextStyle>
                </TextEllip>
              </div>
            </Col>
            {feed.price && !isNotShowOrderBtn && (
              <FeedOrderBtn
                path={`${AppPath.feed}/${feed.feed_node}${AppPath.order}`}
              />
            )}
          </div>
        </CardItem>
      </CardRatio>
    </Link>
  );
}
