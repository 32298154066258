import React from "react";
import { Row, Col, Button } from "antd";
import { useHistory } from "react-router-dom";

import { TextTime } from "../../../styles/components/style";
import { AppPath } from "../../../router/path";
import ContactShop from "../../order_v2/ContactShop";

export default function FeedCardBuy({ price, stock, feedNode, contact }) {
  const history = useHistory();
  return (
    <Row
      type="flex"
      justify="space-between"
      align="middle"
      className="bg-advt pd-2"
    >
      <Col className="flex-auto">
        <p className="mb-1 size-11 bold">ราคาสินค้า {price} บาท</p>
        <TextTime>จำนวนสินค้า {stock}</TextTime>
      </Col>
      <Col className="flex-130 text-right ">
        <ContactShop contact={contact} />
        <Button
          className="font-main size-10 ml-2"
          style={{ borderRadius: 10 }}
          onClick={() =>
            history.push({
              pathname: `${AppPath.feed}/${feedNode}${AppPath.order}`,
            })
          }
        >
          ซื้อสินค้า
        </Button>
      </Col>
    </Row>
  );
}
