import React, { useRef, useEffect } from "react";
import { Tabs } from "antd";
import { connect } from "react-redux";

import ShopPromotionList from "./ShopPromotionList";
import { setShopsPoints } from "../../store/actions/shop";

const { TabPane } = Tabs;

const ShopPromotionCouponHome = (props) => {
  const latestProps = useRef(props);
  useEffect(() => {
    latestProps.current = props;
  });

  useEffect(() => {
    const { sid, history, setShopsPoints } = latestProps.current;
    const postData = {
      sid,
    };
    setShopsPoints(history, postData);
  }, []);

  return (
    <Tabs
      type="card"
      className="tab-bg-w w-100 mx-auto"
      tabBarStyle={{ color: "red" }}
    >
      <TabPane
        tab={<span className="font-main size-12">โปรโมชั่น</span>}
        key="1"
      >
        <div className=" pd-3 " style={{ overflowX: "auto" }}>
          <ShopPromotionList sid={props.sid} history={props.history} />
        </div>
      </TabPane>
      <TabPane
        disabled
        tab={<span className="font-main size-12">คูปอง</span>}
        key="2"
      >
        <div className=" pd-3 ">
          <p>Content of Tab Pane 2</p>
          <p>Content of Tab Pane 2</p>
          <p>Content of Tab Pane 2</p>
        </div>
      </TabPane>
    </Tabs>
  );
};

export default connect(null, { setShopsPoints })(ShopPromotionCouponHome);
