import React, { useRef, useEffect } from "react";
import { connect } from "react-redux";
import { Avatar, Row, Col, Progress, Button } from "antd";

import { setShopsPoints } from "../../store/actions/shop";

const ShopPoint = (props) => {
  const latestProps = useRef(props);
  useEffect(() => {
    latestProps.current = props;
  });

  useEffect(() => {
    const { sid, history, setShopsPoints } = latestProps.current;
    const postData = {
      sid,
    };
    setShopsPoints(history, postData);
  }, []);

  useEffect(() => {
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 200);
  });

  const { sid, shops } = latestProps.current;
  const { dataDetail, dataFollow } = shops;
  return (
    <div className="b-w w-100 pd-3">
      {dataDetail[sid] ? (
        <Row type="flex" className="rela">
          <Col>
            <Avatar src="/logo.png" shape="square" size={64} />
          </Col>
          <Col className="ab w-100 bold" style={{ paddingLeft: 80 }}>
            <Row type="flex" justify="space-between">
              <Col>
                {" "}
                <span className="font-main size-15">แต้มของฉัน</span>
              </Col>
              <Col>
                <Button
                  type="primary"
                  shape="round"
                  size="small"
                  className="font-main"
                >
                  <span className="c-sub pr-2">
                    {dataDetail[sid].member_stamp}{" "}
                  </span>
                  แต้ม
                </Button>
              </Col>
              <Col span={24}>
                <Progress
                  strokeColor="#f03975"
                  percent={
                    (parseInt(dataDetail[sid].member_stamp) /
                      (dataFollow[sid]
                        ? dataFollow[sid].minest_pro
                          ? dataFollow[sid].minest_pro.pro_amount
                          : 10
                        : 10)) *
                    100
                  }
                  showInfo={false}
                />
              </Col>
            </Row>
          </Col>
        </Row>
      ) : (
        <div>555555</div>
      )}
    </div>
  );
};

const mapStateToProps = (state) => ({
  shops: state.shops,
});
export default connect(mapStateToProps, { setShopsPoints })(ShopPoint);
